import React from 'react';
import styled from "styled-components";
import {DownLoadLink} from "../DocumentsPage/DocumentsPageComponent";
import {DownIcon} from "../WinnersPage/WinnerCard";
import {Action} from "./MediaPageComponent";
import {ActionWrapper, Socials} from './ImageSlider';
import download from "../../assets/svg/down.svg";
import vk from "../../assets/img/vk.png";
import fb from "../../assets/img/fb.png";
import ReactPlayer from "react-player";

const Content = styled.div`
  width: 90%;
  height: auto;
  max-width: 1053px;
  max-height: 763px;
  margin: auto;
  background: #fff;
  margin-top: 5%;
  padding: 40px 45px 0;
  
  @media screen and (max-width: 1000px) {
    padding: 30px 35px 0;
  }
  @media screen and (max-width: 945px) {
    padding: 30px 35px 0;
  }
  @media screen and (max-width: 780px) {
    padding: 30px 35px 0;
  }
  @media screen and (max-width: 620px) {
    margin-top: 10%;
    width: 100%;
  }
  @media screen and (max-width: 445px) {
    padding: 30px 0 0;
  }
  @media (orientation: landscape) and (max-width: 780px) {
  margin-top: 0;
  height: 100%;
  padding: 0 35px 0;
  }
  @media (orientation: landscape) and (max-width: 445px) {
  margin-top: 0;
  height: 100%;
  padding: 0 35px 0;
  }
`;

const IframeContainer = styled.div`
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
`;

const CustomIframe = styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;

const VideoPopupContent = ({video}) => {
    return(
        <Content>
            <IframeContainer>
                { video.type === "youtube" && (
                    <CustomIframe
                        src={`https://www.youtube.com/embed/${video.id}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                )}
                { video.type === "rutube" && (
                    <iframe width="940" height="520" src={`https://rutube.ru/play/embed/${video.id}`}
                            frameBorder="0"
                            allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen>
                    </iframe>
                )}
            </IframeContainer>
            <Action style={{marginTop: '15px'}}>
                <p>
                    {video.title}
                </p>
            </Action>
            <ActionWrapper style={{marginTop: '25px', paddingBottom: '15px'}}>
            {/*
                <Action>
                    <DownLoadLink href='#' download style={{cursor: 'not-allowed'}}>
                        <DownIcon src={download}/>
                        Скачать
                    </DownLoadLink>
                </Action>
                <Socials style={{cursor: 'not-allowed', marginTop: '-12px'}}>
                    <img
                        src={vk}
                        alt=""
                        style={{ marginRight: "20px" }}
                    />
                </Socials>
            */}
            </ActionWrapper>
        </Content>
    );
};

export default VideoPopupContent;