import React from "react";
import styled from "styled-components";
import {Breadcrumbs, BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {Grid, Row, Col} from "react-flexbox-grid";
import {PageContainer, Error} from "../../styles/containers";
import {H2} from "../../styles/fonts";
import table from "../../assets/file/График проведения региональных этапов Конкурса- 2019.xlsx";
import table2 from "../../assets/file/График проведения региональных этапов Конкурса БСД.xlsx";
import table2022 from "../../assets/file/ВКС_График_проведения_регионального_этапа_2022.pdf";
import table2023 from "../../assets/file/ВКС_График_проведения_регионального_этапа_21_09_2023.pdf";
import table2024 from "../../assets/file/ГРАФИК_проведения_РЭ_04.06.pdf";

const OperatorPageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 100px;
  font-family: "Roboto Condensed", sans-serif;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

const BorderContainer = styled.div`
  padding-left: 20px;
  border-left: 2px dashed ${props => props.color};
  margin-top: 50px;
`;

const StageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AbsoluteDivStage = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: -20px;
  left: -30.5px;
  background-color: ${props => props.color};
  z-index: 1;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: -30.5px;
  background-color: ${props => props.color};
`;

const StageTitle = styled.span`
  position: relative;
  display: block;
  font-size: 30px;
  letter-spacing: 1px;
  color: #232c5f;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 5;
`;

const Dates = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 26px;
  color: #4c579a;
  font-weight: 700;
  text-transform: uppercase;
`;

const CalendarDatesContainer = styled.div`
  position: relative;
  margin-top: 40px;
  padding-left: 24px;
`;

const Description = styled.span`
  font-size: 18px;
  line-height: 20px;
  color: #616875;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
`;

const TitleContainer = styled(Col)`
  display: flex;
  align-items: center;
  @media (max-width: 756px) {
    display: block;
  }
`;

const LinkSpan = styled.a`
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 24px;
  color: #6873b5;
  font-family: "Roboto Condensed";
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: underline;
`;

class CalendarPageComponent extends React.Component {

    state = {
        colors: ["#9eabfa", "#5ad1e2", "#8697d7"]
    }

    componentDidUpdate() {
        const hash = decodeURI(this.props.location.hash).slice(1);
        const hashElement = document.querySelector('#mainEvent');
        if (hash === 'scrollToMain' && hashElement) {
            hashElement.scrollIntoView()
        }
    }

    render() {
        return (
            <OperatorPageContainer>
                <Grid fluid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs
                                separator={<span style={{color: "#39404c"}}> / </span>}
                                finalItem={"b"}
                                finalProps={{
                                    style: {color: "#6873b5", fontWeight: "normal"}
                                }}
                            />
                            <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
                            <BreadcrumbsItem to={`/news/`}>Календарь</BreadcrumbsItem>
                        </Col>
                    </Row>
                    <Row>
                        <TitleContainer lg={12}>
                            <H2 style={{marginRight: "35px"}}>календарь</H2>
                            <LinkSpan href={table2024} download target="_blank">ГРАФИК ПРОВЕДЕНИЯ РЕГИОНАЛЬНОГО ЭТАПА</LinkSpan>
                        </TitleContainer>
                    </Row>
                    <Row>
                        <Col lg={1}/>
                        <Col lg={10}>
                            {
                                this.props.calendar.allEvents && this.props.calendar.allEvents.length && !this.props.calendar.isFetchingAllEvents ? this.props.calendar.allEvents.map((stage, i) => {
                                        return (
                                            <BorderContainer key={i}
                                                             color={i < 3 ? this.state.colors[i] : this.state.colors[0]}>
                                                <StageContainer>
                                                    <AbsoluteDivStage
                                                        color={i < 3 ? this.state.colors[i] : this.state.colors[0]}/>
                                                    <StageTitle>
                                                        <div style={{textShadow: '1px 0px 5px rgba(255,255,255,.5)'}}
                                                            id={this.props.calendar.mainEvent && this.props.calendar.mainEvent.stage === stage.title ? 'mainEvent' : '' }>
                                                            {stage.title}
                                                        </div>
                                                    </StageTitle>
                                                    <Dates>{stage.dates}</Dates>
                                                </StageContainer>
                                                {stage.events.map((item, j) => (
                                                    <CalendarDatesContainer key={j}>
                                                        <AbsoluteDiv
                                                            color={i < 3 ? this.state.colors[i] : this.state.colors[0]}/>
                                                        <Dates>{item.dates}</Dates>
                                                        <Description>{item.title}</Description>
                                                    </CalendarDatesContainer>
                                                ))}
                                            </BorderContainer>
                                        )
                                    }) : this.props.calendar.allEvents && this.props.calendar.allEvents.length === 0 ?
                                    <Error>
                                        Для данного конкурса нет доступного календаря
                                    </Error> : null
                            }
                        </Col>
                        <Col lg={1}/>
                    </Row>
                </Grid>
            </OperatorPageContainer>
        );
    }
}

export default CalendarPageComponent;
