import React from "react";
import styled from "styled-components";
import download from "../../assets/svg/down.svg";
import look from "../../assets/svg/look.svg";
import flag from "../../assets/svg/flag.svg";
import WinnersSvg from "../UI/Icons/Winners";
import { DownLoadLink } from "../DocumentsPage/DocumentsPageComponent";

const Card = styled.div`
  border: 1px solid #cccccc;
  width: 49%;
  margin-bottom: 25px;
  min-height: 236px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #3d4669;

  @media screen and (max-width: 787px) {
    width: 100%;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
`;

const DivRight = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.48px;
`;

const Grade = styled.p`
  color: #868b94;
  letter-spacing: 0.64px;
  margin: 5px 0;
`;

const Name = styled.p`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.96px;
  margin: 8px 0;
  width: 80%;
`;

const Location = styled.p`
  color: #4c579a;
  letter-spacing: 0.64px;
  margin: 5px 0;
`;

const Essay = styled.p`
  font-size: 18px;
  letter-spacing: 0.72px;
  margin: 20px 0 0;
`;

const Uploads = styled.div`
  display: flex;
  margin-top: 20px;
  color: #6873b5;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 250px;
`;

const Action = styled.div`
  width: 40%;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  a {
    color: #6873b5;
  }

  @media screen and (max-width: 930px) {
    width: 50%;
  }
`;

export const DownIcon = styled.img`
  width: 13px;
  height: 15px;
  margin-right: 8px;
  position: relative;
  top: 3px;
`;

const LookIcon = styled.img`
  width: 20px;
  height: 13px;
  margin-right: 10px;
`;

const Status = styled.div`
  max-width: 100px;
  color: ${props => props.color};
`;

const SvgWrapper = styled.div`
  width: 25px;
  height: 25px;
  margin-bottom: 9px;

  & > img {
    width: 32px;
    height: 22px;
  }
`;
const MaxWidth = styled.div`
  max-width: 70%;
`

const WinnerCard = ({winner, openModalHandler}) => {

  // let color = "#00bbd5";
  // if (winner.status) {
  //   switch (winner.status.id) {
  //     case 1:
  //       color = "#00bbd5";
  //       break;
  //     case 2:
  //       color = "#737dba";
  //       break;
  //     case 3:
  //       color = "#59a187";
  //       break;
  //     default:
  //       color = "#232c5f";
  //       break;
  //   }
  // }

  return (
    <Card>
      <FlexDiv>
        <MaxWidth>
          <Grade>{winner.grade_category.name}</Grade>
          <Name>
            <span>{winner.surname}</span> <span>{winner.name}</span> <span>{winner.patronymic}</span>
          </Name>
          <Location>
            {winner.region !== null && winner.region.name}
            {/*{winner.country !== undefined && winner.country !== null && `(${winner.country.name})`}*/}
          </Location>
          <Essay>{winner.title}</Essay>
          {winner.essay && true && winner.essay.url && (
            <Uploads>
              <Action>
                <DownLoadLink href={winner.essay.url} download>
                  <DownIcon src={download} />
                  Скачать
                </DownLoadLink>
              </Action>
              <Action
                onClick={() => openModalHandler(winner.essay.url)}
              >
                <LookIcon src={look} />
                Смотреть
              </Action>
            </Uploads>
          )}
        </MaxWidth>
        <DivRight>
          <SvgWrapper>
            {winner.status && winner.status.id === 4 ? (
              <img src={flag} alt="" />
            ) : (
              <WinnersSvg color={'#232c5f'} />
            )}
          </SvgWrapper>
          <Status color={'#232c5f'}>{winner.winnerStatus && winner.winnerStatus.name}</Status>
        </DivRight>
      </FlexDiv>
    </Card>
  );
};

export default WinnerCard;
