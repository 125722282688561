import styled from "styled-components";
import {Col, Row} from "react-flexbox-grid";
import React, {Component} from "react";

const ArrowBtn = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transition: all .2s;
  color: #3d4669;
  margin-top: 8px;
  
  transform: ${props => (props.isopen === 'true' ? `rotate(-135deg)` : `rotate(45deg)`)};
`;

const Header = styled.div`
  color: #10215e;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  
  & > p {
    margin-bottom: 5px;
  }
`;

const Body = styled.div`
  display: ${props => (props.isopen === 'true' ? 'inline-block' : 'none')};
  color: #616875;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  font-weight: 500;
  transition: max-height 0.2s ease-out;
  
  & > p {
    margin: 10px 0;
  }
`;

class Accordion extends Component {
    state = {
        isOpen: false
    };

    handleClick = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}));
    };

    render() {
        const {isOpen} = this.state;
        const {header, body, btnPosition = 'left'} = this.props;
        const btnCol = btnPosition === 'left' ? 2 : 1;
        const bodyCol = btnPosition === 'left' ? 10 : 11;

        return (
            <Row style={{width: "100%"}}>
                {btnPosition === 'left' && (
                    <Col xs={btnCol} s={1} md={1} lg={1}>
                        <ArrowBtn onClick={this.handleClick}>
                            <Arrow isopen={isOpen.toString()}/>
                        </ArrowBtn>
                    </Col>
                )}
                <Col xs={bodyCol} s={11} md={11} lg={11}>
                    <Header>
                        <p>{header}</p>
                    </Header>
                    <Body isopen={isOpen.toString()}>
                    <p>{body}</p>
                    </Body>
                </Col>
                {btnPosition === 'right' && (
                    <Col xs={btnCol}>
                        <ArrowBtn onClick={this.handleClick}>
                            <Arrow isopen={isOpen.toString()}/>
                        </ArrowBtn>
                    </Col>
                )}
            </Row>
        );
    }
}

export default Accordion;