import { OPEN_MODAL, CLOSE_MODAL } from "../constants";
import {
  GET_WINNERS_FOR_MAIN_PAGE_REQUEST,
  GET_WINNERS_FOR_MAIN_PAGE_FAILURE,
  GET_WINNERS_FOR_MAIN_PAGE_SUCCESS,
  GET_ALL_WINNERS_FOR_COMPETITION_REQUEST,
  GET_ALL_WINNERS_FOR_COMPETITION_SUCCESS,
  GET_ALL_WINNERS_FOR_COMPETITION_FAILURE
}
  from '../constants/winnersTypes';

const initialState = {
  modal: false,
  item: null,
  winnersData: null,
  isFetchingWinners: false,
  winnersError: '',
  allWinners: null,
  isFetchingAllWinners: false,
  allWinnersError: ''
};

function winnerReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modal: true,
        item: action.payload
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modal: false
      };
    case GET_WINNERS_FOR_MAIN_PAGE_REQUEST:
      return {
        ...state,
        isFetchingWinners: true
      };
    case GET_WINNERS_FOR_MAIN_PAGE_SUCCESS:
      return {
        ...state,
        isFetchingWinners: false,
        winnersData: action.payload
      };
    case GET_WINNERS_FOR_MAIN_PAGE_FAILURE:
      return {
        ...state,
        isFetchingWinners: false,
        winnersError: action.payload
      };
    case GET_ALL_WINNERS_FOR_COMPETITION_REQUEST:
      return {
        ...state,
        isFetchingAllWinners: true
      };
    case GET_ALL_WINNERS_FOR_COMPETITION_SUCCESS:
      return {
        ...state,
        isFetchingAllWinners: false,
        allWinners: action.payload
      };
    case GET_ALL_WINNERS_FOR_COMPETITION_FAILURE:
      return {
        ...state,
        isFetchingAllWinners: false,
        allWinnersError: action.payload
      };
    default:
      return state;
  }
}

export default winnerReducer;
