import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter  } from "react-router-dom";
import queryString from "query-string";
import NewsPageComponent from "./NewsPageComponent";
import FullNewsContainer from "./FullNews/FullNewsContainer";
import NotFound from '../NotFound/NotFound';
import {getAllNewsByCompetitionIdRequest, getMainNewsByCompetitionIdRequest} from '../../actions/newsActions';
import {fetchMainCalendarEventRequest} from '../../actions/calendarActions';

class NewsPageContainer extends Component {
  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    const {competitionId, page} = search;

    // todo: ПЕРЕДЕЛАТЬ ДЛЯ НОВОГО АПИ
    if (competitionId) {
      this.props.getMainNewsByCompetitionIdRequest(competitionId);
      this.props.getAllNewsByCompetitionIdRequest(null, `page=${page}`)
      this.props.fetchMainCalendarEventRequest(competitionId);
    }
  }

  componentDidUpdate(prevProps) {
    const currentPage = queryString.parse(this.props.location.search).page;
    const lastPage = queryString.parse(prevProps.location.search).page;
    if (this.props.location.search !== prevProps.location.search) {
      if (currentPage !== lastPage) {
        this.props.getAllNewsByCompetitionIdRequest(null, `page=${currentPage}`)
      }
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/news/" exact component={() => <NewsPageComponent {...this.props} />} />
        <Route path="/news/detail/:id/:competitionId" component={FullNewsContainer} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMainCalendarEventRequest: (id) => {
      dispatch(fetchMainCalendarEventRequest(id))
    },
    getMainNewsByCompetitionIdRequest: (id) => {
      dispatch(getMainNewsByCompetitionIdRequest(id))
    },
    getAllNewsByCompetitionIdRequest: (id, query) => {
      dispatch(getAllNewsByCompetitionIdRequest(id, query))
    }
  };
};

const mapStateToProps = state => ({
  main_news: state.news.mainNews,
  news: state.news.allNews,
  pagination: state.news && state.news.allNews && state.news.allNews.pagination,
  mainisLoading: state.news.isFetchingMainNews,
  loading: state.news.isFetchingAllNews,
  calendar: state.calendar,
  competitions: state.competitions,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsPageContainer));
