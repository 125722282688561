import {
    GET_COMPETITION_OPTIONS_REQUEST,
    GET_COMPETITION_OPTIONS_FAILURE,
    GET_COMPETITION_OPTIONS_SUCCESS,
    GET_COMPETITION_INFO_REQUEST,
    GET_COMPETITION_INFO_FAILURE,
    GET_COMPETITION_INFO_SUCCESS,
    GET_MAIN_COMPETITION_INFO_REQUEST,
    GET_MAIN_COMPETITION_INFO_SUCCESS,
    GET_MAIN_COMPETITION_INFO_FAILURE,
    GET_ALL_COMPETITIONS_ID_REQUEST,
    GET_ALL_COMPETITIONS_ID_SUCCESS,
    GET_ALL_COMPETITIONS_ID_FAILURE,
} from "../constants/competitionsTypes";

const initialState = {
    options: null,
    isOptionsLoading: false,
    errorOptions: '',
    info: null,
    isInfoLoading: false,
    errorInfo: '',
    allGroupCompetitions: [],
    isAllGroupLoading: false,
    allCompetitionsError: '',
    actualCompetitionId: null
};

function competitionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_COMPETITION_OPTIONS_REQUEST:
            return {
                ...state,
                isOptionsLoading: true
            };

        case GET_COMPETITION_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.payload.filter(type => type['active-contest']),
                isOptionsLoading: false
            };

        case GET_COMPETITION_OPTIONS_FAILURE:
            return {
                ...state,
                errorOptions: action.payload,
                isOptionsLoading: false
            };
        case GET_COMPETITION_INFO_REQUEST:
            return {
                ...state,
                isInfoLoading: true
            };

        case GET_COMPETITION_INFO_SUCCESS:
            return {
                ...state,
                info: action.payload,
                isInfoLoading: false
            };

        case GET_COMPETITION_INFO_FAILURE:
            return {
                ...state,
                errorInfo: action.payload,
                isInfoLoading: false
            };
        case GET_MAIN_COMPETITION_INFO_REQUEST:
            return {
                ...state,
                isInfoLoading: true
            };

        case GET_MAIN_COMPETITION_INFO_SUCCESS:
            return {
                ...state,
                info: action.payload,
                isInfoLoading: false
            };

        case GET_MAIN_COMPETITION_INFO_FAILURE:
            return {
                ...state,
                errorInfo: action.payload,
                isInfoLoading: false
            };
        case GET_ALL_COMPETITIONS_ID_REQUEST:
            return {
                ...state,
                isAllGroupLoading: true
            }
        case GET_ALL_COMPETITIONS_ID_SUCCESS: {
            return {
                ...state,
                isAllGroupLoading: false,
                allGroupCompetitions: action.payload
            }
        }
        case GET_ALL_COMPETITIONS_ID_FAILURE: {
            return {
                ...state,
                isAllGroupLoading: false,
                allCompetitionsError: action.payload
            }
        }
        default:
            return state;
    }
}

export default competitionsReducer;
