import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../../styles/containers";
// import { ContainerSpaces } from "../../styles/spaces";
import { H2 } from "../../../styles/fonts";
import Button from "../../UI/Button";
import icon from "../../../assets/svg/bg-winner-znak.svg";
import image_1 from "../../../assets/img/media-1.jpeg";
import image_2 from "../../../assets/img/media-2.jpeg";
import image_3 from "../../../assets/img/media-3.jpeg";
import { MenuLink } from "../../Header/HeaderComponent";

const CustomPageContainer = styled(PageContainer)`
  @media (max-width: 768px) {
    margin-top: -22px;
  }
`;

const MediaCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 280px;
  background-color: gray;
  /* background-image: url(${image_1});
  background-size: cover; */
  font-size: 22px;
  text-align: center;
  @media (max-width: 768px) {
    height: 180px;
    margin-bottom: 25px;
  }
`;

const CustomMediaCard = styled(MediaCard)`
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => (props.color ? "#6873b5" : "none")};
`;

const Icon = styled.img`
  width: 100px;
  margin-bottom: 22px;
  opacity: ${props => props.opacity && "0.5"};
`;

const CardTitle = styled.div`
  width: 70%;
  padding: 5px 22px;
  background-color: #6873b5;
  margin: 0 auto;
  margin-bottom: 35px;
  font-size: 18px;
  /* letter-spacing: 1px; */
  color: #fff;
  font-family: "Roboto Condensed";
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

const MediaLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
`;

const HomeMediaComponent = () => {
  return (
    <CustomPageContainer>
      <Grid fluid>
        <Row>
          <Col lg={2}>
            <H2>Медиатека</H2>
          </Col>
          <Col lg={2} style={{ textAlign: "right" }}>
            {/* <Button
              color="#8d96c7"
              style={{
                marginTop: "28px",
                marginRight: "-33px"
              }}
            >
              Все материалы
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={4} style={{}}>
            <MediaCard
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_1})`,
                backgroundSize: "cover"
              }}
            >
              <MediaLink
                to={{
                  pathname: "/mediateka",
                  state: { year: "2022", tab: 0, popupOpened: true }
                }}
              >
                <CardTitle>Видеообращение</CardTitle>
              </MediaLink>
            </MediaCard>
          </Col>
          <Col lg={4} md={4} sm={4} style={{}}>
            <CustomMediaCard
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_2})`,
                backgroundSize: "cover"
              }}
            >
              {/* <Icon src={icon} alt="" opacity /> */}
              <MediaLink
                to={{
                  pathname: "/mediateka",
                  state: { year: "2022", tab: 1 }
                }}
              >
                <CardTitle>Фото</CardTitle>
              </MediaLink>
            </CustomMediaCard>
          </Col>
          <Col lg={4} md={4} sm={4} style={{}}>
            <CustomMediaCard
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_3})`,
                backgroundSize: "cover"
              }}
            >
              {/* <Icon src={icon} alt="" /> */}
              <MediaLink
                to={{
                  pathname: "/mediateka",
                  state: { year: "2022", tab: 0 }
                }}
              >
                <CardTitle>Видео</CardTitle>
              </MediaLink>
            </CustomMediaCard>
          </Col>
        </Row>
      </Grid>
    </CustomPageContainer>
  );
};

export default HomeMediaComponent;
