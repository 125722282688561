import React, { Component } from "react";
import HeaderComponent from "./HeaderComponent";
import { connect } from "react-redux";
import { GET_COMPETITION_OPTIONS_REQUEST } from '../../constants/competitionsTypes';
import {getAllCompetitionsIdRequest, getCompetitionInfoRequest } from '../../actions/competitionsActions';

class HeaderContainer extends Component {

  componentDidMount() {
      if (!this.props.competitions.options) {
        this.props.getCompetitionOptionsRequest()
      }
      if (!this.props.competitions.info && !this.props.isHome && this.props.competitionId) {
          console.log(1, this.props.competitionId)
        this.props.getCompetitionInfoRequest(this.props.competitionId);
      }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('HeaderContainer', this.props);
  // }

  render() {
    return (
      <HeaderComponent {...this.props}
                       // competitionId={this.props.competitions && this.props.competitions.info ? this.props.competitions.info.id : this.props.competitionId}
      />
    )
  }
}

const mapStateToProps = ({competitions}) => ({
  competitions
});

const mapDispatchToProps = dispatch => {
  return {
    getCompetitionOptionsRequest: () => dispatch({ type: GET_COMPETITION_OPTIONS_REQUEST }),
    getAllCompetitionsIdRequest: (id) =>
        dispatch(getAllCompetitionsIdRequest(id)),
    getCompetitionInfoRequest: (id) =>
        dispatch(getCompetitionInfoRequest(id))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
