import React, {useState, useEffect} from "react";
import './UnitPageComponent.scss';
import styled from "styled-components";
import axios from "axios";
import {Breadcrumbs, BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {Grid, Row, Col} from "react-flexbox-grid";
import {PageContainer} from "../../styles/containers";
import {H2} from "../../styles/fonts";
import Iframemodal from "../UI/Iframemodal";
import download from "../../assets/svg/down.svg";
import look from "../../assets/svg/look.svg";

import {
    displayLook,
    getExtension,
    getFileSize,
    isImage
} from "../../helpers/workWithFiles";

import pamyatka from "../../assets/img/pdf/teacher/Памятка учителю.pdf";
import viyavlenie
    from "../../assets/img/pdf/teacher/Выявление литературно одаренных обучающихся и развитие их филологических способностей статья.pdf";
import podgotogka from "../../assets/img/pdf/teacher/Подготовка литературно одаренных.. Опыт регионов.pdf";
import problemi
    from "../../assets/img/pdf/teacher/Проблемы развития филологической одаренности и специфика работы с одаренными обучающимися. Опыт регионов.pdf";

const TeacherPageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 100px;
  min-height: 300px;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

const SectionTitle = styled.h2`
  position: relative;
  margin-bottom: ${props => props.MarginTop && `80px`};
  padding-left: 45px;
  font-size: 24px;
  letter-spacing: 1px;
  color: #39404c;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  &:before {
    position: absolute;
    content: "";
    width: 75px;
    height: 70px;
    left: 0;
    top: -17.5px;
    background-color: ${props => props.color};
    z-index: -1;
  }
`;

const DocsItem = styled.div`
  margin-bottom: 15px;
`;

const DocsNames = styled.p`
  /* margin-top: ${props => props.marginTop && `35px`}; */
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  color: #3d4669;
  font-family: "Roboto";
  font-weight: 400;
`;

const DocsInfo = styled.span`
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  color: #9fa8b8;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const ActionContainer = styled.div`
  text-align: ${props => (props.textAlign ? `right` : `left`)};
  margin-bottom: ${props => props.marginTop && `35px`};
  margin-right: 20px;
  font-size: 16px;
  line-height: 17px;
  color: #6873b5;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
`;

const Icon = styled.img`
  width: ${props => (props.BiggerIcon ? `19px` : `12px`)};
  margin-right: 10px;
  vertical-align: middle;
  padding-bottom: ${props => (props.BiggerPadding ? `3px` : `2px`)};
`;

const DirectLink = styled.a`
  display: block;
  margin-top: ${props => props.marginTop && `55px`};
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 30px;
  color: #3d4669;
  font-family: "Roboto";
  font-weight: 400;
`;

const DownLoadLink = styled.a`
  text-decoration: none;
`;

const colors = ["#a8dbf5", "#8ad3dd", "#d0d4e5", "#8697d7"];

const UnitPageComponent = props => {

    const { page } = props;

    return (
        <TeacherPageContainer>
            <Grid fluid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs
                            separator={<span style={{color: "#39404c"}}> / </span>}
                            finalItem={"b"}
                            finalProps={{
                                style: {color: "#6873b5", fontWeight: "normal"}
                            }}
                        />
                        <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
                        <BreadcrumbsItem to={`/news/`}>{page.name}</BreadcrumbsItem>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <H2>{page.name}</H2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={1} md={0}/>
                    <Col lg={10} md={12}>
                        {page.sub_section &&
                        page.sub_section.map((item, i) => (
                            item.documents.length > 0 ? <div key={i}>
                                <SectionTitle color={`${colors[i < 3 ? i : 3]}`}>
                                    {item.name}
                                </SectionTitle>
                                {item.documents.length > 0 &&
                                item.documents.map((doc, i) => (
                                    doc.file &&
                                    <DocsItem key={i}>
                                        <Row middle="lg">
                                            <Col lg={12}>
                                                <DocsNames marginTop>{doc.name}</DocsNames>
                                                <div className="DocType">
                                                    Документ / {getExtension(doc.file.url)}, {getFileSize(doc.file.size)} Кб
                                                </div>
                                                <div className="UnitPageComponent__flex">
                                                    <ActionContainer
                                                        marginTop
                                                        onClick={() =>
                                                            props.openModalHandler(doc.file.url)
                                                        }
                                                    >
                                                        <Icon BiggerIcon src={look} alt=""/>
                                                        Смотреть
                                                    </ActionContainer>
                                                    <DownLoadLink
                                                        href={doc.file.url}
                                                        download
                                                    >
                                                        <ActionContainer marginTop>
                                                            <Icon src={download} alt=""/>
                                                            Скачать
                                                        </ActionContainer>
                                                    </DownLoadLink>
                                                </div>
                                            </Col>
                                        </Row>
                                    </DocsItem>
                                    )
                                )}
                                {item.documents.length === 0 && (
                                    <DocsItem>
                                        <Row middle="lg">
                                            <Col lg={12}>
                                                <DocsNames marginTop>Материалов нет</DocsNames>
                                            </Col>
                                        </Row>
                                    </DocsItem>
                                )}
                            </div> : null
                        ))}
                    </Col>
                    {/* <SectionTitle color="#8ad3dd" MarginTop>
              ПОЛЕЗНОЕ
            </SectionTitle>
            <DirectLink href="#" marginTop>
              Региональные операторы и региональные сайты конкурса
            </DirectLink>
            <DirectLink href="#">
              Установочный вебинар по вопросам организации и проведения ВКС 2019
              года
            </DirectLink> */}
                    <Col lg={1}/>
                </Row>
            </Grid>
            <Iframemodal
                openModalHandler={props.openModalHandler}
                close={props.close}
            />
        </TeacherPageContainer>
    );
};

export default UnitPageComponent;
