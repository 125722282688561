import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
  @import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed');

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  background-color: #fff;
  /* @import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
  @import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed'); */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Roboto Condensed", "Fira Sans Condensed", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

`;

export default GlobalStyle;
