import React from 'react';
import styled from "styled-components";

export function getCurrentCards(currentPage, cardsPerPage, cards) {
    const indexOfLastPage = currentPage * cardsPerPage;
    const indexOfFirstPage = indexOfLastPage - cardsPerPage;

    return cards.slice(indexOfFirstPage, indexOfLastPage);
}

const PageLi = styled.li`
  margin-right: 0.3em;
  user-select: none;
  cursor: pointer;
  color: ${props => (props.iscurrent === `true` ? `#3d4669` : `#6873b5`)};
  background: ${props => (props.iscurrent === `true` ? `#d9ddf2` : `#fff`)};
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.25px;
  text-transform: uppercase;
  padding: 6px 12px;

  &:hover,
  &:active {
    color: #3d4669;
    background: #d9ddf2;
  }
`;

const PageNumbers = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
`;

const PaginationStatic = ({currentPage, cardsPerPage, cards, handleClick}) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cards.length / cardsPerPage); i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <PageLi
                key={number}
                id={number}
                onClick={e => handleClick(e)}
                iscurrent={`${number === currentPage}`}
            >
                {number}
            </PageLi>
        );
    });

    return (
        <PageNumbers>
            {renderPageNumbers.length > 1 && renderPageNumbers}
        </PageNumbers>
        );
};

export default PaginationStatic;