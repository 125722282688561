import styled from "styled-components";

const H2 = styled.h2`
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 40px;
  color: #10215e;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
  
  @media screen and (max-width: 575px) {
  font-size: 24px;
  line-height: 28px;
  }
`;

const H3 = styled.h3`
  font-size: 22px;
  letter-spacing: 1px;
  /* line-height: 24px; */
  color: #6873b5;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const H4 = styled.h4`
  font-size: 21px;
  line-height: 34px;
  /* color: #ffffff; */
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const Text = styled.p`
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 22px;
  color: #3d4669;
  font-family: "Roboto";
  font-weight: 400;
  z-index: 10;
`;

export { H2, H3, H4, Text };