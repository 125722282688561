import {
    FETCH_MAIN_CALENDAR_EVENT_REQUEST,
    FETCH_MAIN_CALENDAR_EVENT_SUCCESS,
    FETCH_MAIN_CALENDAR_EVENT_FAILURE,
    FETCH_ALL_CALENDAR_EVENTS_REQUEST,
    FETCH_ALL_CALENDAR_EVENTS_SUCCESS,
    FETCH_ALL_CALENDAR_EVENTS_FAILURE
} from '../constants/calendarTypes';

const initialState = {
  mainEvent: null,
  isFetchingMainEvent: false,
  mainEventError: '',
  allEvents: null,
  isFetchingAllEvents: false,
  allEventsError: ''
};

function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAIN_CALENDAR_EVENT_REQUEST:
      return {
        ...state,
        isFetchingMainEvent: true
      };
    case FETCH_MAIN_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        isFetchingMainEvent: false,
        mainEvent: action.payload
      };
      case FETCH_MAIN_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        isFetchingMainEvent: false,
        mainEventError: action.payload
      };
    case FETCH_ALL_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingAllEvents: true
      };
    case FETCH_ALL_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingAllEvents: false,
        allEvents: Object.values(action.payload)
      };
      case FETCH_ALL_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingAllEvents: false,
        allEventsError: action.payload
      };

    default:
      return state;
  }
}

export default calendarReducer;
