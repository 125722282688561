import React, { Component } from "react";
import { connect } from "react-redux";
import HomeInformationComponent from "./HomeInformationComponent";
import { fetchMainCalendarEventRequest } from '../../../actions/calendarActions';
import { getMainNewsByCompetitionIdRequest, getAllNewsByCompetitionIdRequest } from '../../../actions/newsActions';
import {getCompetitionId} from '../../../helpers/getCompetitionId';

class HomeInformationContainer extends Component {
  state = {
    categoryList: [],
    competitionId: null
  }

  componentDidMount() {
    // await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/news-categories/`)
    //   .then(response => {
    //     console.log(response)
    //   });
    // this.props.fetchMainNewsRequest();
    // this.props.fetchNewsRequest();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.competitions.info && this.props.competitions.info.id && this.props.competitions.info.id !== this.state.competitionId) {
      const competitionId = this.props.competitions.info.id;
      this.setState({competitionId: competitionId})
      this.props.fetchMainCalendarEventRequest(competitionId);
      this.props.getMainNewsByCompetitionIdRequest(competitionId)
      this.props.getAllNewsByCompetitionIdRequest(null, 'page=1')
    }
  }

  render() {
    return (
      <HomeInformationComponent calendar={this.props.calendar} news={this.props.news} competitionId={
        this.props.competitions && this.props.competitions.info && this.props.competitions.options && this.props.competitions.info.type &&
        getCompetitionId(this.props.competitions.info.type.id, this.props.competitions.options)
      }/>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMainCalendarEventRequest: (id) => {
      dispatch(fetchMainCalendarEventRequest(id))
    },
    getMainNewsByCompetitionIdRequest: (id) => {
      dispatch(getMainNewsByCompetitionIdRequest(id))
    },
    getAllNewsByCompetitionIdRequest: (id, query) => {
      dispatch(getAllNewsByCompetitionIdRequest(id, query))
    }
  }
}

const mapStateToProps = state => ({
  competitions: state.competitions,
  calendar: state.calendar,
  news: state.news
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeInformationContainer);
