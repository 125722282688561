import React from "react";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../../styles/containers";
// import { ContainerSpaces } from "../../styles/spaces";
import { H2 } from "../../../styles/fonts";
import edu from "../../../assets/img/partners/лого_edu.png";
import edu_fcior from "../../../assets/img/partners/лого_fcior_edu.png";
import school from "../../../assets/img/partners/лого_school_collection.png";
import edu_window from "../../../assets/img/partners/лого_window_edu.png";

import asi from "../../../assets/img/partners/ASI.png";
import alpina from "../../../assets/img/partners/Альпина.png";
import minen from "../../../assets/img/partners/logo_minenergy.png";
import erich from "../../../assets/img/partners/Erich_Krause.png";
import push from "../../../assets/img/partners/Институт-Пушкина.png";
import bib from "../../../assets/img/partners/logo_rgdb.png";
import tol from "../../../assets/img/partners/Tolerant.png";
import classic from "../../../assets/img/partners/logo_gk.png";

const CustomPageContainer = styled(PageContainer)`
  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

const ContainerSpaces = styled.div`
  margin-bottom: 36px;
`;

const CustomH2 = styled(H2)`
  margin-bottom: -15px;
  margin-top: 70px;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const PartnerCard = styled.a`
  padding-left: 20px;
  padding-right: 20px;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomImg = styled.img`
  width: 100%;
`;

const partners = [
  { logo: edu, link: "http://edu.ru/" },
  { logo: asi, link: "#" },
  { logo: alpina, link: "#" },
  { logo: minen, link: "#" },
  { logo: erich, link: "#" },
  { logo: push, link: "#" },
  { logo: bib, link: "#" },
  { logo: tol, link: "#" },
  { logo: classic, link: "#" }
];

const dateSliderParams = {
  slidesPerView: window.innerWidth < 769 ? 1 : 4,
  shortSwipes: true,
  spaceBetween: 30,
  loop: true,
  autoplay: {
    delay: 3000
  },
  on: {
    init: () => {}
  }
};

class HomePartnersComponent extends React.Component {
  render() {
    return (
      <CustomPageContainer>
        <ContainerSpaces>
          <Grid fluid>
            <Row>
              <Col lg={2}>
                <CustomH2>партнеры</CustomH2>
              </Col>
            </Row>
            {
              <Swiper
                {...dateSliderParams}
                ref={node => {
                  if (node) this.swiper = node.swiper;
                }}
              >
                {partners.map((el, i) => (
                  <div key={i}>
                    <PartnerCard
                      target="_blank"
                      rel="noopener noreferrer"
                      href={el.link}
                      className="text-center partners__item"
                    >
                      <CustomImg src={el.logo} alt="" />
                    </PartnerCard>
                  </div>
                ))}
              </Swiper>
            }
          </Grid>
        </ContainerSpaces>
      </CustomPageContainer>
    );
  }
}

export default HomePartnersComponent;
