import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { FETCH_GRADES_REQUEST, FETCH_STATUSES_REQUEST, FETCH_WINNERS_REQUEST } from "../constants";
import { GET_WINNERS_FOR_MAIN_PAGE_REQUEST, GET_ALL_WINNERS_FOR_COMPETITION_REQUEST } from '../constants/winnersTypes';
import {
    fetchWinGradesSuccess,
    fetchWinGradesError,
    fetchWinStatusesSuccess,
    fetchWinStatusesError,
    fetchWinnersSuccess,
    fetchWinnersError,
    getWinnersForMainPageFailure,
    getWinnersForMainPageSuccess,
    getAllWinnersForCompetitionFailure,
    getAllWinnersForCompetitionSuccess
} from "../actions/winnersActions";


// классы для страницы Победители
export function* fetchWinGrades({payload}) {
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v1/winners-grades?year=${payload}`);
        const data = response.data;
        yield put(fetchWinGradesSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(fetchWinGradesError(message));
    }
}

// статусы для страницы Победители
export function* fetchWinStatuses({payload}) {
    // const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v1/winners-statuses?year=${payload}`);
    // const data = response.data;
    const data = []
    try {
        yield put(fetchWinStatusesSuccess(data));
    } catch (error) {
        console.error('ERROR')
        const message = "error, please try again";
        yield put(fetchWinStatusesError(message));
    }
}

// победители для страницы Победители
export function* fetchWinners({payload}) {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v1/winners?year=${payload}`);
    const data = response.data;
    try {
        yield put(fetchWinnersSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(fetchWinnersError(message));
    }
}

export function* getWinnersForMainPage({payload}) {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/winners/${payload}`);
    const data = response.data;
    try {
        yield put(getWinnersForMainPageSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getWinnersForMainPageFailure(message));
    }
}

export function* getAllWinnersForCompetition({payload}) {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/all-winners/${payload}`);
    const data = response.data;
    try {
        yield put(getAllWinnersForCompetitionSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getAllWinnersForCompetitionFailure(message));
    }
}

export function* winnerGradesActionWatcher() {
    yield takeLatest(FETCH_GRADES_REQUEST, fetchWinGrades);
}

export function* winnerStatusesActionWatcher() {
    yield takeLatest(FETCH_STATUSES_REQUEST, fetchWinStatuses);
}

export function* winnersActionWatcher() {
    yield takeLatest(FETCH_WINNERS_REQUEST, fetchWinners);
    yield takeLatest(GET_WINNERS_FOR_MAIN_PAGE_REQUEST, getWinnersForMainPage);
    yield takeLatest(GET_ALL_WINNERS_FOR_COMPETITION_REQUEST, getAllWinnersForCompetition)
}
