import {
    FETCH_OPERATORS_REQUEST,
    FETCH_OPERATORS_SUCCESS,
    FETCH_OPERATORS_ERROR
} from "../constants";

export const fetchOperatorsRequest = () => ({
    type: FETCH_OPERATORS_REQUEST
});

export const fetchOperatorsSuccess = (operators) => ({
    type: FETCH_OPERATORS_SUCCESS,
    operators,
});

export const fetchOperatorsError = (message) => ({
    type: FETCH_OPERATORS_ERROR,
    message,
});