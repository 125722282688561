import React from "react";
import styled from "styled-components";
import {Breadcrumbs, BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {Grid, Row, Col} from "react-flexbox-grid";

import {PageContainer, Error} from "../../styles/containers";
import {H2} from "../../styles/fonts";
import Iframemodal from "../UI/Iframemodal";
import DocItem, {DocsItem} from './DocItem';
import DocItemShort from './DocItemShort';
import DiplomaItem from './DiplomaItem';

import logo from "../../assets/img/vks-banner-24.png";

const DocumentsPageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 45px;
  min-height: 300px;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

export const SectionTitle = styled.h2`
  position: relative;
  margin-top: ${props => props.MarginTop && `100px`};
  padding-left: 45px;
  font-size: 24px;z 
  letter-spacing: 1px;
  color: #39404c;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  &:before {
    position: absolute;
    content: "";
    width: 75px;
    height: 70px;
    left: 0;
    top: -17.5px;
    background-color: ${props => props.color};
    z-index: -1;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 100px;
`;

const DocItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const CopyContainer = styled.div`
  margin-top: 32px;
`;

const CopyTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: #3d4669;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
`;

const LinkStyle = styled.span`
  display: inline-block;
  padding-top: 20px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 14px;
  line-height: 20px;
  color: #4e515d;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  @media (max-width: 411px) {
    padding-left: 0;
  }
`;

const colors = ['#a8dbf5', '#8ad3dd', '#d0d4e5', '#8697d7'];

export const DownLoadLink = styled.a`
  text-decoration: none;
`;

const DocumentPageComponent = ({docs, openModalHandler, close, isDocsLoading}) => {

    return (
        <DocumentsPageContainer>
            <Grid fluid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs
                            separator={<span style={{color: "#39404c"}}> / </span>}
                            finalItem={"b"}
                            finalProps={{
                                style: {color: "#6873b5", fontWeight: "normal"}
                            }}
                        />
                        <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
                        <BreadcrumbsItem to={`/news/`}>Документы</BreadcrumbsItem>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <H2>Документы</H2>
                    </Col>
                </Row>

                <Row>
                    <Col lg={1} md={0}/>
                    <Col lg={10} md={12}>

                        {docs !== null && !isDocsLoading ? docs.map((section, i) => {
                            const docs = section.documents.length === 0 ? null : section.documents.map((doc, index) => <DocItem openModalHandler={openModalHandler} doc={doc} key={index}/>);

                            return (
                                section.documents.length === 0 ? null :
                                    <SectionWrapper key={i}>
                                        <SectionTitle color={i < colors[i].length - 1 ? colors[i] : colors[colors.length - 1]}>{section.name}</SectionTitle>
                                        <DocItemWrapper>{docs}</DocItemWrapper>
                                        {section.id === 3 &&
                                        <DocsItem>
                                            <CopyContainer>
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} style={{marginBottom: "8px"}}>
                                                        <CopyTitle>
                                                            Скопируйте код баннера и разместите его на Вашем сайте
                                                            или
                                                            блоге:
                                                        </CopyTitle>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        lg={4} md={5} sm={12} xs={12}
                                                        style={{
                                                            border: "1px solid #b7c5d8",
                                                            padding: "10px",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <img
                                                            src={logo}
                                                            alt="Всероссийский конкурс сочинений"
                                                            style={{
                                                                width: "250px"
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg={7} md={7} sm={12} xs={12} style={{
                                                        border: "1px solid #b7c5d8",
                                                        paddingBottom: "15px"
                                                    }}>
                                                        <LinkStyle>
                                                            {`<a href="https://vks.apkpro.ru/"><img src="https://vks.apkpro.ru/static/media/vks-banner-24.png" 
					                                            alt="Всероссийский конкурс сочинений" /></a>`
                                                            }
                                                        </LinkStyle>
                                                    </Col>
                                                </Row>
                                            </CopyContainer>
                                        </DocsItem>
                                        }
                                    </SectionWrapper>
                            )
                        }) :
                            null
                        }
                        { docs && docs.every(section => section.documents.length === 0) ?
                            <Error>
                                Для данного конкурса нет доступных документов
                            </Error> : null
                        }
                    </Col>
                    <Col lg={1} md={0}/>
                </Row>
            </Grid>
            <Iframemodal
                openModalHandler={openModalHandler}
                close={close}
            />
        </DocumentsPageContainer>
    );
};

export default DocumentPageComponent;
