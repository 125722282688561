import React, {Component} from 'react';
import Input from "../UI/Input";
import styled from "styled-components";
import axios from 'axios';
import Recaptcha from "react-google-recaptcha";

const Content = styled.div`
  width: 90%;
  max-width: 945px;
  margin: auto;
  background: #fff;
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 60px 97px;
  
    @media screen and (max-width: 745px) {
    padding: 30px;
  }
  
  & input:invalid, & textarea:invalid {
    border-color: red;
  }
  
  & label {
    color: #6873b5;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  & input[type=submit] {
    color: #6873b5;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.25px;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #b7c5d8;
    padding: 5px 25px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    cursor: pointer;
    
    &[disabled] {
        cursor: not-allowed;
      }
    
    &:focus, &:active {
      border-color: rgba(104, 115, 200, 0.8);
      box-shadow: 0 1px 1px rgba(104, 115, 200, 0.075) inset,
        0 0 8px rgba(104, 115, 200, 0.6);
      outline: 0 none;
    }
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 30px;

  & > input {
    padding-left: 10px;

    &::placeholder {
      color: #818bc6;
    }

    &:focus {
      border-color: rgba(104, 115, 200, 0.8);
      box-shadow: 0 1px 1px rgba(104, 115, 200, 0.075) inset,
        0 0 8px rgba(104, 115, 200, 0.6);
      outline: 0 none;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  border: ${props => (props.border )};
  height: 243px;
  padding-left: 10px;
  
  &:focus {
      border-color: rgba(104, 115, 200, 0.8);
      box-shadow: 0 1px 1px rgba(104, 115, 200, 0.075) inset,
        0 0 8px rgba(104, 115, 200, 0.6);
      outline: 0 none;
    }
`;

const inputs = [
    {
        name: 'text',
        label: 'фио',
        type: 'text'
    },
    {
        name: 'tel',
        label: 'телефон',
        type: 'tel'
    },
    {
        name: 'email',
        label: 'email',
        type: 'email'
    },
];

class PopupContent extends Component {
    state = {
        text: '',
        tel: '',
        email: '',
        question: '',
        isEmpty: {
            text: false,
            tel: false,
            email: false,
            question: false
        },
        isDisabled: false,
        checkCaptcha: false,
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleSubmit = (e) => {
      e.preventDefault();
        const {tel, text, email, question} = this.state;
        const data = {
            phone: tel,
            email,
            fio: text,
            message: question
        }

        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/v1/feedback?phone=${data.phone}&email=${data.email}&fio=${data.fio}&message=${data.message}`,
        })  
          .then(response => {
              // alert("Ваша заявка принята")
            this.props.handleClose();
          })
          .catch(response => {
            alert("Что-то пошло не так, попробуйте позже")
            console.log(response);
            this.props.handleClose();
          });
    };

    handleBlur = (type) => {
        if (this.state[type]) {
            this.setState(prevState => ({
                isEmpty: {
                    ...prevState.isEmpty,
                    [type]: false
                }
            }))
        } else {
            this.setState(prevState => ({
                isEmpty: {
                    ...prevState.isEmpty,
                    [type]: true
                }
            }))
        }
    };

    checkCaptchaHandler = () => {
      this.setState({
          checkCaptcha: true
      })
  }

    render() {
        const {tel, text, email, question, isEmpty, checkCaptcha} = this.state;
        return (
            <Content>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    {inputs.map((input, i) => (
                        <InputWrapper key={i}>
                            <label>{input.label}</label>
                            <Input
                                name={input.name}
                                value={this.state[input.name]}
                                type={input.type}
                                border={`${this.state.isEmpty[input.type] ? "1px solid red" : "1px solid #b7c5d8;"}`}
                                height='44px'
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                required={isEmpty[input.type]}
                            />
                        </InputWrapper>
                    ))}
                    <label>содержание вопроса</label>
                    <TextArea
                        onChange={this.handleChange}
                        name='question'
                        value={this.state.question}
                        onBlur={() => this.handleBlur('question')}
                        border={`${isEmpty.question ? "1px solid red" : "1px solid #b7c5d8;"}`}
                        required={isEmpty.question}
                    />
                    <Recaptcha
                            sitekey="6Lf_sakUAAAAAPXDtJQUXvA0ZzU8NpvhE0_sziM9"
                            onChange={this.checkCaptchaHandler}
                        />
                    <input
                        type='submit'
                        value="отправить"
                        disabled={!(question && email && tel && text && checkCaptcha)}
                    />
                </form>
            </Content>
        );
    }
}

export default PopupContent;
