import {
    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_ERROR
} from "../constants";

export const fetchRegionsRequest = () => ({
    type: FETCH_REGIONS_REQUEST
});

export const fetchRegionsSuccess = (regions) => ({
    type: FETCH_REGIONS_SUCCESS,
    regions,
});

export const fetchRegionsError = (message) => ({
    type: FETCH_REGIONS_ERROR,
    message,
});