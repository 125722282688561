import {
    GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST,
    GET_MAIN_NEWS_BY_COMPETITION_ID_SUCCESS,
    GET_MAIN_NEWS_BY_COMPETITION_ID_FAILURE,
    GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST,
    GET_ALL_NEWS_BY_COMPETITION_ID_SUCCESS,
    GET_ALL_NEWS_BY_COMPETITION_ID_FAILURE
} from '../constants/newsTypes';


const initialState = {
    mainNews: null,
    isFetchingMainNews: false,
    mainNewsError: '',
    allNews: null,
    isFetchingAllNews: false,
    allNewsError: ''
}

function newsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST:
            return {
                ...state,
                isFetchingMainNews: true
            };
        case GET_MAIN_NEWS_BY_COMPETITION_ID_SUCCESS:
            return {
                ...state,
                isFetchingMainNews: false,
                mainNews: action.payload
            };
        case GET_MAIN_NEWS_BY_COMPETITION_ID_FAILURE:
            return {
                ...state,
                isFetchingMainNews: false,
                mainNewsError: action.payload
            };
        case GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST:
            return {
                ...state,
                isFetchingAllNews: true
            };
        case GET_ALL_NEWS_BY_COMPETITION_ID_SUCCESS:
            return {
                ...state,
                isFetchingAllNews: false,
                allNews: action.payload
            };
        case GET_ALL_NEWS_BY_COMPETITION_ID_FAILURE:
            return {
                ...state,
                isFetchingAllNews: false,
                allNewsError: action.payload
            };
        default:
            return state;
    }
}

export default newsReducer;
