import styled from "styled-components";

const Button = styled.button`
  padding: 6px 15px 4px 15px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Roboto Condensed";
  color: ${props => props.color};
  background-color: #fff;
  border: 1px solid #b7c5d8;
  text-transform: uppercase;
  outline: 0;
  z-index: 10;
  transition: 0.3s;
  &:hover {
    color: #fff;
    background-color: #4c579a;
  }
`;

export default Button;
