import {
    GET_SECTION_INFO_BY_ID_SUCCESS,
    GET_SECTION_INFO_BY_ID_FAILURE,
    GET_SECTION_INFO_BY_ID_REQUEST
} from '../constants/documentsTypes';

export const getSectionInfoByIdRequest = (id, sectionBlock) => ({
    type: GET_SECTION_INFO_BY_ID_REQUEST,
    payload: {id, sectionBlock}
})

export const getSectionInfoByIdSuccess = (info) => ({
    type: GET_SECTION_INFO_BY_ID_SUCCESS,
    payload: info,
});

export const getSectionInfoByIdFailure = (message) => ({
    type: GET_SECTION_INFO_BY_ID_FAILURE,
    payload: message,
});
