import {
    FETCH_OPERATORS_REQUEST,
    FETCH_OPERATORS_SUCCESS,
    FETCH_OPERATORS_ERROR
} from "../constants";

const initialState = {
    operators: [],
    isOpsLoading: false
};

function operatorsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_OPERATORS_REQUEST:
            return {
                ...state,
                operators: [],
                isOpsLoading: true
            };

        case FETCH_OPERATORS_SUCCESS:
            return {
                ...state,
                operators: action.operators,
                isOpsLoading: false
            };

        case FETCH_OPERATORS_ERROR:
            return {
                ...state,
                operators: action.message,
                isOpsLoading: false
            };

        default:
            return state;
    }
}

export default operatorsReducer;
