import {
  GET_SECTION_INFO_BY_ID_REQUEST,
  GET_SECTION_INFO_BY_ID_FAILURE,
  GET_SECTION_INFO_BY_ID_SUCCESS}
  from '../constants/documentsTypes';

import {
  DOCS_OPEN_MODAL,
  DOCS_CLOSE_MODAL
} from '../constants';

const initialState = {
  dokumenty: null,
  ['regionalnomu-operatoru']: null,
  zhiuri: null,
  isLoading: false,
  error: '',
  modal: false,
  url: null
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SECTION_INFO_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_SECTION_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.payload.name]: action.payload.data
      };
    case GET_SECTION_INFO_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case DOCS_OPEN_MODAL:
      return {
        ...state,
        modal: true,
        url: action.payload
      };
    case DOCS_CLOSE_MODAL:
      return {
        ...state,
        modal: false
      };

    default:
      return state;
  }
}

export default documentsReducer;
