import React from 'react';

const WinnersSvg = ({color}) => {

    return (
        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 45.1 46.6" styles="enable-background:new 0 0 45.1 46.6;" xmlSpace="preserve">
            <g>
                <path fill={color} d="M36.3,4.1V0H8.8v4.1H0v7.7c0,5.4,4.4,9.8,9.8,9.8h0.5c1.7,3.4,4.9,6,8.6,7v3.7h-2.1c-1.5,0-2.7,1.2-2.7,2.7v1.3h-2.7v10.2
                    h22.6V36.4h-2.7V35c0-1.5-1.2-2.7-2.7-2.7h-2.1v-3.7c3.7-1,6.8-3.6,8.6-7h0.5c5.4,0,9.8-4.4,9.8-9.8V4.1H36.3z M43.1,11.8
                    c0,4.3-3.5,7.8-7.8,7.8h-1.8l-0.3,0.6c-1.5,3.4-4.6,5.9-8.2,6.7L24.3,27v7.3h4.1c0.4,0,0.7,0.3,0.7,0.7v3.3h2.7v6.2H13.3v-6.2h2.7
                    V35c0-0.4,0.3-0.7,0.7-0.7h4.1V27L20,26.8c-3.6-0.8-6.7-3.3-8.2-6.7l-0.3-0.6H9.8c-4.3,0-7.8-3.5-7.8-7.8V6.1h8.8V2h23.6v4.1h8.8
                    V11.8z"/>
                <path fill={color} d="M1.6,37.9c-0.2,0.4-0.2,0.9,0,1.4l1.7,2.1l-1.7,2.1c-0.3,0.4-0.3,0.9-0.1,1.4c0.2,0.5,0.7,0.8,1.2,0.8H11v-8.4H2.7
                    C2.2,37.2,1.8,37.4,1.6,37.9z M5.4,40.7l-1.2-1.5H9v4.4H4.1l1.2-1.5l0.1-0.2C5.6,41.5,5.6,41.1,5.4,40.7z"/>
                <path fill={color} d="M21.9,3.7h-7.2c-1.3,0-2.4,1.1-2.4,2.4v7.1c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4V8.5h4.8c1.3,0,2.4-1.1,2.4-2.4
                    S23.3,3.7,21.9,3.7z M21.9,6.5h-6.8v6.7c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4V6.1c0-0.2,0.2-0.4,0.4-0.4h7.2
                    c0.2,0,0.4,0.2,0.4,0.4S22.2,6.5,21.9,6.5z"/>
                <path fill={color} d="M43.6,38c-0.2-0.5-0.7-0.8-1.2-0.8H34v8.4h8.3c0.5,0,1-0.3,1.2-0.8c0.2-0.5,0.1-1-0.2-1.3l-1.6-2l1.7-2
                    C43.7,39,43.8,38.5,43.6,38z M39.6,42l1.2,1.5H36v-4.4h4.9l-1.2,1.5l-0.1,0.2C39.4,41.2,39.4,41.6,39.6,42z"/>
                <path fill={color} d="M10.7,15.3V8.6H4.4v3.3c0,2.9,2.4,5.3,5.4,5.3h1v-1c0-0.2,0-0.3,0-0.5C10.7,15.5,10.7,15.4,10.7,15.3z M8.7,15
                    c-1.3-0.4-2.3-1.7-2.3-3.1v-1.3h2.3V15z"/>
                <path fill={color} d="M18,43.1h9.1v-6.4H18V43.1z M20,38.7h5.1v2.4H20V38.7z"/>
                <path fill={color} d="M34.3,15.3c0,0.2,0,0.5,0,0.6l-0.2,1.2h1.2c2.9,0,5.3-2.4,5.3-5.3V8.6h-6.3V15.3z M36.3,10.6h2.3v1.3c0,1.5-1,2.7-2.3,3.1
                    V10.6z"/>
            </g>
        </svg>
    )
};

export default WinnersSvg;