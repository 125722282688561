import React, {Component} from 'react';
import MediaPageComponent from './MediaPageComponent';

class MediaPageContainer extends Component {
    render() {
        return (
            <MediaPageComponent
                year={(this.props.location.state && this.props.location.state.year) || '2019'}
                tab={(this.props.location.state && this.props.location.state.tab) || 0}
                popupOpened={(this.props.location.state && this.props.location.state.popupOpened) || 0}
            />
        );
    }
}

export default MediaPageContainer;