export const displayLook = (fileType) => (
    fileType ? fileType.includes('image') || fileType.includes('pdf') : null
);

export const getExtension = url => (
    url.slice(url.lastIndexOf('.')+1).toUpperCase()
);

export const getFileSize = (bites) => (
    Math.round(bites/1024)
);

export const isImage = fileType => (
    fileType.includes('image') || fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'gif'
);

