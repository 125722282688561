import React from "react";
import styled from "styled-components";
import { Row } from "react-flexbox-grid";

import { DownLoadLink, Icon, ActionContainer } from './DocItem';
import {displayLook, isImage} from '../../helpers/workWithFiles';

import download from "../../assets/svg/down.svg";
import look from "../../assets/svg/look.svg";

const DiplomaWrapper = styled.div`
  width: 27%;
  margin-bottom: 15px;
  /* margin-top: 80px; */
  
  @media screen and (max-width: 1024px) {
    width: 45%;
  }
  @media screen and (max-width: 595px) {
    width: 100%;
  }
`;

const DiplomImg = styled.div`
  display: block;
  width: 150px;
  height: 215px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media (max-width: 1024px) {
    margin-top: 25px;
  }
`;

const DiplomTitle = styled.span`
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
  color: #3d4669;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
`;

export const ActionItem = styled.div`
  margin-right: 30px;
  &:last-of-type {
  margin-right: 0;
  }
`;

const DiplomaItem = ({ doc, openModalHandler }) => {
    return (
        <DiplomaWrapper>
            {/* <DiplomImg style={isImage(doc.uploads.file_type) ? {backgroundImage: `url(${doc.uploads.url})`} : {}} /> */}
            <DiplomTitle>
                {doc.name}
            </DiplomTitle>
            <Row>
                <ActionWrapper>
                    {displayLook(doc.uploads.file_type) &&
                        <ActionItem>
                            { isImage(doc.uploads.file_type) ?
                                <ActionContainer>
                                    <DownLoadLink href={doc.uploads.url} target='_blank'>
                                        <Icon BiggerIcon BiggerPadding src={look} alt="" />
                                        Смотреть
                                    </DownLoadLink>
                                </ActionContainer>
                                :
                                <ActionContainer
                                    onClick={() => openModalHandler(doc.uploads.url)}
                                >
                                    <Icon BiggerIcon src={look} alt="" />
                                    Смотреть
                                </ActionContainer>
                            }
                        </ActionItem>
                    }
                    {doc.uploads.download_url &&
                        <ActionItem>
                            <DownLoadLink href={doc.uploads.download_url} download>
                                <ActionContainer>
                                    <Icon src={download} alt="" />
                                    Скачать
                                </ActionContainer>
                            </DownLoadLink>
                        </ActionItem>
                    }
                </ActionWrapper>
            </Row>
        </DiplomaWrapper>
    );
};

export default DiplomaItem;