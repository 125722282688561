
export const FETCH_GRADES_REQUEST = "FETCH_GRADES_REQUEST";
export const FETCH_GRADES_SUCCESS = "FETCH_GRADES_SUCCESS";
export const FETCH_GRADES_ERROR = "FETCH_GRADES_ERROR";

export const FETCH_STATUSES_REQUEST = "FETCH_STATUSES_REQUEST";
export const FETCH_STATUSES_SUCCESS = "FETCH_STATUSES_SUCCESS";
export const FETCH_STATUSES_ERROR = "FETCH_STATUSES_ERROR";

export const FETCH_WINNERS_REQUEST = "FETCH_WINNERS_REQUEST";
export const FETCH_WINNERS_SUCCESS = "FETCH_WINNERS_SUCCESS";
export const FETCH_WINNERS_ERROR = "FETCH_WINNERS_ERROR";

export const FETCH_OPERATORS_REQUEST = "FETCH_OPERATORS_REQUEST";
export const FETCH_OPERATORS_SUCCESS = "FETCH_OPERATORS_SUCCESS";
export const FETCH_OPERATORS_ERROR = "FETCH_OPERATORS_ERROR";

export const FETCH_REGIONS_REQUEST = "FETCH_REGIONS_REQUEST";
export const FETCH_REGIONS_SUCCESS = "FETCH_REGIONS_SUCCESS";
export const FETCH_REGIONS_ERROR = "FETCH_REGIONS_ERROR";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const DOCS_OPEN_MODAL = "DOCS_OPEN_MODAL";
export const DOCS_CLOSE_MODAL = "DOCS_CLOSE_MODAL";




