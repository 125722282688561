import React from "react";
import styled from "styled-components";
import {Row, Col} from "react-flexbox-grid";

import {displayLook, getExtension, getFileSize, isImage} from '../../helpers/workWithFiles';

import download from "../../assets/svg/down.svg";
import look from "../../assets/svg/look.svg";

export const DocsItem = styled.div`
  margin-bottom: 15px;
  height: ${props => props.height};
  width: 100%;
`;

export const DocsNames = styled.p`
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  color: #3d4669;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
`;

export const DocsInfo = styled.span`
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  color: #9fa8b8;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
`;

export const ActionContainer = styled.div`
  text-align: ${props => (props.textAlign ? `right` : `left`)};
  margin-top: ${props => props.marginTop && `15px`};
  font-size: 16px;
  line-height: 17px;
  color: #6873b5;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: 1024px) {
    margin-top: 15px;
    text-align: left;
  }
  
  a {
    color: #6873b5;
  }
`;

export const DownLoadLink = styled.a`
  text-decoration: none;
`;

export const Icon = styled.img`
  width: ${props => (props.BiggerIcon ? `19px` : `12px`)};
  margin-right: 10px;
  vertical-align: middle;
  padding-bottom: ${props => (props.BiggerPadding ? `3px` : `2px`)};
`;

const DocItem = ({doc, openModalHandler}) => {
    return (
        <DocsItem>
            <Row middle="lg">
                <Col lg={8} md={12}>
                    <DocsNames>
                        {doc.name}
                    </DocsNames>
                    <DocsInfo>Документ / {getExtension(doc.file.url)}, {getFileSize(doc.file.size)} Кб</DocsInfo>
                </Col>
                <Col lg={2} md={2} sm={3} xs={5}>
                    {isImage(getExtension(doc.file.url)) ?
                        <ActionContainer textAlign>
                            <DownLoadLink href={doc.file.url} target='_blank'>
                                <Icon BiggerIcon BiggerPadding src={look} alt=""/>
                                Смотреть
                            </DownLoadLink>
                        </ActionContainer>
                        :
                        <ActionContainer
                            textAlign
                            onClick={() => openModalHandler(doc.file.url)}
                        >
                            <Icon BiggerIcon src={look} alt=""/>
                            Смотреть
                        </ActionContainer>
                    }
                </Col>
                {doc.file.url &&
                <Col lg={2} md={2} sm={3} xs={4}>
                    <DownLoadLink href={doc.file.url} download>
                        <ActionContainer textAlign>
                            <Icon src={download} alt=""/>
                            Скачать
                        </ActionContainer>
                    </DownLoadLink>
                </Col>
                }
            </Row>
        </DocsItem>
    );
};

export default DocItem;
