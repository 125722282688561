import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST, GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST } from '../constants/newsTypes';
import {
    getMainNewsByCompetitionIdSuccess,
    getMainNewsByCompetitionIdFailure,
    getAllNewsByCompetitionIdFailure,
    getAllNewsByCompetitionIdSuccess
} from '../actions/newsActions';

export function* getMainNewsByCompetitionId({payload}) {
    // todo: CHANGE REQUEST!
    // const { id } = payload;
    try {
        const response = yield axios.get(`${process.env.REACT_APP_API_URL}/api/v1/main-news`).then().catch(e => console.error(e))
        // const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/actual-contest-calendar-event`);
        const data = response.data;
        yield put(getMainNewsByCompetitionIdSuccess(data));
    }
    catch (error) {
        const message = "error, please try again";
        yield put(getMainNewsByCompetitionIdFailure(message));
    }
}

export function* getAllNewsByCompetitionId({payload}) {
    // todo: CHANGE REQUEST!
    // const { id } = payload;
    try {
        const response = yield axios.get(`${process.env.REACT_APP_API_URL}/api/v1/news?${payload.query}`).then().catch(e => console.error(e))
        // const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/actual-contest-calendar-event`);
        const data = response.data;
        yield put(getAllNewsByCompetitionIdSuccess(data));
    }
    catch (error) {
        const message = "error, please try again";
        yield put(getAllNewsByCompetitionIdFailure(message));
    }
}

export function* newsActionWatcher() {
    yield takeLatest(GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST, getMainNewsByCompetitionId);
    yield takeLatest(GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST, getAllNewsByCompetitionId)
}
