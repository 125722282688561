import React, { Component } from "react";
import { connect } from "react-redux";
import OperatorPageComponent from "./OperatorPageComponent";

class OperatorPageContainer extends Component {
  componentDidMount() {
    this.props.fetchOperatorsRequest();
    this.props.fetchRegionsRequest();
  }

  render() {
    return <OperatorPageComponent operators={this.props.operators} regions={this.props.regions} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOperatorsRequest: () => dispatch({ type: "FETCH_OPERATORS_REQUEST" }),
    fetchRegionsRequest: () => dispatch({ type: "FETCH_REGIONS_REQUEST" })
  };
};

const mapStateToProps = state => ({
  operators: state.operators.operators,
  isOpsLoading: state.operators.isOpsLoading,
  regions: state.regions.regions,
  isRegsLoading: state.regions.isRegsLoading
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperatorPageContainer);
