import React, { Component } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-paginating";

import chevron from "../../assets/svg/right-thin-chevron.svg";
import {getCompetitionId} from '../../helpers/getCompetitionId';

const CustomPage = styled(Link)`
margin-right: 0.3em;
user-select: none;
cursor: pointer;
/* color: ${props => (props.iscurrent === `true` ? `#3d4669` : `#6873b5`)}; */
/* background: ${props => (props.iscurrent === `true` ? `#d9ddf2` : `#fff`)}; */
font-family: "Roboto Condensed", sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 20.25px;
text-transform: uppercase;
padding: 6px 12px;
text-decoration: none;
/* Text style for "1," */
color: #3d4669;

&:hover,
&:active {
color: #3d4669;
background: #d9ddf2;
}
`;

const PaginationArrow = styled.div`
  display: inline-block;
  vertical-align: middle;
  & img {
    width: 15px;
    height: 15px;
    padding-top: 2px;
    transform: translateY(-2px);
  }
`;

const PaginationArrowLeft = styled.div`
  display: inline-block;
  vertical-align: middle;
  & img {
    width: 15px;
    height: 15px;
    padding-top: 2px;
    transform: rotate(180deg);
  }
`;

class PaginationUI extends Component {
  render() {
    return (
      <>
        <Pagination
          total={this.props.total}
          limit={this.props.limit}
          currentPage={this.props.current_page}
        >
          {({ pages, currentPage, hasNextPage, hasPreviousPage }) => (
            <div>
              {hasPreviousPage && (
                <CustomPage
                  to={`/news?competitionId=${this.props.competitionId}&page=${currentPage - 1}`}
                  className="Pagination__page-btn"
                  // onClick={() => this.props.pageHandler(currentPage - 1)}
                >
                  <PaginationArrowLeft>
                    <img src={chevron} alt="" />
                  </PaginationArrowLeft>{" "}
                  назад
                </CustomPage>
              )}
              {pages.map(page => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = {
                    backgroundColor: "#d9ddf2",
                    textDecoration: "none",
                    pointerEvents: "none"
                  };
                }
                return (
                  <CustomPage
                    to={`/news?competitionId=${this.props.competitionId}&page=${page}`}
                    className="Pagination__page-btn"
                    key={page}
                    style={activePage}
                  >
                    {page}
                  </CustomPage>
                );
              })}
              {hasNextPage && (
                <CustomPage
                  to={`/news?competitionId=${this.props.competitionId}&page=${currentPage + 1}`}
                  className="Pagination__page-btn"
                >
                  вперед{" "}
                  <PaginationArrow>
                    <img src={chevron} alt="" />
                  </PaginationArrow>
                </CustomPage>
              )}
            </div>
          )}
        </Pagination>
      </>
    );
  }
}

export default withRouter(PaginationUI);
