import React, { Component } from 'react';
import axios from "axios";
import AboutPageComponent from "./AboutPageComponent";

class AboutPageContainer extends Component {
    render() {
        return (
            <AboutPageComponent />
        )
    }
}

export default AboutPageContainer;