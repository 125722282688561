import React, { Component } from 'react';
import { connect } from "react-redux";
import MainComponent from "./HomeComponent";
import { getCompetitionInfoRequest, getMainCompetitionInfoRequest } from '../../actions/competitionsActions';

class HomeContainer extends Component {

    componentDidMount() {
        if (this.props.match && this.props.match.params && !this.props.match.params.competitionId) {
            this.props.getMainCompetitionInfoRequest()
        }
        if (this.props.match && this.props.match.params && this.props.match.params.competitionId) {
            this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match && this.props.match.params && this.props.match.params.competitionId
            && this.props.match.params.competitionId !== prevProps.match.params.competitionId) {
            this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
        }
        if (this.props.match && this.props.match.params && !this.props.match.params.competitionId &&
            this.props.match.url !== prevProps.match.url) {
            this.props.getMainCompetitionInfoRequest()
        }
    }

    render() {
        return (
            <MainComponent
                competitionInfo={this.props.competitions.info}
                competitionId={this.props.match.params.competitionId}
                competitionOptions={this.props.competitions.options}
                {...this.props}
            />
        )
    }
}

const mapStateToProps = ({competitions}) => ({
    competitions
  });

const mapDispatchToProps = dispatch => {
    return {
        getCompetitionInfoRequest: (id) => dispatch(getCompetitionInfoRequest(id)),
        getMainCompetitionInfoRequest: () => dispatch(getMainCompetitionInfoRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
