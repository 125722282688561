import {
    FETCH_GRADES_REQUEST,
    FETCH_GRADES_SUCCESS,
    FETCH_GRADES_ERROR,
    FETCH_STATUSES_REQUEST,
    FETCH_STATUSES_SUCCESS,
    FETCH_STATUSES_ERROR,
    FETCH_WINNERS_REQUEST,
    FETCH_WINNERS_SUCCESS,
    FETCH_WINNERS_ERROR,
} from "../constants";

import {
    GET_WINNERS_FOR_MAIN_PAGE_REQUEST,
    GET_WINNERS_FOR_MAIN_PAGE_SUCCESS,
    GET_WINNERS_FOR_MAIN_PAGE_FAILURE,
    GET_ALL_WINNERS_FOR_COMPETITION_FAILURE,
    GET_ALL_WINNERS_FOR_COMPETITION_REQUEST,
    GET_ALL_WINNERS_FOR_COMPETITION_SUCCESS
} from '../constants/winnersTypes';

export const fetchWinGradesRequest = () => ({
    type: FETCH_GRADES_REQUEST
});

export const fetchWinGradesSuccess = (grades) => ({
    type: FETCH_GRADES_SUCCESS,
    grades,
});

export const fetchWinGradesError = (message) => ({
    type: FETCH_GRADES_ERROR,
    message,
});

export const fetchWinStatusesRequest = () => ({
    type: FETCH_STATUSES_REQUEST
});

export const fetchWinStatusesSuccess = (statuses) => ({
    type: FETCH_STATUSES_SUCCESS,
    statuses,
});

export const fetchWinStatusesError = (message) => ({
    type: FETCH_STATUSES_ERROR,
    message,
});

export const fetchWinnersRequest = () => ({
    type: FETCH_WINNERS_REQUEST
});

export const fetchWinnersSuccess = (winners) => ({
    type: FETCH_WINNERS_SUCCESS,
    winners,
});

export const fetchWinnersError = (message) => ({
    type: FETCH_WINNERS_ERROR,
    message,
});

export const getWinnersForMainPageRequest = (id) => ({
    type: GET_WINNERS_FOR_MAIN_PAGE_REQUEST,
    payload: id
});

export const getWinnersForMainPageSuccess = (data) => ({
    type: GET_WINNERS_FOR_MAIN_PAGE_SUCCESS,
    payload: data
});

export const getWinnersForMainPageFailure = (error) => ({
    type: GET_WINNERS_FOR_MAIN_PAGE_FAILURE,
    payload: error
})

export const getAllWinnersForCompetitionRequest = (id) => ({
    type: GET_ALL_WINNERS_FOR_COMPETITION_REQUEST,
    payload: id
});

export const getAllWinnersForCompetitionSuccess = (data) => ({
    type: GET_ALL_WINNERS_FOR_COMPETITION_SUCCESS,
    payload: data
});

export const getAllWinnersForCompetitionFailure = (error) => ({
    type: GET_ALL_WINNERS_FOR_COMPETITION_FAILURE,
    payload: error
})
