import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru';
import Truncate from 'react-truncate';
import {Link} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {Grid, Row, Col} from 'react-flexbox-grid';
import {PageContainer} from '../../styles/containers';
import {H2, H3, Text} from '../../styles/fonts';
import Button from '../UI/Button';
import PaginationUI from '../UI/pagination';
import image_1 from '../../assets/img/media-1.jpeg';
import image_2 from '../../assets/img/media-2.jpeg';
import image_3 from '../../assets/img/media-3.jpeg';
import {getMonth} from '../helpers/getMonth';
import HomeInformationCalendar from '../Home/HomeInformation/HomeInformationCalendar';
import {getCompetitionId} from '../../helpers/getCompetitionId';

const NewsPageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 45px;
`;

export const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

const CalendarCol = styled(Col)`
  position: relative;
  padding-left: 56px;
  @media (max-width: 991px) {
    padding-left: 0;
  }
`;

const CalendarH2 = styled(H2)`
  text-align: left;
  margin-bottom: 53px;
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 53px;
  }
`;

const CalendarH3 = styled(H3)`
  margin-top: 9px;
  margin-bottom: 15px;
  font-size: 22px;
  text-decoration: underline;
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

const CustomBtnDiv = styled.div`
  width: 100%;
  padding-top: 6px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Roboto Condensed";
  color: ${props => props.color};
  background-color: #fff;
  border: 1px solid #b7c5d8;
  text-transform: uppercase;
  outline: 0;
  text-align: center;
  z-index: 10;
  transition: 0.3s;
  &:hover {
    color: #fff;
    background-color: #4c579a;
  }
`;

const CardTextContainer = styled(Col)`
  padding-left: 45px;
  @media (max-width: 757px) {
    padding-left: 0;
  }
`;

const Date = styled.span`
  font-size: 72px;
  letter-spacing: 3px;
  line-height: 30px;
  color: #00bcd6;
  font-family: "Roboto Condensed";
  font-weight: 700;
`;

const Month = styled.span`
  font-size: 25px;
  line-height: 30px;
  color: #00bcd6;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  width: 75px;
  height: 55px;
  top: 18px;
  right: 0;
  background-color: #00bcd6;
`;

export const InformationH3 = styled(H3)`
  padding-top: 5px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 37px;
`;

const Image = styled.div`
  width: 220px;
  height: 165px;
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
  /* border: 1px solid black; */
`;

const RightText = styled(Text)`
  position: absolute;
  width: 95px;
  top: 29px;
  right: 17px;
  /* margin-left: 26px;
  padding-top: 28px; */
  line-height: 24px;
  text-decoration: underline;
  /* cursor: pointer; */
  &:hover {
    /* text-decoration: none; */
  }
  @media (max-width: 991px) {
    left: 10px;
    top: 87px;
    right: auto;
  }
  @media (max-width: 768px) {
    left: auto;
    top: 29px;
    right: 17px;
  }
`;

const CardTitleText = styled.span`
  margin-left: ${props => (props.marginLeft ? `6px` : ``)};
  font-size: ${props => (props.smallerTitle ? `15px` : `24px`)};
  letter-spacing: 1px;
  line-height: 21px;
  color: #6873b5;
  font-family: "Roboto";
  font-weight: 700;
  text-transform: uppercase;
`;

const CardText = styled.p`
  font-size: ${props => (props.smallerText ? `15px` : `16px`)};
  line-height: 22px;
  color: #232c5f;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: ${props => (props.underline ? `underline` : ``)};
`;

const MediaCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 280px;
  background-color: gray;
  font-size: 22px;
  text-align: center;

  margin-right: -7px;
`;

const CardTitle = styled.div`
  padding: 5px 22px;
  background-color: #6873b5;
  margin-bottom: 35px;
  font-size: 18px;
  /* letter-spacing: 1px; */
  color: #fff;
  font-family: "Roboto Condensed";
  font-weight: 400;
  text-transform: uppercase;
`;

const CalendarWrapper = styled.div`
    .title_h2 {
        margin: 30px 0;
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 40px;
        color: #10215e;
        font-family: "Roboto Condensed";
        font-weight: 700;
        text-transform: uppercase;
    }
`;

const SwitchTransition = styled(Col)``;

const MainNews = props => {
    return (
        <Row>
            {/* {console.log(props)} */}
            <Col lg={11} md={12} sm={12} xs={12}>
                {props.mainisLoading === false && props.main_news && (
                    <NewsCard
                        id={props.main_news.id}
                        published_at={props.main_news.published_at}
                        title={props.main_news.title}
                        preview={props.main_news.preview}
                        image={
                            props.main_news &&
                            props.main_news.thumb_urls &&
                            props.main_news.thumb_urls.previewPicture
                                ? props.main_news.thumb_urls.previewPicture.c_news275x190
                                : ``
                        }
                    />
                )}
            </Col>
        </Row>
    );
};

const NewsList = props => {
    return (
        <>
            <Row>
                <Col lg={11}>
                    {props.loading === false && props.news &&
                    props.news.items.map((item, id) => (
                        <NewsCard
                            key={id}
                            id={item.id}
                            published_at={item.published_at}
                            title={item.title}
                            preview={item.preview}
                            competitions={props.competitions}
                            image={
                                item && item.thumb_urls && item.thumb_urls.previewPicture
                                    ? item.thumb_urls.previewPicture.c_news275x190
                                    : ``
                            }
                        />
                    ))}
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {props.loading === false && props.pagination && props.pagination.total_items && (
                        <PaginationUI
                            competitionId={props.competitions.info && props.competitions.info.type && getCompetitionId(props.competitions.info.type.id, props.competitions.options)}
                            total={props.pagination && props.pagination.total_items}
                            limit={props.pagination && props.pagination.items_per_page}
                            current_page={props.pagination && props.pagination.current_page}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

const NewsCard = props => {
    return (
        <CardContainer>
            {/*{console.log(props)}*/}
            <Row>
                <Col lg={4} md={5} sm={5} xs={5}>
                    <Image
                        style={{
                            background: `url(${props.image})`,
                            backgroundPosition: '50%',
                            backgroundSize: 'cover'
                        }}
                    />
                </Col>
                <CardTextContainer lg={8} md={7} sm={7} xs={12}>
                    <CardTitleText>
                        {moment(props.published_at).format('D')}
                        <CardTitleText smallerTitle marginLeft>
                            {getMonth(moment(props.published_at).format('MMMM'))}{' '}
                        </CardTitleText>
                        <CardTitleText marginLeft>
                            {moment(props.published_at).format('YYYY')}{' '}
                        </CardTitleText>
                        <CardTitleText smallerTitle style={{marginLeft: '-5px'}}>
                            г.
                        </CardTitleText>
                        <CardTitleText smallerTitle marginLeft>
                            {moment(props.published_at).format('HH:mm')}
                        </CardTitleText>
                    </CardTitleText>
                    {props.competitions && props.competitions.info && props.competitions.info.type &&
                    <Link to={`/news/detail/${props.id}/${getCompetitionId(props.competitions.info.type.id, props.competitions.options)}`}>
                        <CardText underline>
                            <Truncate lines={2}>{props.title}</Truncate>
                        </CardText>
                    </Link>
                    }
                    <CardText smallerText>
                        <Truncate lines={3}>{props.preview}</Truncate>
                    </CardText>
                </CardTextContainer>
            </Row>
        </CardContainer>
    );
};

const NewsPageComponent = props => {
    return (
        <NewsPageContainer>
            <Grid fluid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs
                            separator={<span style={{color: '#39404c'}}> / </span>}
                            finalItem={'b'}
                            finalProps={{
                                style: {color: '#6873b5', fontWeight: 'normal'}
                            }}
                        />
                        <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
                        {props.competitions.info && props.competitions.info.type &&
                        <BreadcrumbsItem
                            to={`/news/?competitionId=${getCompetitionId(props.competitions.info.type.id, props.competitions.options)}&page=1`}>Новости</BreadcrumbsItem>
                        }
                    </Col>
                </Row>
                <Row>
                    <SwitchTransition lg={8}>
                        <Row style={{marginBottom: '10px'}}>
                            <Col lg={5}>
                                <H2>Новости конкурса</H2>
                            </Col>
                            <Col lg={4}>
                                {/* <InformationH3>Новости регионов</InformationH3> */}
                            </Col>
                        </Row>
                        <MainNews {...props} />
                        <NewsList {...props} />
                    </SwitchTransition>
                    <Col lg={4} style={{position: 'relative'}}>
                        <Row>
                            <CalendarWrapper>
                                {
                                    props.calendar.mainEvent && props.calendar.mainEvent.stage &&
                                    <HomeInformationCalendar calendar={props.calendar} competitionId={
                                        props.competitions && props.competitions.info && props.competitions.options && props.competitions.info.type &&
                                        getCompetitionId(props.competitions.info.type.id, props.competitions.options)}/>
                                }
                            </CalendarWrapper>

                        </Row>
                        {/*<Row between="lg" middle="lg" style={{marginTop: '33px'}}>
                            <Col lg={6}>
                                <H2>Медиатека</H2>
                            </Col>
                            <Col
                                lg={6}
                                style={{
                                    textAlign: 'right',
                                    paddingRight: '0',
                                    paddingLeft: '0'
                                }}
                            >
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                lg={12}
                                md={4}
                                sm={4}
                                style={{
                                    // paddingRight: "0",
                                    // paddingLeft: "0",
                                    marginBottom: '40px'
                                }}
                            >
                                <MediaCard
                                    style={{
                                        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_1})`,
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <CardTitle>Видеообращениe 2020</CardTitle>
                                </MediaCard>
                            </Col>
                            <Col
                                lg={12}
                                md={4}
                                sm={4}
                                style={{
                                    // paddingRight: "0",
                                    // paddingLeft: "0",
                                    marginBottom: '40px'
                                }}
                            >
                                <MediaCard
                                    style={{
                                        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_2})`,
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <CardTitle>Фотоархив</CardTitle>
                                </MediaCard>
                            </Col>
                            <Col
                                lg={12}
                                md={4}
                                sm={4}
                                style={{
                                    // paddingRight: "0",
                                    // paddingLeft: "0",
                                    marginBottom: '40px'
                                }}
                            >
                                <MediaCard
                                    style={{
                                        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_3})`,
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <CardTitle>Видеоархив</CardTitle>
                                </MediaCard>
                            </Col>
                        </Row>*/}
                    </Col>
                </Row>
            </Grid>
        </NewsPageContainer>
    );
};

export default NewsPageComponent;
