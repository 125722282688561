import React, { Component } from "react";
import { connect } from "react-redux";
import HomeModalComponent from "./HomeModalComponent";

class HomeModalContainer extends Component {
  componentWillReceiveProps(props) {
    if (props.modal) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }

  render() {
    const { item, modal } = this.props;
    return <HomeModalComponent item={item} modal={modal} closeModalHandler={this.props.closeModalHandler} />;
  }
}

const mapDispatchToProps = dispatch => {
  return{
    closeModalHandler: () => dispatch({ type: "CLOSE_MODAL" })
  }
}

const mapStateToProps = (state) => ({
  modal: state.winners.modal,
  item: state.winners.item
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeModalContainer);
