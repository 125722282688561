import React, { useState, useEffect, useRef } from "react";
import "./HomeTitleComponent.scss";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Row, Col} from "react-flexbox-grid";
import ReactHtmlParser from 'react-html-parser';
import icon_reg from "../../../assets/svg/k4.svg"; //ico-reg.svg
import right_birds from "../../../assets/svg/birds1.svg";
import left_birds from "../../../assets/svg/birds2.svg";
import { items } from '../../../helpers/logosLinks';
import { createPath } from '../../../helpers/createPath';

const Hometitle = styled.div`
  position: relative;
  width: 100%;
  height: 667px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  // margin-top: -100px;
  @media (max-width: 1279px) {
    height: 624.5px;
    /* background-size: 100%; */
  }
  // @media (max-width: 1024px) {
  //   height: 532.5px;
  // }
  // @media (max-width: 768px) {
  //   height: 399.5px;
  // }
  // @media (max-width: 320px) {
  //   height: 399.5px;
  // }
`;

const Title = styled.h1`
  margin-bottom: 115px;
  font-size: 46px;
  line-height: 53px;
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 700;
  text-transform: uppercase;

  // @media (max-width: 1279px) {
  //   display: none !important;
  // }
`;

const TitleImage = styled.img`
  width: 531px;
  height: 253px;
  padding-top: 25px;
  position: relative; 
  z-index: 1;
  // @media (max-width: 1279px) {
  //   display: none !important;
  // }
`;

// const LowResImage = styled.div`
//   @media (max-width: 1279px) {
//     width: 100%;
//     height: 333.5px;
//     margin-top: -100px;
//     background: url(${bg}) 50% 45% no-repeat;
//     background-size: cover;
//   }
// `;

const LogoImg = styled.img`
  display: none;
  width: 223px;
  padding-top: 14px;
  @media (max-width: 1279px) {
    display: block !important;
  }
  @media (max-width: 1089px) {
    width: 531px;
    padding-top: 0;
  }
  @media (max-width: 820px) {
    width: 300px;
    padding-top: 0;
  }
`;

const TitleItem = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  font-family: "Roboto Condensed";
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

const TitleItemSpan = styled.span`
  transition: 0.3s;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: relative;
  display: block;
  height: 90px;
  width: 115px;
  left: -10px;
  margin: 0 auto;
  margin-bottom: 10px;
  z-index: 10;
`;

const CustomImage = styled(Image)`
  width: 121px;
  left: 9px;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  &:hover {
  span {
      color: #3ce7ff;
    }
  }
`;

const RightBirds = styled.img`
    position: absolute;
    width: 325px;
    top: 138px;
    left: 370px;
    opacity: .5;
  @media (max-width: 1279px) {
    display: none !important;
  }
`;

const LeftBirds = styled.img`
    position: absolute;
    width: 140px;
    bottom: -64px;
    left: -107px;
  @media (max-width: 1279px) {
    display: none !important;
  }
`;

const CustomRow = styled(Row)`
  padding-bottom: 25px;
  justify-content: flex-end;
  @media (max-width: 1279px) {
    display: none !important;
  }
`;

const Background = styled.div`
  /* background: rgb(45, 68, 142);
  background: linear-gradient(
    180deg,
    rgb(40, 63, 138) 71%,
    rgba(27, 47, 111, 1) 100%
  ); */
  background: #1b2f6e;
/* background: linear-gradient(180deg, rgba(10,56,133,1) 0%, rgba(0,42,111,1) 50%); */
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
`;

const BackgroundPolygon = styled.div`
  position: absolute;
  height: 900px;
  background: rgba(16,33,94, 0.7);
    left: 0;
    right: 0;
    clip-path: polygon(0 0,100% 0,100% 3%,0 22%);
    @media (max-width: 1920px) {
    display: none;
  }
`;

const CompetitionWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    @media (min-width: 85em) {
      width: 85.625rem;
    }
`

const HomeTitleComponent = props => {

    const [titleInfo, setTitleInfo] = useState(null);
    const [dimention, setDimention] = useState(null);
    const image = useRef(null);

    const dimentions = {
        vertical: {
            flexFlow: 'row wrap',
            alignItems: 'center'
        },
        horizontal: {
            flexFlow: 'column nowrap',
            alignItems: 'flex-start'
        }
    }

    useEffect(() => {
        if(props.competitionInfo) {
            setTitleInfo(props.competitionInfo)
        }
    }, [props]);

    useEffect(() => {
        measureImage();
    }, [props.competitionInfo && props.competitionInfo.logo && props.competitionInfo.logo.url])

    const measureImage = () => {
        if (image.current) {
            if (image.current.offsetWidth > image.current.offsetHeight) {
                setDimention('horizontal')
            }
            else setDimention('vertical');
        }
    }

    return (
        <Hometitle
            style={{
                background: ` url(${props.resBg}) center no-repeat`,
                backgroundSize: 'cover'
            }}
        >
            <BackgroundPolygon/>
            <Background/>

            <CompetitionWrapper>
                <RightBirds src={right_birds} alt=""/>
                <div className="container-fluid CompetitionFlex">
                    <div className="Competition">
                        <LeftBirds src={left_birds} alt=""/>
                        <div className="Title_Wrapper" style={dimentions[dimention]}>
                            {!props.isLoading &&
                            <>
                                {titleInfo && titleInfo.logo && titleInfo.logo.url &&
                                <img className='Image'
                                     ref={image}
                                     src={titleInfo.logo.url}
                                     alt={titleInfo && titleInfo.name}
                                     onLoad={measureImage}
                                />
                                }
                                <div
                                    className={`Title ${titleInfo && titleInfo.logo && titleInfo.logo.url ? '' : 'Title_Big'}`}>
                                    {ReactHtmlParser(titleInfo && titleInfo.name)}
                                </div>
                            </>}
                        </div>
                    </div>
                    <CustomRow>
                        {
                            props.items && items.filter(({id}) => props.items.includes(id)).map((item, i) => (
                                <Col lg={3} style={{position: "relative"}} key={i}>
                                    <MenuLink to={`${item.link}/${item.id}/${createPath(props.competitionOptions, props.competitionInfo)}`}>
                                        <ImageContainer>
                                            {/* <AbsoluteDiv /> */}
                                            <CustomImage src={item.img} alt=""/>
                                        </ImageContainer>
                                        <TitleItem style={{marginTop: "-5px"}}>
                                            <TitleItemSpan>{ReactHtmlParser(item.title)}</TitleItemSpan>
                                        </TitleItem>
                                    </MenuLink>
                                </Col>
                            ))
                        }
                        <Col lg={3} style={{position: "relative"}}>
                            <MenuLink to={`/operator`}>
                                <ImageContainer>
                                    {/* <AbsoluteDiv /> */}
                                    <CustomImage src={icon_reg} alt=""/>
                                </ImageContainer>
                                <TitleItem style={{marginTop: "-5px"}}>
                                    <TitleItemSpan>
                                        Ответственные операторы <br/>
                                        регионального этапа Конкурса
                                    </TitleItemSpan>
                                </TitleItem>
                            </MenuLink>
                        </Col>
                    </CustomRow>
                </div>
            </CompetitionWrapper>
        </Hometitle>
    );
};

export default HomeTitleComponent;
