import photo1 from '../../assets/img/media2018/1.jpg';
import photo2 from '../../assets/img/media2018/2.jpg';
import photo3 from '../../assets/img/media2018/3.jpg';
import photo4 from '../../assets/img/media2018/4.jpg';
import photo5 from '../../assets/img/media2018/5.jpg';
import photo6 from '../../assets/img/media2018/6.jpg';
import photo7 from '../../assets/img/media2018/7.jpg';
import photo8 from '../../assets/img/media2018/8.jpg';
import photo9 from '../../assets/img/media2018/9.jpg';
import photo10 from '../../assets/img/media2018/10.jpg';
import photo11 from '../../assets/img/media2018/11.jpg';
import photo12 from '../../assets/img/media2018/12.jpg';
import photo13 from '../../assets/img/media2018/13.jpg';
import photo14 from '../../assets/img/media2018/14.jpg';
import photo15 from '../../assets/img/media2018/15.jpg';
import photo16 from '../../assets/img/media2018/16.jpg';
import photo17 from '../../assets/img/media2018/17.jpg';
import photo18 from '../../assets/img/media2018/18.jpg';
import photo19 from '../../assets/img/media2018/19.jpg';
import photo20 from '../../assets/img/media2018/20.jpg';
import photo21 from '../../assets/img/media2018/21.jpg';
import photo22 from '../../assets/img/media2018/22.jpg';
import photo23 from '../../assets/img/media2018/23.jpg';
import photo24 from '../../assets/img/media2018/24.jpg';
import photo25 from '../../assets/img/media2018/25.jpg';
import photo26 from '../../assets/img/media2018/26.jpg';
import photo27 from '../../assets/img/media2018/27.jpg';
import photo28 from '../../assets/img/media2018/28.jpg';
import photo29 from '../../assets/img/media2018/29.jpg';
import photo30 from '../../assets/img/media2018/30.jpg';
import photo31 from '../../assets/img/media2018/31.jpg';
import photo32 from '../../assets/img/media2018/32.jpg';

const photos2018 = [
    {
        id: 1,
        url: photo1
    },
    {
        id: 2,
        url: photo2
    },
    {
        id: 3,
        url: photo3
    },
    {
        id: 4,
        url: photo4
    },
    {
        id: 5,
        url: photo5
    },
    {
        id: 6,
        url: photo6
    },
    {
        id: 7,
        url: photo7
    },
    {
        id: 8,
        url: photo8
    },
    {
        id: 9,
        url: photo9
    },
    {
        id: 10,
        url: photo10
    },
    {
        id: 11,
        url: photo11
    },
    {
        id: 12,
        url: photo12
    },
    {
        id: 13,
        url: photo13
    },
    {
        id: 14,
        url: photo14
    },
    {
        id: 15,
        url: photo15
    },
    {
        id: 16,
        url: photo16
    },
    {
        id: 17,
        url: photo17
    },
    {
        id: 18,
        url: photo18
    },
    {
        id: 19,
        url: photo19
    },
    {
        id: 20,
        url: photo20
    },
    {
        id: 21,
        url: photo21
    },
    {
        id: 22,
        url: photo22
    },
    {
        id: 23,
        url: photo23
    },
    {
        id: 24,
        url: photo24
    },
    {
        id: 25,
        url: photo25
    },
    {
        id: 26,
        url: photo26
    },
    {
        id: 27,
        url: photo27
    },
    {
        id: 28,
        url: photo28
    },
    {
        id: 29,
        url: photo29
    },
    {
        id: 30,
        url: photo30
    },
    {
        id: 31,
        url: photo31
    },
    {
        id: 32,
        url: photo32
    }
];

export default photos2018;