import icon_teach from '../assets/svg/k1.svg';
import icon_student from '../assets/svg/k2.svg';
import icon_organize from '../assets/svg/k3.svg';


export const items = [
    {
        id: 2,
        link: '/teacher',
        title: 'Учителю',
        img: icon_teach
    },
    {
        id: 4,
        link: '/student',
        title: 'Участнику',
        img: icon_student
    },
    {
        id: 3,
        link: '/organizer',
        title: 'Организатору',
        img: icon_organize
    }
]
