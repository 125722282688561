import React, { Component } from "react";
import styled from "styled-components";
import { Col, Grid, Row } from "react-flexbox-grid";
import { PageContainer } from "../../styles/containers";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { BreadcrumbsItemLink } from "../NewsPage/NewsPageComponent";
import Accordion from "../UI/Accordion";
import { H2 } from "../../styles/fonts";
// import qa from "./qa";
import Popup from "../UI/Popup";
import PopupContent from "./PopupContent";
import axios from "axios";

export const QAPageWrapper = styled(PageContainer)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AccordionContainer = styled.div`
  margin: 0 auto;
  max-width: 950px;
  & > div {
    width: 100%;
  }

  margin-bottom: 60px;
`;

const MoreWrapper = styled(Row)`
  justify-content: center;
  margin-bottom: 50px;
`;

const More = styled.div`
  justify-content: center;
  cursor: pointer;
  color: #6873b5;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 26px;
  text-decoration: underline;
`;

const Arrow = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transition: all 0.2s;
  color: #3d4669;
  cursor: pointer;
  margin-left: 12px;
`;

const ArrowUp = styled(Arrow)`
  transform: rotate(-135deg);
`;

const ArrowDown = styled(Arrow)`
  transform: rotate(45deg);
`;

const P = styled.p`
  margin-bottom: 60px;
  color: #3d4669;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 26px;

  & > a {
    color: #6873b5;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MappingDiv = styled.div`
  display: flex;
  margin-bottom: 9px;
`;

const IdDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 45px;
  margin-right: 46px;
`;

const IdSpan = styled.span`
  align-self: flex-start;
  font-size: 40px;
  letter-spacing: 2px;
  color: #7db9d8;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-decoration: underline;
`;

// const PopupInner = <PopupContent />;

class QAPageContainer extends Component {
  state = {
    qa: null,
    indexOfFirstQA: 0,
    indexOfLastQA: 11,
    popupOpened: false
  };

  componentWillMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/faq`).then(response => {
      this.setState({
        qa: response.data
      })
    })
  }

  handleMoreClick = () => {
    this.setState(prevState => ({
      indexOfLastQA: prevState.indexOfLastQA + 12
    }));
  };

  handleLessClick = () => {
    this.setState({ indexOfLastQA: 11 });
  };

  handleOpen = () => {
    this.setState({ popupOpened: true });
    document.body.style.overflow = 'hidden'
  };

  handleClose(e) {
    const ESC = 27;
    if (e.keyCode === ESC) {
      this.setState({ popupOpened: false });
      document.body.style.overflow = 'auto'
    }
  }

  handleCloseForm =() => {
          this.setState({ popupOpened: false });
          document.body.style.overflow = 'auto'
  	  }

  render() {
    const {qa} = this.state
    const PopupInner = <PopupContent handleClose={this.handleCloseForm} />
    const { indexOfFirstQA, indexOfLastQA, popupOpened } = this.state;
    const currentQA = qa && qa.slice(indexOfFirstQA, indexOfLastQA);

    return (
      <QAPageWrapper>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              {console.log(qa)}
              <Breadcrumbs
                separator={<span style={{ color: "#39404c" }}> / </span>}
                finalItem={"b"}
                finalProps={{
                  style: { color: "#6873b5", fontWeight: "normal" }
                }}
              />
              <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
              <BreadcrumbsItem to={`/news/?page=1`}>
                Вопрос - ответ
              </BreadcrumbsItem>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <H2>Вопрос - ответ</H2>
            </Col>
          </Row>
          <AccordionContainer>
            {qa && currentQA.map((qa, i) => (
              <MappingDiv key={i}>
                <IdDiv>
                  <IdSpan>{i + 1}</IdSpan>
                </IdDiv>
                <Accordion
                  // key={i}
                  header={qa.question}
                  body={qa.answer}
                  btnPosition="right"
                />
              </MappingDiv>
            ))}
          </AccordionContainer>
          {qa && indexOfLastQA < qa.length && (
            <MoreWrapper>
              <More onClick={this.handleMoreClick}>
                еще вопросы
                <ArrowDown />
              </More>
            </MoreWrapper>
          )}
          {qa && indexOfLastQA > qa.length && (
            <MoreWrapper>
              <More onClick={this.handleLessClick}>
                скрыть
                <ArrowUp />
              </More>
            </MoreWrapper>
          )}
          <P>

          </P>

        </Grid>
      </QAPageWrapper>
    );
  }
}

export default QAPageContainer;
