import React, { Component } from "react";
import CalendarPageComponent from "./CalendarPageComponent";
import { connect } from "react-redux";
import {fetchAllCalendarEventsRequest, fetchMainCalendarEventRequest} from '../../actions/calendarActions';

class CalendarPageContainer extends Component {

  componentDidMount() {
    const competitionId = this.props.match.params.competitionId;
    this.props.fetchAllCalendarEventsRequest(competitionId)
    this.props.fetchMainCalendarEventRequest(competitionId);
  }

  render() {
    return <CalendarPageComponent { ...this.props } />;
  }
}

const mapStateToProps = ({calendar}) => ({
  calendar
});

const mapDispatchToProps = dispatch => {
  return {
    fetchAllCalendarEventsRequest: (id) =>
        dispatch(fetchAllCalendarEventsRequest(id)),
    fetchMainCalendarEventRequest: (id) => {
      dispatch(fetchMainCalendarEventRequest(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPageContainer);
