import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import {
    GET_COMPETITION_OPTIONS_REQUEST,
    GET_COMPETITION_INFO_REQUEST,
    GET_MAIN_COMPETITION_INFO_REQUEST,
    GET_ALL_COMPETITIONS_ID_REQUEST
} from "../constants/competitionsTypes";
import {
    getCompetitionOptionsFailure, getCompetitionOptionsSuccess,
    getCompetitionInfoFailure, getCompetitionInfoSuccess,
    getMainCompetitionInfoFailure, getMainCompetitionInfoSuccess,
    getAllCompetitionsIdFailure, getAllCompetitionsIdSuccess
} from '../actions/competitionsActions';

export function* getCompetitionOptions() {
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/contest-types`);
        const data = response.data;
        yield put(getCompetitionOptionsSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getCompetitionOptionsFailure(message));
    }
}

export function* competitionActionWatcher() {
    yield takeLatest(GET_COMPETITION_OPTIONS_REQUEST, getCompetitionOptions)
}

export function* getCompetitionInfo({payload}) {
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/contest/${payload}`);
        const data = response.data;
        yield put(getCompetitionInfoSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getCompetitionInfoFailure(message));
    }
}

export function* competitionInfoWatcher() {
    yield takeLatest(GET_COMPETITION_INFO_REQUEST, getCompetitionInfo)
}

export function* getMainCompetitionInfo() {
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/main-contest`);
        const data = response.data;
        yield put(getMainCompetitionInfoSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getMainCompetitionInfoFailure(message));
    }
}

export function* mainCompetitionInfoWatcher() {
    yield takeLatest(GET_MAIN_COMPETITION_INFO_REQUEST, getMainCompetitionInfo)
}

export function* getAllCompetitionsRequest({payload}) {
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/contest-types/${payload}/contests`);
        const data = response.data;
        yield put(getAllCompetitionsIdSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(getAllCompetitionsIdFailure(message));
    }
}

export function* allCompetitionsRequestWatcher() {
    yield takeLatest(GET_ALL_COMPETITIONS_ID_REQUEST, getAllCompetitionsRequest)
}
