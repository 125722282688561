import React, { Component } from "react";
import { connect } from "react-redux";
// import Swiper from "react-id-swiper";
// import "react-id-swiper/src/styles/scss/swiper.scss";
import HomeWinnersComponent from "./HomeWinnersComponent";
import { getWinnersForMainPageRequest } from '../../../actions/winnersActions';

class HomeWinnersContainer extends Component {

  state = {
    competitionId: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.competitionInfo && this.props.competitionInfo.id && this.props.competitionInfo.id !== this.state.competitionId) {
      this.setState({
        competitionId: this.props.competitionInfo.id
      })
      this.props.getWinnersForMainPageRequest(this.props.competitionInfo.id);
    }
  }

  render() {

    const { modal } = this.props;
    return (
      <HomeWinnersComponent openModalHandler={this.props.openModalHandler}
                            competitions={this.props.competitions}
                            modal={modal} data={this.props.winnersData}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalHandler: (item) => dispatch({ type: "OPEN_MODAL", payload: item }),
    getWinnersForMainPageRequest: (id) => dispatch(getWinnersForMainPageRequest(id))
  };
};

const mapStateToProps = (state) => ({
  modal: state.winners.modal,
  winnersData: state.winners.winnersData,
  competitions: state.competitions
  // id: state.winners.id
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeWinnersContainer);
