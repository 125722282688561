import {
    GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST,
    GET_MAIN_NEWS_BY_COMPETITION_ID_FAILURE,
    GET_MAIN_NEWS_BY_COMPETITION_ID_SUCCESS,
    GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST,
    GET_ALL_NEWS_BY_COMPETITION_ID_FAILURE,
    GET_ALL_NEWS_BY_COMPETITION_ID_SUCCESS
} from '../constants/newsTypes';

export const getMainNewsByCompetitionIdRequest = (id) => ({
    type: GET_MAIN_NEWS_BY_COMPETITION_ID_REQUEST,
    payload: id
})
export const getMainNewsByCompetitionIdFailure = (err) => ({
    type: GET_MAIN_NEWS_BY_COMPETITION_ID_FAILURE,
    payload: err
})
export const getMainNewsByCompetitionIdSuccess = (data) => ({
    type: GET_MAIN_NEWS_BY_COMPETITION_ID_SUCCESS,
    payload: data
})

export const getAllNewsByCompetitionIdRequest = (id, query) => ({
    type: GET_ALL_NEWS_BY_COMPETITION_ID_REQUEST,
    payload: {id, query}
})
export const getAllNewsByCompetitionIdFailure = (err) => ({
    type: GET_ALL_NEWS_BY_COMPETITION_ID_FAILURE,
    payload: err
})
export const getAllNewsByCompetitionIdSuccess = (data) => ({
    type: GET_ALL_NEWS_BY_COMPETITION_ID_SUCCESS,
    payload: data
})
