import React from 'react';
import styled from "styled-components";

const InputDefault = styled.input`
  width: ${props => (props.width )};
  height: ${props => (props.height )};
  border: ${props => (props.border )};
`;

const Input = (props) => {
    const {
        type,
        placeholder = '',
        handleChange,
        handleBlur,
        value,
        disabled,
        height = 'auto',
        width = '100%',
        border = 'auto',
        name = '',
        required = false
    } = props;

    if (type === 'tel') {
        return (
            <InputDefault
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                onBlur={() => handleBlur(type)}
                required={required}
                disabled={disabled}
                height={height}
                width={width}
                border={border}
                name={name}
                pattern='(\+?[7-8]{0,1}?[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{2})'
            />
        )
    } else {
        return (
            <InputDefault
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                onBlur={() => handleBlur(type)}
                required={required}
                disabled={disabled}
                height={height}
                width={width}
                border={border}
                name={name}
            />
        )
    }
}

export default Input;
