import {
    FETCH_GRADES_REQUEST,
    FETCH_GRADES_SUCCESS,
    FETCH_GRADES_ERROR,
    FETCH_STATUSES_REQUEST,
    FETCH_STATUSES_SUCCESS,
    FETCH_STATUSES_ERROR,
    FETCH_WINNERS_REQUEST,
    FETCH_WINNERS_SUCCESS,
    FETCH_WINNERS_ERROR
} from "../constants";

const initialState = {
    grades: [],
    statuses: [],
    winners: [],
    isGradesLoading: false,
    isStatusesLoading: false,
    isWinnersLoading: false
};

function winnerPageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GRADES_REQUEST:
            return {
                ...state,
                grades: [],
                isGradesLoading: true
            };

        case FETCH_GRADES_SUCCESS:
            return {
                ...state,
                grades: action.grades,
                isGradesLoading: false
            };

        case FETCH_GRADES_ERROR:
            return {
                ...state,
                error: action.message,
                isGradesLoading: false
            };

        case FETCH_STATUSES_REQUEST:
            return {
                ...state,
                statuses: [],
                isStatusesLoading: true
            };

        case FETCH_STATUSES_SUCCESS:
            return {
                ...state,
                statuses: action.statuses,
                isStatusesLoading: false
            };

        case FETCH_STATUSES_ERROR:
            return {
                ...state,
                error: action.message,
                isStatusesLoading: false
            };

        case FETCH_WINNERS_REQUEST:
            return {
                ...state,
                winners: [],
                isWinnersLoading: true
            };

        case FETCH_WINNERS_SUCCESS:
            return {
                ...state,
                winners: action.winners,
                isWinnersLoading: false
            };

        case FETCH_WINNERS_ERROR:
            return {
                ...state,
                error: action.message,
                isWinnersLoading: false
            };

        default:
            return state;
    }
}

export default winnerPageReducer;
