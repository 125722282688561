import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import moment from 'moment';
import 'moment/locale/ru';
import createMarkup from '../../helpers/dangerouslySetHTML';
import {Grid, Row, Col} from 'react-flexbox-grid';
import {PageContainer} from '../../../styles/containers';
import {getMonth} from '../../helpers/getMonth';
// import { H2, H3, Text } from "../../styles/fonts";

const FullNewsContainer = styled(PageContainer)`
  margin-top: 30px;
  margin-bottom: 56px;
`;

const StyledLink = styled(Link)`
  position: relative;
  color: #39404c;
  text-decoration: none;
  margin-right: 20px;
  ::after {
    content: '/';
    position: absolute;
    top: -1px;
    right: -10px;
   }
`;

const NewsTitle = styled.h1`
  margin-top: 43px;
  font-size: 36px;
  line-height: 40px;
  color: #10215e;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const NewsDate = styled.span`
  margin-left: ${props => (props.marginLeft ? `6px` : ``)};
  font-size: ${props => (props.smallerTitle ? `15px` : `24px`)};
  letter-spacing: 1px;
  line-height: 21px;
  color: #6873b5;
  font-family: "Roboto";
  font-weight: 700;
  text-transform: uppercase;
`;

// const NewsImage = styled.div`
//   width: 100%;
//   height: 490px;
//   background-image: url("https://picsum.photos/904/490");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   border: 1px solid red;
// `;

const NewsImage = styled.img`
  width: 100%;
`;

const NewsText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #4e515d;
  font-family: "Roboto";
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
`;

const Backbtn = styled(Link)`
  color: #7d86bf;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  font-family: "Roboto Condensed";
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
`;

const Span = styled.span`
    color: rgb(104, 115, 181);
`

const FullNewsComponent = props => {
    const {selectedNews, loading} = props;
    return (
        <>
            {loading === false && (
                <FullNewsContainer>
                    <Grid fluid>
                        <Row>
                            <Col lg={12}>
                                <Breadcrumbs
                                    separator={<span style={{color: '#39404c'}}> / </span>}
                                    finalItem={'b'}
                                    finalProps={{
                                        style: {color: '#6873b5', fontWeight: 'normal'}
                                    }}
                                />
                                <StyledLink to="/">
                                    Главная страница
                                </StyledLink>
                                <StyledLink to={`/news?competitionId=${props.competitionId}&page=1`}>Новости</StyledLink>
                                <Span>
                                    {selectedNews.title}
                                </Span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={1}/>
                            <Col lg={10}>
                                <NewsTitle>{selectedNews.title}</NewsTitle>
                                <NewsDate>
                                    {moment(selectedNews.published_at).format('D')}
                                    <NewsDate smallerTitle marginLeft>
                                        {getMonth(moment(selectedNews.published_at).format('MMMM'))}{' '}
                                    </NewsDate>
                                    <NewsDate marginLeft>
                                        {moment(selectedNews.published_at).format('YYYY')}{' '}
                                    </NewsDate>
                                    <NewsDate smallerTitle style={{marginLeft: '-5px'}}>
                                        г.
                                    </NewsDate>
                                    <NewsDate smallerTitle marginLeft>
                                        {moment(selectedNews.published_at).format('HH:mm')}
                                    </NewsDate>
                                </NewsDate>
                                <Row
                                    center="lg"
                                    style={{marginTop: '26px', marginBottom: '46px'}}
                                >
                                    <Col lg={10}>
                                        <NewsImage
                                            src={
                                                selectedNews &&
                                                selectedNews.thumb_urls &&
                                                selectedNews.thumb_urls.previewPicture
                                                    ? selectedNews.thumb_urls.previewPicture.c_news904x490
                                                    : ``
                                            }
                                            alt=""
                                        />
                                        {/* <picture>
                    <source media="(max-width: 799px)" srcset="https://picsum.photos/904/490" />
                    <source media="(min-width: 800px)" srcset="https://picsum.photos/904/490" />
                    <img srcset="https://picsum.photos/904/490" alt="" />
                    </picture> */}
                                    </Col>
                                </Row>
                                <NewsText>
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(selectedNews.text)}
                                    />
                                </NewsText>
                                {selectedNews.resource ?
                                    <NewsText>
                                        Ссылка на источник:
                                        <br/>
                                        <a href={selectedNews.resource} target="_blank">
                                            {selectedNews.resource}
                                        </a>
                                    </NewsText> : null
                                }
                                <Backbtn to="/news/?page=1">
                                    вернуться к списку новостей
                                </Backbtn>
                            </Col>
                            <Col lg={1}/>
                        </Row>
                    </Grid>
                </FullNewsContainer>
            )}
        </>
    );
};
export default FullNewsComponent;
