import React, { Component } from "react";
import {connect} from "react-redux";
import FooterComponent from "./FooterComponent";

class FooterContainer extends Component {
  render() {
    return (
      <FooterComponent competitions={this.props.competitions}/>
    )
  }
}

const mapStateToProps = (state) => ({
  competitions: state.competitions
});

export default connect(mapStateToProps)(FooterContainer);
