import styled from "styled-components";

export const PageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
 

  @media (min-width: 85em) {
    width: 85.625rem;
  }

  @media (max-width: 375px) {
    padding-left: 0;
    padding-right: 0;
  }
`;


export const Error = styled.div`
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4c579a;
    font-size: 24px;
    background: #eef5f7;
    `;
