import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './WorksSlider.scss';
import {getCompetitionId} from '../../helpers/getCompetitionId';

const WorksSlider = ({works, competitionTypeId, options}) => {
    const itemsOnPage = 5;
    const totalItems = works.length;

    const totalPages = Math.ceil(totalItems / itemsOnPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);

    const onNextSlide = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    const onPrevSlide = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    useEffect(() => {
        let current = works.slice((currentPage - 1) * itemsOnPage, currentPage * itemsOnPage);
        let emptyArray = [];
        if (current.length < 5) {
            emptyArray = new Array(5 - current.length).fill({empty: true})
        }
        setCurrentItems([...current, ...emptyArray])
    }, [works, currentPage])

    return (
        <div className="WorkBlock__Wrapper">
            {competitionTypeId.info && competitionTypeId.info.type && competitionTypeId.info.type.id && currentItems.length &&
            currentItems.map((work, i) => {
                if (work.name) {
                    return (
                        <Link
                            key={i}
                            to={`/winners?competitionId=${getCompetitionId(competitionTypeId.info.type.id, options)}&chosenGrade=${work.id}`}>
                            <div className='WorkBlock' key={i}>
                                <div
                                    className={`WorkTitle ${work.name.toLowerCase().replace(/класс|ы/g, '').length > 7 ? 'WorkTitle_Small' : ''}`}>
                                    {work.name.toLowerCase().replace(/класс|ы/g, '')}
                                </div>
                                {
                                    /класс|ы/.test(work.name) ?
                                        <div className="WorkSubtitle">
                                            класс
                                        </div> : null
                                }
                            </div>
                        </Link>
                    )
                } else {
                    return (
                        <div className='WorkBlock__Empty' key={i}>

                        </div>
                    )
                }
            })}
            <div className="ArrowBlock">
                <div className={`Arrow Arrow__Prev ${totalPages > 1 && currentPage !== 1 ? 'active' : ''}`}
                     onClick={onPrevSlide}>

                </div>
                <div className={`Arrow Arrow__Next ${currentPage < totalPages ? 'active' : ''}`} onClick={onNextSlide}>

                </div>
            </div>
        </div>
    )
}

WorksSlider.defaultProps = {
    works: []
}

export default WorksSlider;
