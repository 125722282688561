import React, { Component } from "react";
import axios from 'axios';
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Text } from "../../../styles/fonts";
import { Scrollbars } from "react-custom-scrollbars";
import corner from "../../../assets/img/ugolok.png";
import winner1 from "../../../assets/img/winners/2018АндрейВейдеКрасноярскийкрай.png";
import winner2 from "../../../assets/img/winners/2018БорисМожаевСвердловскаяобласть.png";
import winner3 from "../../../assets/img/winners/2018СофьяПшеницынаСвердловскаяобласть.png";
import winner4 from "../../../assets/img/winners/2018ВарвараРыковаСвердловскаяобласть.png";
import winner5 from "../../../assets/img/winners/2018ДаниилЧепурнойКурскаяобласть.png";

import winner6 from "../../../assets/img/winners/2017АнжелаМалошенкоКрасноярскийкрай.png";
import winner7 from "../../../assets/img/winners/2017ЕленаПрямиковаМурманская.png";
import winner8 from "../../../assets/img/winners/2017МарияКучуковаСамарскаяобласть.png";
import winner9 from "../../../assets/img/winners/2017НиколайТополовКостромская.png";
import winner10 from "../../../assets/img/winners/2017СофьяШарковаЛенинградскаяобласть.png";

import winner11 from "../../../assets/img/winners/2016АлександрОвчинниковКостромскаяобласть.png";
import winner12 from "../../../assets/img/winners/2016АнастасияТицкаяВологодскаяобласть.png";
import winner13 from "../../../assets/img/winners/2016ДарьяГунькинаВоронежская.png";
import winner14 from "../../../assets/img/winners/2016НикитаМорозовгСанкт-Петербург.png";
import winner15 from "../../../assets/img/winners/2016ПолинаКудрявцеваКурскаяобласть.png";

import winner16 from "../../../assets/img/winners/2015ВарвараСтрижакгСанкт-Петербург.png";
import winner17 from "../../../assets/img/winners/2015ЕвгенийСелянинАлтайскийкрай.png";
import winner18 from "../../../assets/img/winners/2015МатвейЖуковКировскаяобласть.png";
import winner19 from "../../../assets/img/winners/2015ЕлизаветаРыжковаВологодскаяобласть.png";
import winner20 from "../../../assets/img/winners/2015НикитаГоловкоКраснодарскийкрай.png";

import winner21 from "../../../assets/img/winners/2017РУБАннаПлотниковаРеспубликаХакасия.png";
import winner22 from "../../../assets/img/winners/2017РУБДарьяБуторинаРеспубликаКоми.png";
import winner23 from "../../../assets/img/winners/2017РУБДмитрийПавловРостовскаяобласть.png";
import winner24 from "../../../assets/img/winners/2017РУБОксанаСуховаИвановскаяобласть.png";
import winner25 from "../../../assets/img/winners/2017РУБОльгаЕвсееваБелгородскаяобласть.png";

//2018
import pdf_1 from "../../../assets/img/pdf/2018/Вейде Андрей Иванович_Год театра. Случайные размышления дилетанта_Красноярский край_page-0001.jpg";
import pdf_2 from "../../../assets/img/pdf/2018/Вейде Андрей Иванович_Год театра. Случайные размышления дилетанта_Красноярский край_page-0002.jpg";
import pdf_3 from "../../../assets/img/pdf/2018/Вейде Андрей Иванович_Год театра. Случайные размышления дилетанта_Красноярский край_page-0003.jpg";
import pdf_4 from "../../../assets/img/pdf/2018/Вейде Андрей Иванович_Год театра. Случайные размышления дилетанта_Красноярский край_page-0004.jpg";
import pdf_5 from "../../../assets/img/pdf/2018/Вейде Андрей Иванович_Год театра. Случайные размышления дилетанта_Красноярский край_page-0005.jpg";

import pdf_6 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-1.png";
import pdf_7 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-2.png";
import pdf_8 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-3.png";
import pdf_9 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-4.png";
import pdf_10 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-5.png";
import pdf_11 from "../../../assets/img/pdf/2018/Можаев Борис Андреевич _Как слово наше отзовется _Свердловская обл.-6.png";

import pdf_12 from "../../../assets/img/pdf/2018/Пшеницына Софья Дмитриевна_Марш Черномора_Свердловская обл.-1.png";
import pdf_13 from "../../../assets/img/pdf/2018/Пшеницына Софья Дмитриевна_Марш Черномора_Свердловская обл.-2.png";
import pdf_14 from "../../../assets/img/pdf/2018/Пшеницына Софья Дмитриевна_Марш Черномора_Свердловская обл.-3.png";
import pdf_15 from "../../../assets/img/pdf/2018/Пшеницына Софья Дмитриевна_Марш Черномора_Свердловская обл.-4.png";

import pdf_16 from "../../../assets/img/pdf/2018/Рыкова Варвара Владиславовна_Корабли солнечного круга_Свердловская обл.-1.png";
import pdf_17 from "../../../assets/img/pdf/2018/Рыкова Варвара Владиславовна_Корабли солнечного круга_Свердловская обл.-2.png";
import pdf_18 from "../../../assets/img/pdf/2018/Рыкова Варвара Владиславовна_Корабли солнечного круга_Свердловская обл.-3.png";
import pdf_19 from "../../../assets/img/pdf/2018/Рыкова Варвара Владиславовна_Корабли солнечного круга_Свердловская обл.-4.png";

import pdf_20 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-01.png";
import pdf_21 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-02.png";
import pdf_22 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-03.png";
import pdf_23 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-04.png";
import pdf_24 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-05.png";
import pdf_25 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-06.png";
import pdf_26 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-07.png";
import pdf_27 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-08.png";
import pdf_28 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-09.png";
import pdf_29 from "../../../assets/img/pdf/2018/Чепурной Даниил Рафаэлевич_Урок из прошлого_Курская обл.-10.png";

//2017
import pdf_30 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-1.png"
import pdf_31 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-2.png"
import pdf_32 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-3.png"
import pdf_33 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-4.png"
import pdf_34 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-5.png"
import pdf_35 from "../../../assets/img/pdf/2017/Малошенко Анжела Геннадьевна Кибитень. Звезда полей Красноярский край-6.png"

import pdf_36 from "../../../assets/img/pdf/2017/Прямикова Елена Леонидовна Каждый должен начать с себя. Мурманская обл.-1.png"
import pdf_37 from "../../../assets/img/pdf/2017/Прямикова Елена Леонидовна Каждый должен начать с себя. Мурманская обл.-2.png"
import pdf_38 from "../../../assets/img/pdf/2017/Прямикова Елена Леонидовна Каждый должен начать с себя. Мурманская обл.-3.png"
import pdf_39 from "../../../assets/img/pdf/2017/Прямикова Елена Леонидовна Каждый должен начать с себя. Мурманская обл.-4.png"

import pdf_40 from "../../../assets/img/pdf/2017/Кучукова Мария Денисовна Пересматривая читательский блог... (к написанию эссе на экологическую тему) Самарская обл.-1.png"
import pdf_41 from "../../../assets/img/pdf/2017/Кучукова Мария Денисовна Пересматривая читательский блог... (к написанию эссе на экологическую тему) Самарская обл.-2.png"
import pdf_42 from "../../../assets/img/pdf/2017/Кучукова Мария Денисовна Пересматривая читательский блог... (к написанию эссе на экологическую тему) Самарская обл.-3.png"
import pdf_43 from "../../../assets/img/pdf/2017/Кучукова Мария Денисовна Пересматривая читательский блог... (к написанию эссе на экологическую тему) Самарская обл.-4.png"

// import pdf_44 from "../../../assets/img/pdf/2017/"
// import pdf_45 from "../../../assets/img/pdf/2017"
// import pdf_46 from "../../../assets/img/pdf/2017"
// import pdf_47 from "../../../assets/img/pdf/2017"

import pdf_48 from "../../../assets/img/pdf/2017/Шаркова Софья Михайловна Когда говорят музы, замолкают пушки Ленинградская область-1.png"
import pdf_49 from "../../../assets/img/pdf/2017/Шаркова Софья Михайловна Когда говорят музы, замолкают пушки Ленинградская область-2.png"
import pdf_50 from "../../../assets/img/pdf/2017/Шаркова Софья Михайловна Когда говорят музы, замолкают пушки Ленинградская область-3.png"
import pdf_51 from "../../../assets/img/pdf/2017/Шаркова Софья Михайловна Когда говорят музы, замолкают пушки Ленинградская область-4.png"

//2016
import pdf_52 from "../../../assets/img/pdf/2016/Овчинников Александр Валерьевич Стоп, снято «Жестокий романс» о бесприданнице Костромская обл.-1.png"
import pdf_53 from "../../../assets/img/pdf/2016/Овчинников Александр Валерьевич Стоп, снято «Жестокий романс» о бесприданнице Костромская обл.-2.png"
import pdf_54 from "../../../assets/img/pdf/2016/Овчинников Александр Валерьевич Стоп, снято «Жестокий романс» о бесприданнице Костромская обл.-3.png"
import pdf_55 from "../../../assets/img/pdf/2016/Овчинников Александр Валерьевич Стоп, снято «Жестокий романс» о бесприданнице Костромская обл.-4.png"

import pdf_56 from "../../../assets/img/pdf/2016/Тицкая Анастасия Витальевна Сквозь века с датским принцем в сердце Вологодская обл.-1.png"
import pdf_57 from "../../../assets/img/pdf/2016/Тицкая Анастасия Витальевна Сквозь века с датским принцем в сердце Вологодская обл.-2.png"
import pdf_58 from "../../../assets/img/pdf/2016/Тицкая Анастасия Витальевна Сквозь века с датским принцем в сердце Вологодская обл.-3.png"

import pdf_59 from "../../../assets/img/pdf/2016/Гунькина Дарья Андреевна Волшебная книга. Воронежская обл.-1.png"
import pdf_60 from "../../../assets/img/pdf/2016/Гунькина Дарья Андреевна Волшебная книга. Воронежская обл.-2.png"

import pdf_61 from "../../../assets/img/pdf/2016/Морозов Никита Денисович Через тернии - к звездам г. Санкт-Петербург-1.png"
import pdf_62 from "../../../assets/img/pdf/2016/Морозов Никита Денисович Через тернии - к звездам г. Санкт-Петербург-2.png"
import pdf_63 from "../../../assets/img/pdf/2016/Морозов Никита Денисович Через тернии - к звездам г. Санкт-Петербург-3.png"
import pdf_64 from "../../../assets/img/pdf/2016/Морозов Никита Денисович Через тернии - к звездам г. Санкт-Петербург-4.png"

import pdf_65 from "../../../assets/img/pdf/2016/Кудрявцева Полина Евгеньевна Разгромная критика и... всенародная любовь Курская обл.-1.png"
import pdf_66 from "../../../assets/img/pdf/2016/Кудрявцева Полина Евгеньевна Разгромная критика и... всенародная любовь Курская обл.-2.png"
import pdf_67 from "../../../assets/img/pdf/2016/Кудрявцева Полина Евгеньевна Разгромная критика и... всенародная любовь Курская обл.-3.png"
import pdf_68 from "../../../assets/img/pdf/2016/Кудрявцева Полина Евгеньевна Разгромная критика и... всенародная любовь Курская обл.-4.png"

//2015
import pdf_69 from "../../../assets/img/pdf/2015/Стрижак Варвара Александровна Вишневый сад. Элегия о времени г. Санкт-Петербург-1.png"
import pdf_70 from "../../../assets/img/pdf/2015/Стрижак Варвара Александровна Вишневый сад. Элегия о времени г. Санкт-Петербург-2.png"
import pdf_71 from "../../../assets/img/pdf/2015/Стрижак Варвара Александровна Вишневый сад. Элегия о времени г. Санкт-Петербург-3.png"
import pdf_72 from "../../../assets/img/pdf/2015/Стрижак Варвара Александровна Вишневый сад. Элегия о времени г. Санкт-Петербург-4.png"

import pdf_73 from "../../../assets/img/pdf/2015/Селянин Евгений Романович Как купец Сухов барина благотворительности научил Алтайский край-1.png"
import pdf_74 from "../../../assets/img/pdf/2015/Селянин Евгений Романович Как купец Сухов барина благотворительности научил Алтайский край-2.png"

import pdf_75 from "../../../assets/img/pdf/2015/Жуков Матвей Владиславович Лучшее, что есть у человека, - это собака Кировская обл-1.png"
import pdf_76 from "../../../assets/img/pdf/2015/Жуков Матвей Владиславович Лучшее, что есть у человека, - это собака Кировская обл-2.png"
import pdf_77 from "../../../assets/img/pdf/2015/Жуков Матвей Владиславович Лучшее, что есть у человека, - это собака Кировская обл-3.png"
import pdf_78 from "../../../assets/img/pdf/2015/Жуков Матвей Владиславович Лучшее, что есть у человека, - это собака Кировская обл-4.png"

import pdf_79 from "../../../assets/img/pdf/2015/Рыжкова Елизавета Ивановна Увидеть небо... Вологодская обл.-1.png"
import pdf_80 from "../../../assets/img/pdf/2015/Рыжкова Елизавета Ивановна Увидеть небо... Вологодская обл.-2.png"
import pdf_81 from "../../../assets/img/pdf/2015/Рыжкова Елизавета Ивановна Увидеть небо... Вологодская обл.-3.png"

import pdf_82 from "../../../assets/img/pdf/2015/Головко Никита Николаевич Памятник воину - мечтателю Краснодарский край-1.png"
import pdf_83 from "../../../assets/img/pdf/2015/Головко Никита Николаевич Памятник воину - мечтателю Краснодарский край-2.png"
import pdf_84 from "../../../assets/img/pdf/2015/Головко Никита Николаевич Памятник воину - мечтателю Краснодарский край-3.png"

//РУБ
import pdf_85 from "../../../assets/img/pdf/РУБ/Плотникова Анна РУБ 2017 Что я сделаю для людей-1.png"
import pdf_86 from "../../../assets/img/pdf/РУБ/Плотникова Анна РУБ 2017 Что я сделаю для людей-2.png"
import pdf_87 from "../../../assets/img/pdf/РУБ/Плотникова Анна РУБ 2017 Что я сделаю для людей-3.png"
import pdf_88 from "../../../assets/img/pdf/РУБ/Плотникова Анна РУБ 2017 Что я сделаю для людей-4.png"

import pdf_89 from "../../../assets/img/pdf/РУБ/Буторина Дарья РУБ 2017 Время разумных-1.png"
import pdf_90 from "../../../assets/img/pdf/РУБ/Буторина Дарья РУБ 2017 Время разумных-2.png"
import pdf_91 from "../../../assets/img/pdf/РУБ/Буторина Дарья РУБ 2017 Время разумных-3.png"

import pdf_92 from "../../../assets/img/pdf/РУБ/Павлов Дмитрий РУБ2017 Человек обязательно должен быть на своей земле хозяином-1.png"
import pdf_93 from "../../../assets/img/pdf/РУБ/Павлов Дмитрий РУБ2017 Человек обязательно должен быть на своей земле хозяином-2.png"
import pdf_94 from "../../../assets/img/pdf/РУБ/Павлов Дмитрий РУБ2017 Человек обязательно должен быть на своей земле хозяином-3.png"
import pdf_95 from "../../../assets/img/pdf/РУБ/Павлов Дмитрий РУБ2017 Человек обязательно должен быть на своей земле хозяином-4.png"
import pdf_96 from "../../../assets/img/pdf/РУБ/Павлов Дмитрий РУБ2017 Человек обязательно должен быть на своей земле хозяином-5.png"

import pdf_97 from "../../../assets/img/pdf/РУБ/Сухова Оксана РУБ 2017 Девичьи грезы-1.png"
import pdf_98 from "../../../assets/img/pdf/РУБ/Сухова Оксана РУБ 2017 Девичьи грезы-2.png"
import pdf_99 from "../../../assets/img/pdf/РУБ/Сухова Оксана РУБ 2017 Девичьи грезы-3.png"

import pdf_100 from "../../../assets/img/pdf/РУБ/Ольга Евсеева РУБ 2017 Высоко-высоко-1.png"
import pdf_101 from "../../../assets/img/pdf/РУБ/Ольга Евсеева РУБ 2017 Высоко-высоко-2.png"
import pdf_102 from "../../../assets/img/pdf/РУБ/Ольга Евсеева РУБ 2017 Высоко-высоко-3.png"
import pdf_103 from "../../../assets/img/pdf/РУБ/Ольга Евсеева РУБ 2017 Высоко-высоко-4.png"
import pdf_104 from "../../../assets/img/pdf/РУБ/Ольга Евсеева РУБ 2017 Высоко-высоко-5.png"


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 84, 149, 0.7);
  overflow: auto;
  z-index: 999;
`;

const ModalContainer = styled.div`
  position: relative;
  width: 80%;
  height: 80vh;
  border: 20px solid #4a5495;
  box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.75);
  /* max-height: calc(80vh); */
  /* overflow: scroll;
  overflow-x: hidden; */
  /* overflow-y: scroll; */
  /* body:not(&) { overflow: hidden; } */
`;

const CloseBtn = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  right: -20px;
  top: -85px;
  background-color: #47518f;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 32px;
    height: 3px;
    background: #fff;
    border-radius: 10px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalGrid = styled(Grid)`
  height: 100%;
  padding-right: 8px !important;
  padding-left: 8px !important;
`;

const ModalRow = styled(Row)`
  height: 100%;
`;

const LeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 100%;
  /* height: 76vh; */
  padding: 100px 80px !important;
  /* padding-left: 80px;
  padding-right: 80px; */
  background: linear-gradient(
    0deg,
    rgba(245, 242, 235, 1) 50%,
    rgba(198, 194, 196, 1) 100%
  );
  @media (max-width: 1280px) {
    padding: 100px 25px !important;
  }
  @media (max-width: 990px) {
    display: none;
  }
`;

const RightCol = styled(Col)`
  position: relative;
  background-color: #fbfcfd;
  height: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  &:before {
    content: "";
    position: absolute;
    z-index: 100;
    height: 150px;
    left: 0;
    right: 10px;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 100;
    height: 150px;
    left: 0;
    right: 0px;
    bottom: 0;
    background: url(${corner}) no-repeat right;
  }
`;

const RightContainer = styled.div`
  position: relative;
  height: 100%;
  /* overflow-y: scroll; */
  /* ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #00bcd6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #00bcd6;
  } */
`;

const PdfImage = styled.img`
  width: 100%;
  margin-bottom: -10px;
  /* height: 100%; */
`;

const Quote = styled.span`
  font-size: 26px;
  letter-spacing: 1px;
  line-height: 34px;
  color: #0a1c59;
  font-family: "Roboto Condensed";
  font-weight: 400;
  text-align: center;
  @media (max-width: 1280px) {
   font-size: 18px;
  }
`;

const ActionContainer = styled.div`
  /* text-align: ${props => props.textAlign && `right`}; */
  /* text-align: right; */
  font-size: 16px;
  line-height: 17px;
  color: #6873b5;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
`;

const SlideContainer = styled.div`
  position: relative;
  width: 176px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #10215e;
  font-family: "Roboto Condensed";
`;

const Circle = styled.div`
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background-color: #00bcd6;
  text-align: center;
`;

const ModalName = styled(Text)`
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 33px;
  color: #10215e;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const ModalCity = styled(Text)`
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 24px;
  color: #232c5f;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

class ModalComponent extends Component {
  state = {
    oneWinnerData: [],
    loading: true
  }

  // componentDidMount() {
  //   this.setState({
  //     loading: true
  //   });
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/api/v1/winner-for-title/${this.props.id}`)
  //     .then(response => {
  //       // console.log(response.data, this.state.year, this.state.winnerData);
  //       // let winnerData = Object.assign({}, this.state.winnerData);
  //       // let newData = response.data;
  //       // winnerData[this.state.year] = newData;
  //       // console.log("response one winner", response)
  //       this.setState({
  //         oneWinnerData: response.data,
  //         loading: false
  //       });
  //     });
  // }

  render() {
    return (
      <>
        {/* {console.log("this.state.oneWinnerData", this.state.oneWinnerData.essay_pictures)} */}
        {this.props.modal && (
          <ModalOverlay>
            <ModalContainer>
              <CloseBtn onClick={this.props.closeModalHandler} />
              <ModalContent>
                <ModalGrid fluid>
                  <ModalRow>
                    <LeftCol lg={4}>
                      <Quote>"{this.props.item && this.props.item.title}"</Quote>
                      <SlideContainer>
                        <Circle
                          style={{
                            background: `url(${this.props.item && this.props.item.avatar && this.props.item.avatar.url})`
                          }}
                        />
                        <ModalName>
                          {this.props.item && this.props.item.name} {this.props.item && this.props.item.surname}
                        </ModalName>
                        <ModalCity>{this.props.item && this.props.item.region && this.props.item.region.name}</ModalCity>
                      </SlideContainer>
                    </LeftCol>
                    <RightCol lg={8}>
                      <RightContainer>
                        <Scrollbars
                          autoHide
                          autoHeightMax="100%"
                          renderThumbVertical={({ style, ...props }) => (
                            <div
                              {...props}
                              style={{
                                ...style,
                                backgroundColor: "#4a5495",
                                borderRadius: "inherit",
                                width: "100%",
                                borderTop: "1px solid #fff",
                                borderBottom: "1px solid #fff"
                              }}
                            />
                          )}
                          renderTrackVertical={({ style, ...props }) => (
                            <div
                              {...props}
                              style={{
                                ...style,
                                backgroundColor: "#acacac",
                                position: "absolute",
                                width: "6px",
                                right: "2px",
                                bottom: "2px",
                                top: "2px",
                                borderRadius: "3px"
                              }}
                            />
                          )}
                        >
                          {this.props.item && this.props.item.essay_pics &&
                          this.props.item.essay_pics.map((item, id) => {
                            return (
                                <PdfImage src={item.file ? item.file.url : ''}  alt="" />
                            )
                          }
                          )}
                        </Scrollbars>
                      </RightContainer>
                    </RightCol>
                  </ModalRow>
                </ModalGrid>
              </ModalContent>
            </ModalContainer>
          </ModalOverlay>
        )}
      </>
    );
  }
};

export default ModalComponent;
