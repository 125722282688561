import React, {Component} from 'react';
import styled from "styled-components";
import Input from './Input';

const PopupWrapper = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(65, 72, 118, .89);
    z-index: 3000;
    
    //transition: all .3s;
    backdrop-filter: blur(10px);
    overflow-y: auto;
`;

const PopupBody = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2000;
  //transform: scale(.25);
  //transition: all .5s;
`;

const CloseBtn = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  right: 18%;
  top: 1%;
  cursor: pointer;
  
  @media screen and (max-width: 1280px) {
    right: 1%;
  }
  
  @media screen and (max-width: 850px) {
    top: 0;
  }
  
  @media screen and (max-width: 460px) {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 32px;
    height: 3px;
    background: #fff;
    border-radius: 10px;
    
    @media screen and (max-width: 780px){
      width: 25px;
     }
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @media (orientation: landscape) and (max-width: 780px){
  right: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  /* max-width: 1400px; */
  position: absolute;
  top: 0;
`;

class Popup extends Component {
    handleClose = () => {
        this.props.handleClose({keyCode: 27});
    };

    render() {
        return (
            <PopupWrapper>
                <PopupBody>
                    <Container>
                    <CloseBtn onClick={this.handleClose}/>
                    {this.props.children}
                    </Container>
                </PopupBody>
            </PopupWrapper>
        );
    }
}

export default Popup;