import React from "react";
import Select from "react-select";
import styled from "styled-components";

import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../../styles/containers";
import { H2 } from "../../../styles/fonts";

import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  SelectWrapper,
  InputWrapper,
  SearchBtn,
} from "../../WinnersPage/WinnersPageContainer";
import { renameObjKey } from "../../helpers/renameObjKey";

const SchedulePageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 100px;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

const ResultWrapper = styled.div`
  /* max-width: 1000px;
  margin: 5px auto 0; */
`;

const StyledRow = styled(Row)`
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    justify-content: flex-start;
  }
`;

const RegionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 88px;
`;

const RegionFlag = styled.img`
  width: 110px;
  height: 130px;
  margin-right: 22px;
  border: 1px solid #000;
`;

const Title = styled.span`
  font-size: 31px;
  letter-spacing: 1px;
  line-height: 17px;
  color: #39404c;
  font-family: "Roboto Condensed";
  font-weight: 400;
`;

const ScheduleProgressBar = styled.div`
  height: 30px;
  background-color: #c5c6ca;
`;

const StagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 85px;
`;

const Stage = styled.div`
  /* &:nth-child(1) {
    &:before {
        content: "";
        position: absolute;
        background-color: #0296e3;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
    }
} */
`;

const StageUpper = styled.div`
  position: relative;
  margin-bottom: 7px;
  font-size: 24px;
  line-height: 26px;
  color: #39404c;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-decoration: uppercase;
  &:nth-child(1) {
    &:before {
      content: "";
      position: absolute;
      background-color: #0296e3;
      width: 20px;
      height: 20px;
      left: -37px;
      top: 3px;
    }
  }
`;

const StageLower = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #39404c;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: uppercase;
`;

class SchedulePageComponent extends React.Component {
  state = {
    subjects: [],
    selectedOption: { value: 0 },
    currentSubject: undefined,
    options: []
  };

  componentDidMount() {
    //   console.log(prevProps)
    // if (this.props.regions.length !== prevProps.regions.length) {
    let options = [...this.props.regions];
    options.forEach(option => {
      renameObjKey(option, "id", "value");
      renameObjKey(option, "name", "label");
    });
    this.setState({ options });
    // }

    // if (this.props.scheduleData.length !== prevProps.scheduleData.length) {
    // console.log("hi")
    this.setState({
      subjects: this.props.scheduleData
      // currentOperators: this.props.operators
    });
    // }
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption }, () => {
      let currentSubject = this.getCurrentRegion(
        this.state.subjects,
        selectedOption.value
      );
      this.setState({ currentSubject });
    });
  };

  getCurrentRegion(subjects, selectedOption) {
    let currentSubject;

    if (selectedOption === 0) {
      return subjects;
    } else {
      currentSubject = subjects.filter(item => item.id === selectedOption);
    }
    return currentSubject;
  }

  render() {
    const { subjects, selectedOption, options, currentSubject } = this.state;

    return (
      <SchedulePageContainer>
        {/* {console.log(this.props)}
        {console.log(this.state)} */}
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs
                separator={<span style={{ color: "#39404c" }}> / </span>}
                finalItem={"b"}
                finalProps={{
                  style: { color: "#6873b5", fontWeight: "normal" }
                }}
              />
              <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
              <BreadcrumbsItem to={`/news/`}>
                График проведения региональных этапов
              </BreadcrumbsItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <H2>График проведения региональных этапов</H2>
            </Col>
          </Row>
          <Row style={{ marginLeft: "0" }}>
            <Col lg={5} style={{ padding: "0" }}>
              <SelectWrapper>
                <Select
                  options={options}
                  onChange={this.handleSelectChange}
                  value={selectedOption}
                  placeholder="Субъект Российской Федерации"
                />
              </SelectWrapper>
            </Col>
          </Row>
          <ResultWrapper>
            {currentSubject &&
              currentSubject.map((item, id) => (
                <div key={id}>
                  <RegionTitle>
                    <RegionFlag />
                    <Title>{item.subject}</Title>
                  </RegionTitle>
                  <ScheduleProgressBar />
                  <StagesContainer>
                    {item.stages.map(stage => (
                      <Stage>
                        <StageUpper>
                          {stage.start_date} - {stage.finish_date}
                        </StageUpper>
                        <StageLower>{stage.stage}</StageLower>
                      </Stage>
                    ))}
                  </StagesContainer>
                </div>
              ))}
          </ResultWrapper>
        </Grid>
      </SchedulePageContainer>
    );
  }
}

export default SchedulePageComponent;
