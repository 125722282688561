import {
    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_ERROR
} from "../constants";

const initialState = {
    regions: [],
    isRegsLoading: false
};

function regionsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_REGIONS_REQUEST:
            return {
                ...state,
                regions: [],
                isRegsLoading: true
            };

        case FETCH_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.regions,
                isRegsLoading: false
            };

        case FETCH_REGIONS_ERROR:
            return {
                ...state,
                regions: action.message,
                isRegsLoading: false
            };

        default:
            return state;
    }
}

export default regionsReducer;
