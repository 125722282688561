import {
    GET_COMPETITION_OPTIONS_REQUEST,
    GET_COMPETITION_OPTIONS_SUCCESS,
    GET_COMPETITION_OPTIONS_FAILURE,
    GET_COMPETITION_INFO_REQUEST,
    GET_COMPETITION_INFO_SUCCESS,
    GET_COMPETITION_INFO_FAILURE,
    GET_MAIN_COMPETITION_INFO_FAILURE,
    GET_MAIN_COMPETITION_INFO_REQUEST,
    GET_MAIN_COMPETITION_INFO_SUCCESS,
    GET_ALL_COMPETITIONS_ID_FAILURE,
    GET_ALL_COMPETITIONS_ID_REQUEST,
    GET_ALL_COMPETITIONS_ID_SUCCESS
} from "../constants/competitionsTypes";

export const getCompetitionOptionsRequest = () => ({
    type: GET_COMPETITION_OPTIONS_REQUEST
});

export const getCompetitionOptionsSuccess = (options) => ({
    type: GET_COMPETITION_OPTIONS_SUCCESS,
    payload: options,
});

export const getCompetitionOptionsFailure = (message) => ({
    type: GET_COMPETITION_OPTIONS_FAILURE,
    payload: message,
});

export const getCompetitionInfoRequest = (id) => ({
    type: GET_COMPETITION_INFO_REQUEST,
    payload: id
})

export const getCompetitionInfoSuccess = (info) => ({
    type: GET_COMPETITION_INFO_SUCCESS,
    payload: info,
});

export const getCompetitionInfoFailure = (message) => ({
    type: GET_COMPETITION_INFO_FAILURE,
    payload: message,
});

export const getMainCompetitionInfoRequest = () => ({
    type: GET_MAIN_COMPETITION_INFO_REQUEST
})

export const getMainCompetitionInfoSuccess = (info) => ({
    type: GET_MAIN_COMPETITION_INFO_SUCCESS,
    payload: info,
});

export const getMainCompetitionInfoFailure = (message) => ({
    type: GET_MAIN_COMPETITION_INFO_FAILURE,
    payload: message,
});

export const getAllCompetitionsIdRequest = (id) => ({
    type: GET_ALL_COMPETITIONS_ID_REQUEST,
    payload: id
})

export const getAllCompetitionsIdSuccess = (info) => ({
    type: GET_ALL_COMPETITIONS_ID_SUCCESS,
    payload: info,
});

export const getAllCompetitionsIdFailure = (message) => ({
    type: GET_ALL_COMPETITIONS_ID_FAILURE,
    payload: message,
});

