import React, { Component } from "react";
import { Normalize } from "styled-normalize";
import { Router, Route, Switch } from "react-router-dom";
import { ThroughProvider } from "react-through";
import createBrowserHistory from "history/createBrowserHistory";
import GlobalStyle from "../styles/globalstyles";
import Layout from "../components/helpers/Layout";
import ScrollToTop from "../helpers/ScrollToTop";

import HomeContainer from "../components/Home/HomeContainer";
import NewsPage from "../components/NewsPage/NewsPageContainer";
import DocumentsPage from "../components/DocumentsPage/DocumentsPageContainer";
import ContestInFacesPage from "../components/ContestInFacesPage/ContestInFacesPageContainer";
import UnitPage from "./UnitPage/UnitPageContainer";
import WinnersPage from "../components/WinnersPage/WinnersPageWrapper";
import QAPage from "../components/QAPage/QAPageContainer";
import TopicsPage from '../components/TopicsPage/TopicsPageContainer';
import MediaPage from '../components/MediaPage/MediaPageContainer';
import OperatorPage from "../components/OperatorPage/OperatorPageContainer";
import CalendarPage from "../components/CalendarPage/CalendarPageContainer";
import AboutPage from "../components/AboutPage/AboutPageContainer";
import SchedulePage from "../components/CalendarPage/SchedulePage/SchedulePageContainer";
import NotFound from './NotFound/NotFound';

const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <>
        <Normalize/>
        <GlobalStyle />
        <Router history={history}>
          <ThroughProvider>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" render={(props) => <HomeContainer {...props}/>}/>
                <Route exact path="/konkursi/:competitionId" render={(props) => <HomeContainer {...props}/>}/>
                <Layout {...this.props}>
                  <Switch>
                    <Route path="/news" component={props => <NewsPage {...props} />} />
                    <Route path="/docs/:competitionId" render={props => <DocumentsPage {...props} /> }/>
                    <Route path="/teacher/:sectionId/:competitionId" render={(props) => <UnitPage {...props} />} />
                    <Route path="/student/:sectionId/:competitionId" render={(props) => <UnitPage {...props}/>} />
                    <Route path="/organizer/:sectionId/:competitionId" render={(props) => <UnitPage {...props}/>} />
                    <Route path="/operator" component={OperatorPage} />
                    <Route path="/winners" render={(props) => <WinnersPage {...props}/> } />
                    <Route path="/qa" component={QAPage} />
                    <Route path="/topics/:competitionId" component={TopicsPage} />
                    <Route path="/mediateka" component={MediaPage} />
                    <Route path="/calendar/:competitionId" render={(props) => <CalendarPage {...props} />} />
                    <Route path="/schedule" component={SchedulePage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contest_in_faces" render={props => <ContestInFacesPage {...props} /> }/>
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Layout>
              </Switch>
            </ScrollToTop>
          </ThroughProvider>
        </Router>
      </>
    );
  }
}

export default App;
