import { all } from "redux-saga/effects";
import { winnerGradesActionWatcher, winnerStatusesActionWatcher, winnersActionWatcher} from "./winnersSaga";
import { documentsActionWatcher } from './documentsSagas';
import { operatorsActionWatcher } from "./operatorsSaga";
import { regionsActionWatcher } from "./regionsSaga";
import { competitionActionWatcher,
    competitionInfoWatcher,
    mainCompetitionInfoWatcher,
    allCompetitionsRequestWatcher } from './competitionsSagas';
import { calendarActionWatcher } from './calendarSagas';
import { newsActionWatcher } from './newsSagas';

export default function* rootSaga() {
  yield all(
    [
        winnerGradesActionWatcher(),
        winnerStatusesActionWatcher(),
        winnersActionWatcher(),
        documentsActionWatcher(),
        operatorsActionWatcher(),
        regionsActionWatcher(),
        competitionActionWatcher(),
        competitionInfoWatcher(),
        mainCompetitionInfoWatcher(),
        allCompetitionsRequestWatcher(),
        calendarActionWatcher(),
        newsActionWatcher()
      ]
  );
}
