import React from "react";
import Select from "react-select";
import styled from "styled-components";

import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../styles/containers";
import { H2 } from "../../styles/fonts";

import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { SelectWrapper, InputWrapper, SearchBtn } from '../WinnersPage/WinnersPageContainer';
import Input from "../UI/Input";
import SearchSvg from "../UI/Icons/Search";
import OperatorCard from './OperatorCard';
import PaginationAdvanced from "../UI/PaginationAdvanced";
import { renameObjKey } from '../helpers/renameObjKey';

const OperatorPageContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-bottom: 100px;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

const ResultWrapper = styled.div`
  max-width: 1000px;
  margin: 5px auto 0;
`;

const StyledRow = styled(Row)`
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    justify-content: flex-start;
  }
`;

class OperatorPageComponent extends React.Component {
  state = {
    operators: [],
    search: "",
    currentPage: 1,
    selectedOption: { value: 0 },
    currentOperators: [],
    currentCards: [],
    options: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.regions.length !== prevProps.regions.length) {
      let options = [...this.props.regions];
      options.forEach(option => {
        renameObjKey(option, "id", "value");
        renameObjKey(option, "name", "label");
      });
      this.setState({ options });
    }

    if (this.props.operators.length !== prevProps.operators.length) {
      this.setState({
        operators: this.props.operators,
        currentOperators: this.props.operators
      });
    }
  }

  handleChange = e => {
    const value = e.target.value;
    this.setState({ [e.target.name]: value }, () => {
      let currentOperators = this.getCurrentOprerators(
        this.state.operators,
        this.state.selectedOption.value
      ).filter(this.searchFilter);

      this.setState({ currentOperators }, () => this.setCurrentCards(1));
    });
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption }, () => {
      let currentOperators = this.getCurrentOprerators(
        this.state.operators,
        selectedOption.value
      ).filter(this.searchFilter);
      this.setState({ currentOperators }, () => this.setCurrentCards(1));
    });
  };

  getCurrentOprerators(operators, selectedOption) {
    let currentOperators;

    if (selectedOption === 0) {
      return operators;
    } else {
      currentOperators = operators.filter(
        operator => operator.region && operator.region.id === selectedOption
      );
    }

    return currentOperators;
  }

  searchFilter = operator => (
      operator.surname.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
      -1 ||
      (operator.region && operator.region.name
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1)
  );

  setCurrentCards = currentPage => {
    const { currentOperators } = this.state;
    const pageLimit = 5;
    const offset = (currentPage - 1) * pageLimit;
    const currentCards = currentOperators.slice(offset, offset + pageLimit);
    const totalPages = Math.ceil(currentOperators.length / pageLimit);
    this.setState({ currentPage, currentCards, totalPages });
  };

  onPageChanged = currentPage => {
    this.setCurrentCards(currentPage);
  };

  render() {
    const {
      currentOperators,
      search,
      currentCards,
      selectedOption,
      options
    } = this.state;

    return (
      <OperatorPageContainer>
        {console.log(this.props)}
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs
                separator={<span style={{ color: "#39404c" }}> / </span>}
                finalItem={"b"}
                finalProps={{
                  style: { color: "#6873b5", fontWeight: "normal" }
                }}
              />
              <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
              <BreadcrumbsItem to={`/news/`}>
                Ответственные операторы Всероссийского конкурса сочинений в
                субъектах Российской Федерации
              </BreadcrumbsItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <H2>
                Ответственные операторы Всероссийского конкурса сочинений в
                субъектах Российской Федерации (региональные координаторы)
              </H2>
            </Col>
          </Row>
          <Row style={{ marginLeft: "0" }}>
            <Col lg={5} style={{ padding: "0" }}>
              <SelectWrapper>
                <Select
                  options={options}
                  onChange={this.handleSelectChange}
                  value={selectedOption}
                  placeholder="Субъект Российской Федерации"
                />
              </SelectWrapper>
            </Col>
            <Col lg={7}>
              <StyledRow>
                <InputWrapper>
                  <Input
                    type="search"
                    name="search"
                    value={search}
                    height="44px"
                    handleChange={this.handleChange}
                    border="1px solid #b7c5d8;"
                    placeholder="Найти"
                  />
                </InputWrapper>
                <SearchBtn>
                  <SearchSvg color="#4e515d" />
                </SearchBtn>
              </StyledRow>
            </Col>
          </Row>
          <ResultWrapper>
              {currentCards &&
              currentCards.map((item, id) => (
                  <OperatorCard
                    key={id}
                    name={item.name || ''}
                    surname={item.surname || ''}
                    patronymic={item.patronymic || ''}
                    region={item.region ? item.region.name : ''}
                    regional_site={item.regional_site}
                    email={item.email}
                    work={item.workplace}
                  />
                ))}
          </ResultWrapper>
          <PaginationAdvanced
            totalRecords={currentOperators.length}
            pageLimit={5}
            pageNeighbors={1}
            onPageChanged={this.onPageChanged}
          />
        </Grid>
      </OperatorPageContainer>
    );
  }
}

export default OperatorPageComponent;

