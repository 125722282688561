import React from "react";
import {Link} from "react-router-dom";
import {slide as Menu} from "react-burger-menu";
import icon_login from "../../assets/svg/ico-login.svg";
import {Row} from "react-flexbox-grid";
import { createPath } from '../../helpers/createPath';
import icon_reg from "../../assets/svg/k4.svg";
import styled from 'styled-components';
import {getCompetitionId} from '../../helpers/getCompetitionId';
import {items} from '../../helpers/logosLinks';
import ReactHtmlParser from 'react-html-parser'; //ico-reg.svg

var styles = {
    bmBurgerButton: {
        position: "absolute",
        width: "36px",
        height: "30px",
        right: "16px",
        top: "47px",
        // display: 'none',
    },
    bmBurgerBars: {
        background: "#fff",
        height: "10%"
    },
    bmBurgerBarsHover: {
        background: "#a90000"
    },
    bmCrossButton: {
        height: "37px",
        width: "27px",
        top: "31px",
        right: "16px"
    },
    bmCross: {
        height: "30px",
        background: "#bdc3c7"
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: 0
    },
    bmMenu: {
        background: "#47518f",
        // padding: "2.5em 1.5em 0",
        padding: "25px",
        fontSize: "1.15em"
    },
    bmMorphShape: {
        fill: "#47518f"
    },
    bmItemList: {
        color: "#b8b7ad",
        paddingTop: "0.8em"
    },
    bmItem: {
        display: "block",
        color: "#fff",
        fontSize: "18px",
        letterSpacing: "1px",
        lineHeight: "30px",
        fontFamily: "Roboto Condensed",
        fontWeight: "400",
        textTransform: "uppercase",
        outline: "none"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: 0
    }
};

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 1089px) {
    display: block;
  }
`;

const MenuIcons = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  @media (max-width: 1024px) {
  }
`;

const RightMenu = styled.span`
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #3ce7ff;
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  text-decoration: underline;
  & > p {
    margin-bottom: 5px;
  }
`;

const PanelCollapse = styled.div`
  transition: height 0.3s ease-out;
  overflow: hidden;
`;

const Body = styled.div`
position: relative;
  /* display: ${props =>
    props.isopen === "true" ? "inline-block" : "none"}; */
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-transform: none;
  /* transition: height 0.3s ease-out; */
  & > p {
    margin: 10px 0;
  }
`;

export const MenuLink = styled(Link)`
  margin-bottom: 10px;
  text-decoration: none;
   & > p {
     padding-left: 7px;
  }
`;

const SideMenuCollapse = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 17px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: relative;
  display: block;
  height: 90px;
  width: 67px;
  left: 0;
  margin: 0 auto;
  /* margin-bottom: 10px; */
  z-index: 10;
`;

const CustomImage = styled(Image)`
  width: 67px;
  left: 0;
`;

const TitleItem = styled.div`
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
`;

const TitleItemSpan = styled.span`
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #3ce7ff;
  }
`;

const ArrowBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transition: all 0.2s;
  margin-top: 8px;

  transform: ${props =>
    props.isopen === "true" ? `rotate(-135deg)` : `rotate(45deg)`};
`;

const Tab = styled.div`
    position: relative;
    margin-top: 2px;
    padding-left: 8px;
    width: 100%;
`


class MobileHeader extends React.Component {


    render() {
        const { isOpenList } = this.props.state;
        const currentHeight = {
            acc: isOpenList.acc ? 'auto' : 0,
            doc: isOpenList.doc ? 180 : 0,
        }

        return (
            <MenuWrapper>
                <Menu
                    styles={styles}
                    right
                    id="page-wrap"
                    isOpen={this.props.state.isMenuOpen}
                    onStateChange={state => this.props.handleChange(state)}
                >
                    <a
                        href="/admin"
                        style={{
                            display: "flex",
                            width: "110px",
                            textDecoration: "none"
                        }}
                    >
                        <MenuIcons src={icon_login} alt="icon" />
                        <RightMenu>Войти</RightMenu>
                    </a>
                    <Row style={{ display: "flex", marginTop: '10px' }}>
                        <Tab>
                            <Header onClick={e => this.props.handleClick(e, 'acc')}>
                                Конкурсы
                            </Header>
                            <PanelCollapse style={{ height: currentHeight.acc }}>
                                <Body isopen={isOpenList.toString()} ref="inner">
                                    {
                                        this.props.competitions && this.props.competitions.options
                                        && this.props.competitions.options.map(competition => (
                                            <MenuLink key={competition.id}
                                                      onClick={this.props.closeMenu}
                                                      to={`/konkursi/${competition['active-contest']}`}>
                                                <SideMenuCollapse id={competition.id} competitionId={this.props.competitionId} >
                                                    {competition.name}
                                                </SideMenuCollapse>
                                            </MenuLink>
                                        ))
                                    }
                                </Body>
                            </PanelCollapse>
                            <ArrowBtn onClick={(e) => this.props.handleClick(e, 'acc')}>
                                <Arrow isopen={isOpenList.acc.toString()} />
                            </ArrowBtn>
                        </Tab>
                        {/*<Col xs={2}>*/}
                        {/*   */}
                        {/*</Col>*/}
                        <Tab>
                            <Header onClick={e => this.props.handleClick(e, 'doc')}>
                                Материалы
                            </Header>
                            <PanelCollapse style={{ height: currentHeight.doc + "px" }}>
                                <Body isopen={isOpenList.toString()} ref="inner">
                                    <MenuLink to="/about" onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>О конкурсе</SideMenuCollapse>
                                    </MenuLink>
                                    <MenuLink to={`/docs/${createPath(this.props.competitions.options, this.props.competitions.info)}`} onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>Документы</SideMenuCollapse>
                                    </MenuLink>
                                    <MenuLink to={`/topics/${createPath(this.props.competitions.options, this.props.competitions.info)}`} onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>
                                            Тематические направления
                                        </SideMenuCollapse>
                                    </MenuLink>
                                    <MenuLink to={`/calendar/${createPath(this.props.competitions.options, this.props.competitions.info)}`} onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>Календарь</SideMenuCollapse>
                                    </MenuLink>
                                    <MenuLink to="/qa" onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>Вопросы-ответы</SideMenuCollapse>
                                    </MenuLink>
                                    <MenuLink to="/mediateka" onClick={this.props.closeMenu}>
                                        <SideMenuCollapse>Медиатека</SideMenuCollapse>
                                    </MenuLink>
                                </Body>
                            </PanelCollapse>
                            <ArrowBtn onClick={(e) => this.props.handleClick(e, 'doc')}>
                                <Arrow isopen={isOpenList.doc.toString()} />
                            </ArrowBtn>
                        </Tab>
                    </Row>
                    <Link
                        id="winners"
                        className="menu-item"
                        to={`/winners?competitionId=${createPath(this.props.competitions.options, this.props.competitions.info)}`}
                        onClick={this.props.closeMenu}
                    >
                        Работы победителей
                    </Link>
                    {this.props.state.years && <Link id="contact" className="menu-item" to={{
                        pathname: "/winners",
                        search: `?year=${this.props.state.years[0]}`,
                        state: { year: this.props.state.years[0] }
                    }} onClick={this.props.closeMenu}>
                        Работы победителей
                    </Link>}
                    <Link to={`/news?competitionId=${createPath(this.props.competitions.options, this.props.competitions.info)}&page=1`} onClick={this.props.closeMenu}>
                        Новости
                    </Link>
                    <Row style={{
                        display: "grid",
                        gridTemplateColumns: 'repeat( auto-fill, minmax(105px, 1fr) )',
                        gap: '20px',
                        padding: '20px 10px'
                    }}>
                        {
                            this.props.links && items.filter(({id}) => this.props.links.includes(id)).map((item, i) => (
                                <Link to={`${item.link}/${item.id}/${createPath(this.props.competitions.options, this.props.competitions.info)}`} key={i}
                                      onClick={this.props.closeMenu}
                                      style={{
                                          textDecoration: 'none',
                                          fontSize: '16px',
                                          color: '#fff',
                                          marginRight: '10px'
                                      }}
                                >
                                    <div style={{height: '65px'}}>
                                        {/* <AbsoluteDiv /> */}
                                        <img src={item.img} alt="icon" style={{width: '70px'}}/>
                                    </div>
                                    <div>
                                        <span>{ReactHtmlParser(item.title)}</span>
                                    </div>
                                </Link>
                            ))
                        }
                        <Link to={`/operator`}
                              onClick={this.props.closeMenu}
                              style={{
                                  textDecoration: 'none',
                                  fontSize: '16px',
                                  color: '#fff'
                              }}
                        >
                            <div style={{height: '65px'}} >
                                {/* <AbsoluteDiv /> */}
                                <img src={icon_reg} alt="icon" style={{width: '70px'}}/>
                            </div>
                            <div>
                        <span>
                              Ответственные операторы <br/>
                              регионального этапа Конкурса
                        </span>
                            </div>
                        </Link>
                    </Row>
                </Menu>
            </MenuWrapper>
        )
    }
}

export default MobileHeader;

