import React from "react";
import styled from "styled-components";
import {Col} from "react-flexbox-grid";

const TabItem = styled(Col)`
  color: ${props => (props.isactive === `true` ? `#fff` : `#6873b5` )};
  background: ${props => (props.isactive  === `true` ? `#4c579a` : `#fff`)};
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20.25px;
  text-transform: uppercase;
  letter-spacing: 0.72px;
  border: 1px solid #b7c5d8;
  border-right: ${props => (props.borderright)};
  text-align: center; 
  padding: 12px 0;
  
  &:last-of-type {
    border-right: 1px solid #b7c5d8;
  }
  
  &:hover, &:active {
    background: #4c579a;
    color: #fff;
    cursor: pointer;
  }
  
  @media screen and (max-width: 991px) {
    & {
      border-right: 1px solid #b7c5d8;
    }
  }
`;

const Tab = ({tabName, isActive, handleClick, value, borderRight = 'none'}) => {
    return (
        <TabItem lg={2} md={4} xs={6}
                 isactive={isActive.toString()}
                 onClick={handleClick}
                 value={value}
                 borderright={borderRight}
        >
            {tabName}
        </TabItem>
    );
};


export default Tab;