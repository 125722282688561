import React, {Component} from "react";
import {connect} from "react-redux";
import WinnersPageContainer from "./WinnersPageContainer";
import queryString from "query-string";
import {getCompetitionInfoRequest, getAllCompetitionsIdRequest} from '../../actions/competitionsActions';
import {getAllWinnersForCompetitionRequest} from '../../actions/winnersActions';
import {transformOptions} from '../../helpers/transformOptions';

class WinnersPageWrapper extends Component {
    state = {
        chosenGrade: null,
        chosenGroup: null,
        actualCompetitionByGroup: null
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);

        this.props.getAllWinnersForCompetitionRequest(params.competitionId)
        this.props.getCompetitionInfoRequest(params.competitionId)
    }

    componentDidUpdate(prevProps, prevState) {

        const params = queryString.parse(this.props.location.search)
        const prevParams = queryString.parse(prevProps.location.search)

        if (this.props.competitions && this.props.competitions.info && this.props.competitions.info.type) {
            if (this.state.chosenGroup !== this.props.competitions.info.type.id) {
                this.setState({
                    chosenGroup: this.props.competitions.info.type.id
                });
                this.props.getAllCompetitionsIdRequest(this.props.competitions.info.type.id)
            }
        }

        if (+params.chosenGrade && !this.state.chosenGrade &&
            this.props.competitions && this.props.competitions.info && this.props.competitions.info.grades) {
            const chosenGrade = this.props.competitions.info.grades.filter(grade => grade.id === +params.chosenGrade)[0];
            this.setState({
                chosenGrade: {
                    value: chosenGrade.id,
                    label: chosenGrade.name
                }
            })
        }

        if (params.competitionId !== prevParams.competitionId) {
            this.setState({
                chosenGrade: null
            })
            this.props.getCompetitionInfoRequest(params.competitionId)
            this.props.getAllWinnersForCompetitionRequest(params.competitionId)
        }
    }

    changeGrade(grade) {
        this.setState({chosenGrade: grade})
    }

    filterWinners(winners) {
        if (winners) {
            const transformedWinners = winners.map(winnerBlock => {
                return winnerBlock.winners.map(winner => {
                    return {
                        ...winner,
                        winnerStatus: {
                            id: winnerBlock.id,
                            name: winnerBlock.name
                        }
                    }
                })
            }).flat();
            return this.state.chosenGrade && this.state.chosenGrade.value ?
                transformedWinners.filter(winner => winner.grade_category.id === +this.state.chosenGrade.value) : transformedWinners;
        }
    }


    render() {
        return (
            <>
                <WinnersPageContainer
                    tabs={this.props.competitions.info && [{
                        value: 0,
                        label: 'Все группы'
                    }, ...transformOptions(this.props.competitions.info.grades)]}
                    statuses={this.props.statuses}
                    filteredWinners={this.filterWinners(this.props.winners)}
                    openModalHandler={this.props.openModalHandler}
                    changeGrade={this.changeGrade.bind(this)}
                    competitionsInChosenGroup={transformOptions(this.props.competitions.allGroupCompetitions)}
                    options={[{value: 0, label: 'Все статусы'}, ...transformOptions(this.props.winners)]}
                    isLoading={this.props.isWinnersLoading}
                    {...this.props}
                />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModalHandler: (id) =>
            dispatch({type: "DOCS_OPEN_MODAL", payload: id}),
        getAllWinnersForCompetitionRequest: (id) =>
            dispatch(getAllWinnersForCompetitionRequest(id)),
        getCompetitionInfoRequest: (id) =>
            dispatch(getCompetitionInfoRequest(id)),
        getAllCompetitionsIdRequest: (id) =>
            dispatch(getAllCompetitionsIdRequest(id))
    };
};

const mapStateToProps = (state) => ({
    competitions: state.competitions,
    isTabsLoading: state.winnerPage.isGradesLoading,
    statuses: state.winnerPage.statuses,
    isStatusesLoading: state.winnerPage.isStatusesLoading,
    winners: state.winners.allWinners,
    isWinnersLoading: state.winnerPage.isWinnersLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(WinnersPageWrapper);
