import React, {Component} from 'react';
import {connect} from "react-redux";
import UnitPageComponent from "./UnitPageComponent";
import {getCompetitionInfoRequest} from '../../actions/competitionsActions';
import LogosLinks from '../WinnersPage/LogosLinks';

class UnitPageContainer extends Component {

    state = {}

    componentDidMount() {
        if (this.props.competitions.info && this.props.competitions.info.id) {
            if (+this.props.match.params.competitionId === +this.props.competitions.info.id) {
                const chosenSection = this.props.competitions.info.section.filter(section => section.id === +this.props.match.params.sectionId)[0];
                this.setState(chosenSection)
            } else {
                this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
            }
        } else {
            this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.competitions.info !== prevProps.competitions.info
        || this.props.match.params.sectionId !== prevProps.match.params.sectionId
        ) {
            const chosenSection = this.props.competitions.info.section.filter(section => section.id === +this.props.match.params.sectionId)[0];
            this.setState(chosenSection)
        }
    }

    render() {
        return (
            <>
                {/*<LogosLinks links={this.props.competitions.info && this.props.competitions.info.section ? this.props.competitions.info.section.map(section => section.id) : []} {...this.props} />*/}
                <UnitPageComponent openModalHandler={this.props.openModalHandler} page={this.state}/>
            </>

        )
    }
}

const mapStateToProps = ({competitions}) => ({
    competitions
})

const mapDispatchToProps = dispatch => {
    return {
        openModalHandler: (url) => dispatch({type: "DOCS_OPEN_MODAL", payload: url}),
        getCompetitionInfoRequest: (id) => dispatch(getCompetitionInfoRequest(id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitPageContainer);
