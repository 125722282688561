import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { GET_SECTION_INFO_BY_ID_REQUEST } from '../constants/documentsTypes';
import {
    getSectionInfoByIdSuccess,
    getSectionInfoByIdFailure
} from '../actions/documentsActions';

export function* getSectionInfoById({payload}) {
    const {id, sectionBlock} = payload;
    try {
        const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/contest/${id}/section/${sectionBlock}`);
        const data = response.data;
        yield put(getSectionInfoByIdSuccess({data, name: sectionBlock}));
    } catch (error) {
        const message = "error, please try again";
        yield put(getSectionInfoByIdFailure(message));
    }
}

export function* documentsActionWatcher() {
    yield takeLatest(GET_SECTION_INFO_BY_ID_REQUEST, getSectionInfoById);
}
