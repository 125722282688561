import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";

import { DownLoadLink, Icon, ActionContainer, DocsItem, DocsInfo, DocsNames } from './DocItem';
import { ActionItem, ActionWrapper } from './DiplomaItem';
import { displayLook, getExtension, getFileSize, isImage } from '../../helpers/workWithFiles';

import download from "../../assets/svg/down.svg";
import look from "../../assets/svg/look.svg";

const DocsItemShort = styled(DocsItem)`
  width: 47%;
  
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

const DocItemShort = ({ doc, openModalHandler }) => {
    return (
        <DocsItemShort>
            <Row middle="lg">
                <Col lg={12} md={12}>
                    <DocsNames>
                        {doc.name}
                    </DocsNames>
                    <DocsInfo>Документ / {getExtension(doc.uploads.url)}, {getFileSize(doc.uploads.file_size)} Кб</DocsInfo>
                </Col>
                <ActionWrapper>
                    {displayLook(doc.uploads.file_type) &&
                        <ActionItem>
                            { isImage(doc.uploads.file_type) ?
                                <ActionContainer>
                                    <DownLoadLink href={doc.uploads.url} target='_blank'>
                                        <Icon BiggerIcon BiggerPadding src={look} alt="" />
                                        Смотреть
                                    </DownLoadLink>
                                </ActionContainer>
                                :
                                <ActionContainer
                                    onClick={() => openModalHandler(doc.uploads.url)}
                                >
                                    <Icon BiggerIcon src={look} alt="" />
                                    Смотреть
                                </ActionContainer>
                            }
                        </ActionItem>
                    }
                    {doc.uploads.download_url &&
                        <ActionItem>
                            <DownLoadLink href={doc.uploads.download_url} download>
                                <ActionContainer textAlign>
                                    <Icon src={download} alt="" />
                                    Скачать
                                </ActionContainer>
                            </DownLoadLink>
                        </ActionItem>
                    }
                </ActionWrapper>
            </Row>
        </DocsItemShort>
    );
};

export default DocItemShort;