import React from "react";
import HeaderContainer from '../Header/HeaderContainer';
import FooterContainer from "../Footer/FooterContainer";
import queryString from 'query-string';
import './Layout.scss';

const Layout = props => {
  const search = queryString.parse(props.location.search);
  console.log('search', props)

  const pathname = props.location.pathname && props.location.pathname.match(/[^/]+$/) ? props.location.pathname.match(/[^/]+$/)[0] : null
  return (
    <>
      {/*<PagesHeaderContainer />*/}
      <HeaderContainer isHome={false} {...props} competitionId={search.competitionId ? search.competitionId : pathname}/>
      {props.children}
      <FooterContainer />
    </>
  );
};

export default Layout;
