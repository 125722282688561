import React from "react";
import "./HeaderComponent.scss";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Grid, Row, Col} from "react-flexbox-grid";
import {PageContainer} from "../../styles/containers";
import icon_login from "../../assets/svg/ico-login.svg";
import bg_head from "../../assets/svg/bg-head.svg";
import main_logo from '../../assets/svg/logo-blue-transparent-site.svg';
import line from "../../assets/svg/head-bg-1.svg";
import fullLine from "../../assets/svg/head-bg-2.svg";
import MobileHeader from './MobileHeader';
import LogosLinks from '../WinnersPage/LogosLinks';
import { createPath } from '../../helpers/createPath';

const HeaderContainer = styled.div`
  position: ${props => props.isHome ? 'absolute' : 'static'};
  z-index: 15;
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;
  /* background: url(${bg_head}) center no-repeat; */
  
  a {
    color: #fff;
  }
`;

const FakeCol = styled(Col)`
  // @media (max-width: 991px) {
  //   display: none;
  // }
`;

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  margin-top: 0;
  top: 30px;
  left: ${props => props.left || `10.5%`};
  width: ${props => props.width || `282px`};
  box-shadow: 0 0 13px rgba(2, 2, 2, 0.35);
  list-style: none;
  background-color: rgba(40, 57, 116, 0.8);
  padding: 25px;
  z-index: 999;
  height: ${props => props.height};
`;

const DropdownItem = styled.li`
  margin-bottom: 10px;
  text-align: left;
  line-height: 24px;
  color: #beccfc;
  text-transform: none;
  cursor: pointer;
  &:last-child {
    /* margin-bottom: 0; */
  }
  &:hover {
    color: #fff;
  }
`;

const DropdownItemWithLight = styled.li`
  margin-bottom: 10px;
  text-align: left;
  line-height: 24px;
  color: ${props => +props.competitionId === +props.id ? '#3ce7ff' : '#beccfc'};
  text-transform: none;
  cursor: pointer;
  &:last-child {
    /* margin-bottom: 0; */
  }
  &:hover {
    color: #fff;
  }
`;

export const MenuLink = styled(Link)`
  margin-bottom: 10px;
  text-decoration: none;
`;

const FullsizeMenu = styled.span`
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #3ce7ff;
  }
  &:hover ${DropdownMenu} {
    opacity: 1;
    display: block;
  } 
  @media (max-width: 1089px) {
    display: none;
  }
`;

const RightMenu = styled.span`
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #3ce7ff;
  }
`;

const MenuIcons = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  @media (max-width: 1024px) {
  }
`;

const LoginBtn = styled.a`
  display: flex;
  text-decoration: none;
  @media (max-width: 1089px) {
    display: none;
  }
`;

const ClipPathWrapper = styled.div`
  background: url(${props => props.isHome ? line : fullLine});
  background-size: cover;
  padding-bottom: 35px;
  margin-top: -120px;
  width: 100%;
  z-index: 10;
  position: relative;
  @media (min-width: 1440px) {
    padding-bottom: 60px;
  }
`;

class HeaderComponent extends React.Component {
    state = {
        isMenuOpen: false,
        isOpenList: {
            acc: false,
            doc: false
        },
        years: null,
        competitions: this.props.competitions.options || []
    };

    static getDerivedStateFromProps(props, state) {
        return {
            competitions: props.competitions.options
        }
    }

    handleStateChange(state) {
        this.setState({isMenuOpen: state.isOpen});
        if (state.isOpen) {
            document.body.style.overflowY = "hidden";
            document.body.style.marginRight = "15px";
        } else {
            document.body.style.overflowY = "auto";
            document.body.style.marginRight = "0";
        }
    }

    closeMenu = () => {
        this.setState({isMenuOpen: false});
        document.body.style.overflowY = "auto";
        document.body.style.marginRight = "0";
    };

    handleClick = (e, tab) => {
        e.preventDefault();
        this.setState({
            isOpenList: {...this.state.isOpenList, [tab]: !this.state.isOpenList[tab]}
        });
    };
    //
    // createPath = () => {
    //     return this.props.competitions.options && this.props.competitions.info && this.props.competitions.info.type &&
    //     getCompetitionId(this.props.competitions.info.type.id, this.props.competitions.options)
    // }

    render() {
        return (
            <>
                <HeaderContainer isHome={this.props.isHome}>
                    {/* <BgImage src={bg_head} alt="" /> */}
                    <ClipPathWrapper isHome={this.props.isHome}>
                        <PageContainer style={{position: "relative", zIndex: "10"}}>
                            <Grid fluid>
                                <Row
                                    style={{
                                        paddingTop: "130px",
                                        // height: "100px",
                                        alignItems: "center"
                                    }}
                                >
                                    <FakeCol lg={3}>
                                        <MenuLink to="/">
                                            <img src={main_logo} alt="icon" style={{width: '230px'}}/>
                                        </MenuLink>
                                    </FakeCol>
                                    <div className="flex">
                                        <div className="flex-block">
                                            <div className="flex-item">
                                                <FullsizeMenu>
                                                    Конкурсы
                                                </FullsizeMenu>
                                                <DropdownMenu className='flex-menu'>
                                                    {
                                                        this.props.competitions && this.props.competitions.options
                                                        && this.props.competitions.options.map(competition => (
                                                            <MenuLink key={competition.id}
                                                                      to={`/konkursi/${competition['active-contest']}`}>
                                                                <DropdownItemWithLight id={competition['active-contest']} competitionId={this.props.competitionId} >
                                                                    {competition.name}
                                                                </DropdownItemWithLight>
                                                            </MenuLink>
                                                        ))
                                                    }
                                                </DropdownMenu>
                                            </div>
                                            <div className="flex-item">
                                                <FullsizeMenu>Материалы</FullsizeMenu>
                                                <DropdownMenu className='flex-menu'>
                                                    <MenuLink to="/about">
                                                        <DropdownItem>О Конкурсе</DropdownItem>
                                                    </MenuLink>
                                                    <MenuLink to={`/docs/${createPath(this.props.competitions.options, this.props.competitions.info)}`}>
                                                        <DropdownItem>Документы</DropdownItem>
                                                    </MenuLink>
                                                    <MenuLink to={`/topics/${createPath(this.props.competitions.options, this.props.competitions.info)}`}>
                                                        <DropdownItem>Тематические направления</DropdownItem>
                                                    </MenuLink>
                                                    <MenuLink to={`/calendar/${createPath(this.props.competitions.options, this.props.competitions.info)}`}>
                                                        <DropdownItem>Календарь</DropdownItem>
                                                    </MenuLink>
                                                    <MenuLink to="/qa">
                                                        <DropdownItem>Вопросы-ответы</DropdownItem>
                                                    </MenuLink>
                                                    <MenuLink to="/mediateka">
                                                        <DropdownItem>Медиатека</DropdownItem>
                                                    </MenuLink>
                                                </DropdownMenu>
                                            </div>
                                            <div className="flex-item">
                                                <MenuLink to={{
                                                    pathname: "/winners",
                                                    search: `?competitionId=${createPath(this.props.competitions.options, this.props.competitions.info)}`
                                                }}>
                                                    <FullsizeMenu>Работы победителей</FullsizeMenu>
                                                </MenuLink>
                                            </div>
                                            <div className="flex-item">
                                                <MenuLink to={`/news?competitionId=${createPath(this.props.competitions.options, this.props.competitions.info)}&page=1`}>
                                                    <FullsizeMenu>
                                                        Новости
                                                    </FullsizeMenu>
                                                </MenuLink>
                                            </div>
                                        </div>
                                        <div className="flex-block">
                                            <div className="flex-item">
                                                <LoginBtn href="/admin">
                                                    <MenuIcons src={icon_login} alt=""/>
                                                    <RightMenu>Войти</RightMenu>
                                                </LoginBtn>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Grid>
                        </PageContainer>
                    </ClipPathWrapper>
                </HeaderContainer>
                <MobileHeader state={this.state}
                              handleChange={this.handleStateChange.bind(this)}
                              handleClick={this.handleClick.bind(this)}
                              closeMenu={this.closeMenu.bind(this)}
                              links={this.props.competitions.info && this.props.competitions.info.section ? this.props.competitions.info.section.map(section => section.id) : []}
                              {...this.props}
                />
                {!this.props.isHome &&
                <LogosLinks
                    links={this.props.competitions.info && this.props.competitions.info.section ? this.props.competitions.info.section.map(section => section.id) : []}
                    {...this.props}
                />
                }
            </>
        );
    }
}

HeaderComponent.defaultProps = {
    isHome: false,
    competitionId: null
}

export default HeaderComponent;
