import React from 'react';

const SearchSvg = ({color}) => {
    return (
        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="24px" height="23.972px" viewBox="0 0 24 23.972" enableBackground="new 0 0 24 23.972" xmlSpace="preserve">
            <g>
                <path fill={color} d="M9.601,18.577L9.601,18.577c-5.011,0-9.125-4.114-9.125-9.05c0-5.011,4.039-9.05,9.05-9.05
                    c5.011-0.075,9.125,4.039,9.125,9.05C18.651,14.464,14.537,18.577,9.601,18.577z M9.601,1.599c-4.413,0-7.928,3.515-7.928,7.928
                    c0,4.338,3.59,7.928,7.928,7.928s7.928-3.59,7.928-7.928S13.939,1.599,9.601,1.599z"/>
                <path fill={color} d="M9.601,18.577v-0.476c-2.374,0-4.535-0.974-6.106-2.535c-1.571-1.562-2.543-3.704-2.543-6.039
                    c0.001-4.749,3.825-8.573,8.574-8.574H9.53l0.003,0l0.127-0.001c2.343,0,4.468,0.965,6.014,2.522
                    c1.545,1.558,2.501,3.7,2.501,6.053c0,2.334-0.973,4.477-2.534,6.04c-1.562,1.562-3.705,2.535-6.04,2.534L9.601,18.577v0.476
                    c5.205-0.005,9.521-4.321,9.526-9.526C19.126,4.298,14.879,0.002,9.661,0L9.519,0.001l0.007,0.476V0.001
                    c-2.635,0-5.02,1.063-6.741,2.785C1.063,4.507,0,6.892,0,9.527c0.004,5.206,4.325,9.523,9.601,9.526L9.601,18.577z M9.601,1.599
                    V1.123c-2.334,0-4.439,0.932-5.956,2.449C2.128,5.088,1.196,7.193,1.197,9.527c0.004,4.605,3.799,8.401,8.404,8.404
                    c4.605-0.004,8.401-3.799,8.404-8.404c-0.004-4.605-3.799-8.401-8.404-8.404L9.601,1.599v0.476c2.036,0,3.898,0.842,5.254,2.198
                    c1.356,1.356,2.198,3.218,2.198,5.254c0,2.036-0.842,3.898-2.198,5.254c-1.356,1.356-3.218,2.198-5.254,2.198
                    c-2.036,0-3.898-0.842-5.254-2.198c-1.356-1.356-2.198-3.218-2.198-5.254c0.003-4.153,3.299-7.45,7.452-7.452L9.601,1.599z"/>

                <rect x="14.056" y="18.665" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 19.2742 46.4365)" fill={color} width="10.396" height="1.122"/>
                <polygon fill={color} points="15.975,15.154 15.639,15.491 22.654,22.505 22.533,22.625 15.855,15.947 16.312,15.491
                    15.975,15.154 15.639,15.491 15.975,15.154 15.639,14.817 14.509,15.947 22.533,23.972 24,22.505 15.975,14.481 15.639,14.817 	"/>
            </g>
        </svg>
    );
};

export default SearchSvg;