import React from 'react';
import {Link} from 'react-router-dom';
import 'moment/locale/ru';
import './HomeInformationCalendar.scss';
import {getMonth} from '../../../helpers/getMonth';

const HomeInformationCalendar = props => {
    return (
        <div className="HomeInformationCalendar">
            <div className="title_h2">
                Календарь
            </div>
            <div className="main-content">
                <div className={`date ${props.calendar.mainEvent.date_end ? '' : 'date--center'}`}>
                    {props.calendar.mainEvent.date_start ?
                        <>
                            <div className="date__start">
                                <div className="date__day">
                                    {parseInt(props.calendar.mainEvent.date_start.split("T")[0].split("-")[2])}
                                </div>
                                <div className="date__month">
                                    {getMonth(new Date(props.calendar.mainEvent.date_start).getMonth())}
                                </div>
                                <div className="date__year">
                                    {new Date(props.calendar.mainEvent.date_start).getFullYear()}
                                </div>
                            </div>
                            {
                                props.calendar.mainEvent.date_end ?
                                    <div className="slash">
                                        -
                                    </div> : null
                            }
                        </>
                        :
                        <div className="slash slash--small">
                            до
                        </div>}
                    {
                        props.calendar.mainEvent.date_end ?
                            <div className="date__end">
                                <div className="date__day">
                                    {parseInt(props.calendar.mainEvent.date_end.split("T")[0].split("-")[2])}
                                </div>
                                <div className="date__month">
                                    {getMonth(new Date(props.calendar.mainEvent.date_end).getMonth())}
                                </div>
                                <div className="date__year">
                                    {new Date(props.calendar.mainEvent.date_end).getFullYear()}
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="stage">
                    <Link to={`/calendar/${props.competitionId}/#scrollToMain`}>
                        <div className="stage__title">
                            {props.calendar.mainEvent.stage}
                        </div>
                        <div className="stage__description">
                            {props.calendar.mainEvent.event}
                        </div>
                    </Link>
                </div>
                <div className="schedule">
                    <div className="schedule--square">

                    </div>
                    <div className="schedule--link">
                        <Link to={`/calendar/${props.competitionId}`}>
                            График<br/>
                            проведения<br/>
                            конкурса
                        </Link>
                    </div>
                </div>
            </div>
            <div className="button-block">
                <Link to={'/operator'} className="button button--green">
                    Региональные сайты конкурса
                </Link>
            </div>
        </div>
    );
};

export default HomeInformationCalendar;
