import React from "react";
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className='NotFound'>
            <div className="big">404</div>
            PAGE NOT FOUND
        </div>
    )
}

export default NotFound;
