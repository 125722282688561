import React from "react";
import './Nomination.scss';
import img from './../../../assets/svg/nomination.svg'

const Nomination = ({name}) => {

    return (
        <div className={'Nomination'}>
            <img src={img} alt="nomination"/>
            <div className="Nomination__name">
                Победитель в номинации "{name}"
            </div>
        </div>
    )
}

export default Nomination;
