import { combineReducers } from "redux";
import menuReducer from "./menuReducer";
import calendarReducer from "./calendarReducer";
import winnersReducer from "./winnersReducer";
import documentsReducer from "./documentsReducer";
import winnerPageReducer from "./winnerPageReducer";
import operatorsReducer from "./operatorsReducer";
import regionsReducer from "./regionsReducer";
import competitionsReducer from './competitionsReducer';
import newsReducer from './newsReducer';

const rootReducer = combineReducers({
    test: menuReducer,
    calendar: calendarReducer,
    winners: winnersReducer,
    documents: documentsReducer,
    winnerPage: winnerPageReducer,
    operators: operatorsReducer,
    regions: regionsReducer,
    competitions: competitionsReducer,
    news: newsReducer
});

export default rootReducer;
