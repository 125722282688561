import React, { Component } from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import { Col, Grid, Row } from "react-flexbox-grid";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { BreadcrumbsItemLink } from "../NewsPage/NewsPageComponent";
import { SectionTitle } from "../DocumentsPage/DocumentsPageComponent";
import { QAPageWrapper } from "../QAPage/QAPageContainer";
import Accordion from "../UI/Accordion";
import { H2 } from "../../styles/fonts";
import {getCompetitionInfoRequest} from '../../actions/competitionsActions';

const AccordionContainer = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  & > div {
    width: 100%;
  }

  margin-bottom: 60px;
`;

const Number = styled.span`
  color: #7db9d8;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-decoration: underline;
`;

const GenresContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
`;

const GenreTitle = styled.h2`
  position: relative;
  padding-left: 23px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  z-index: 10;
  color: #10215e;
  font-size: 16px;
  font-weight: 400;

  &:before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    left: 0;
    top: -3.5px;
    background-color: ${props => props.color};
    z-index: -1;
  }
`;

const P = styled.p`
  color: #616875;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 8px 0 32px;
`;

class TopicsPageContainer extends Component {
  state = {
    subjectAreas: [],
    genres: []
  };

  componentDidMount() {
    if (this.props.competitions.info && this.props.competitions.info.id) {
      if (+this.props.match.params.competitionId !== +this.props.competitions.info.id) {
        this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
      }
    } else this.props.getCompetitionInfoRequest(this.props.match.params.competitionId)
  }

  render() {
    return (
      <QAPageWrapper>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs
                separator={<span style={{ color: "#39404c" }}> / </span>}
                finalItem={"b"}
                finalProps={{
                  style: { color: "#6873b5", fontWeight: "normal" }
                }}
              />
              <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
              <BreadcrumbsItem to={`/news/?page=1`}>
                Тематические направления ВКC
              </BreadcrumbsItem>
            </Col>
          </Row>
          <div style={{minHeight: '300px'}}>
            <Row>
              <Col lg={5}>
                <H2>Тематические направления ВКC</H2>
              </Col>
            </Row>
            {this.props.competitions.info && this.props.competitions.info.subject_areas &&
            this.props.competitions.info.subject_areas.length ?
                <>
                  <AccordionContainer>
                    {this.props.competitions.info.subject_areas.map((topic, i) => (
                        <Row key={i}>
                          <Col sm={1}>
                            <Number>{i + 1}</Number>
                          </Col>
                          <Col sm={11}>
                            <Accordion
                                header={topic.name}
                                body={topic.description}
                                btnPosition="right"
                            />
                          </Col>
                        </Row>
                    ))}
                  </AccordionContainer>
                </> : null
            }
            {
              this.props.competitions.info && this.props.competitions.info.essay_genres && this.props.competitions.info.essay_genres.length ?
                  <GenresContainer>
                    <SectionTitle color="#5ad1e2">Жанры конкурсных работ:</SectionTitle>
                    <br />
                    {this.props.competitions.info.essay_genres.map((genre, i) => (
                        <div key={i}>
                          <GenreTitle color="#00bbd5">{genre.name}</GenreTitle>
                          <P>{genre.description}</P>
                        </div>
                    ))}
                    <br />
                  </GenresContainer> : null
            }
          </div>
        </Grid>
      </QAPageWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompetitionInfoRequest: (id) =>
        dispatch(getCompetitionInfoRequest(id)),
  }
}

const mapStateToProps = (state) => ({
  competitions: state.competitions,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicsPageContainer);
