import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { FETCH_REGIONS_REQUEST } from "../constants";
import {
    fetchRegionsSuccess,
    fetchRegionsError,
} from "../actions/regionsActions";

export function* fetchRegions() {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v1/regions`);
    const data = response.data;
    try {
        yield put(fetchRegionsSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(fetchRegionsError(message));
    }
}

export function* regionsActionWatcher() {
    yield takeLatest(FETCH_REGIONS_REQUEST, fetchRegions);
}
