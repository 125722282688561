import React from "react";

import axios from "axios";
import styled from "styled-components";
// import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";
import {Row, Col} from "react-flexbox-grid";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {PageContainer} from "../../../styles/containers";
import {H2, Text} from "../../../styles/fonts";
import bg from "../../../assets/img/gerb-1280_and_more.png";
import win_icon from "../../../assets/svg/ico-winners.svg";
import chevron from "../../../assets/svg/right-thin-chevron.svg";
import read_icon from "../../../assets/svg/ico-read.svg";
import lowresBg from "../../../assets/svg/bg-winner-znak.svg";
import winners_logo from "../../../assets/img/winners_logo.png";

import winner1 from "../../../assets/img/winners/2018АндрейВейдеКрасноярскийкрай.png";
// import winner2 from "../../../assets/img/winners/2018БорисМожаевСвердловскаяобласть.png";
// import winner3 from "../../../assets/img/winners/2018СофьяПшеницынаСвердловскаяобласть.png";
// import winner4 from "../../../assets/img/winners/2018ВарвараРыковаСвердловскаяобласть.png";
// import winner5 from "../../../assets/img/winners/2018ДаниилЧепурнойКурскаяобласть.png";

// import winner6 from "../../../assets/img/winners/2017АнжелаМалошенкоКрасноярскийкрай.png";
// import winner7 from "../../../assets/img/winners/2017ЕленаПрямиковаМурманская.png";
// import winner8 from "../../../assets/img/winners/2017МарияКучуковаСамарскаяобласть.png";
// import winner9 from "../../../assets/img/winners/2017НиколайТополовКостромская.png";
// import winner10 from "../../../assets/img/winners/2017СофьяШарковаЛенинградскаяобласть.png";

// import winner11 from "../../../assets/img/winners/2016АлександрОвчинниковКостромскаяобласть.png";
// import winner12 from "../../../assets/img/winners/2016АнастасияТицкаяВологодскаяобласть.png";
// import winner13 from "../../../assets/img/winners/2016ДарьяГунькинаВоронежская.png";
// import winner14 from "../../../assets/img/winners/2016НикитаМорозовгСанкт-Петербург.png";
// import winner15 from "../../../assets/img/winners/2016ПолинаКудрявцеваКурскаяобласть.png";

// import winner16 from "../../../assets/img/winners/2015ВарвараСтрижакгСанкт-Петербург.png";
// import winner17 from "../../../assets/img/winners/2015ЕвгенийСелянинАлтайскийкрай.png";
// import winner18 from "../../../assets/img/winners/2015МатвейЖуковКировскаяобласть.png";
// import winner19 from "../../../assets/img/winners/2015ЕлизаветаРыжковаВологодскаяобласть.png";
// import winner20 from "../../../assets/img/winners/2015НикитаГоловкоКраснодарскийкрай.png";

// import winner21 from "../../../assets/img/winners/2017РУБАннаПлотниковаРеспубликаХакасия.png";
// import winner22 from "../../../assets/img/winners/2017РУБДарьяБуторинаРеспубликаКоми.png";
// import winner23 from "../../../assets/img/winners/2017РУБДмитрийПавловРостовскаяобласть.png";
// import winner24 from "../../../assets/img/winners/2017РУБОксанаСуховаИвановскаяобласть.png";
// import winner25 from "../../../assets/img/winners/2017РУБОльгаЕвсееваБелгородскаяобласть.png";

import HomeModalContainer from "../HomeModal/HomeModalContainer";
import Nomination from './Nomination';

const Jobs = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url(${bg}) top center no-repeat;
  background: none;
  @media (max-width: 1920px) {
    background: url(${bg}) top center no-repeat;
  }
  @media (max-width: 991px) {
    background: url(${lowresBg}) center center no-repeat;
    /* padding-bottom: 70px; */
  }
`;

const CustomPageContainer = styled(PageContainer)`
  position: relative;
  z-index: 10;
  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ContainerSpaces = styled.div`
  padding-top: 50px;
  @media (max-width: 991px) {
    margin-top: 50px;
    padding-top: 0;
  }
`;

const TitleRow = styled(Row)`
  position: relative;
  @media (max-width: 991px) {
    background: #263381;
    &:after {
      content: "";
      position: absolute;
      bottom: -14px;
      width: 0;
      height: 0;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      border-top: 15px solid #263381;
    }
  }
`;

const HoverDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: absolute;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  /* width: 195px;
  height: 75px;
  bottom: -4px; */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0296e3;
  z-index: 11;
  transition: 0.3s;
  @media (max-width: 768px) {
    width: 125px;
    height: 125px;
  }
`;

const HoverDivText = styled.span`
  display: block;
  /* width: 50%; */
  /* margin-left: 5px; */
  /* text-align: left; */
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 22px;
  color: #ffffff;
  font-family: "Roboto Condensed";
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 14px;
  }
`;

const Circle = styled.div`
position: relative;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  /* background: url(${winner1}); */
  margin: 0 auto;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 15px;
  margin: 0 auto;
  }
  /* @media (max-width: 575px) {
    width: 115px;
  height: 115px;
  border-radius: 50%;
  margin-bottom: 15px;
  margin: 0 auto;
  } */
  &:hover ${HoverDiv} {
    opacity: 1;
  }
`;

const HoverDivIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const SlideContainer = styled(Col)`
  position: relative;
  /* width: 175px; */
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #10215e;
  font-family: "Roboto Condensed";
  cursor: pointer;
  /* &:hover ${HoverDiv} {
    opacity: 1;
  } */
  @media (max-width: 768px) {
    width: 275px;
    &:nth-child(2n) {
      margin-right: -20px;
    }
    /* &:nth-child(2n-1) {
      margin-left: 10px;
    } */
  }
`;

const TitleCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 53px;
  margin-right: 18px;
  padding-bottom: 15px;
  vertical-align: middle;
  @media (max-width: 768px) {
    width: 35px;
    height: 50px;
    margin-right: 10px;
    padding-bottom: 0;
  }
`;

const CustomH2 = styled(H2)`
  display: inline-block;
  @media (max-width: 768px) {
    font-size: 21px;
    letter-spacing: 1px;
    line-height: 22px;
    font-family: "Roboto Condensed";
    font-weight: 700;
    text-align: center;
  }
  @media (max-width: 320px) {
    font-size: 15px;
  }
`;

const Chevron = styled.div`
  width: 30px;
  margin-left: 15px;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const ReverseChevron = styled.div`
  width: 30px;
  transform: rotate(180deg);
  // margin-right: 15px;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const CustomText = styled(Text)`
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    color: #3d4669;
    font-family: "Roboto Condensed";
    font-weight: 400;
    text-align: center;
    /* width: 115px !important; */
  }
`;

const CustomRow = styled(Row)`
  position: relative;
  margin-top: 53px;
  /* display: flex !important; */
  outline: none;
  cursor: pointer;
  /* margin-left: 0px !important; */
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Background = styled.div`
  clip-path: polygon(0 0, 100% 0, 100% 35%, 33% 67%, 0 39%);
  background: #263381;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  @media (max-width: 2560px) {
    clip-path: polygon(0 0, 100% 0, 100% 42%, 25% 67%, 0 51%);
  }
  @media (max-width: 1920px) {
    display: none;
  }
`;

const WinnersLogo = styled.div`
  background: url(${winners_logo});
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  width: 562px;
  height: 100%;
  top: 6%;
  opacity: 0.2;
  @media (max-width: 1920px) {
    display: none;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-next {
    top: 51%;
    /* right: 50%; */
    /* transform: translate(720%, 0); */
    background: url(${chevron});
    width: 40px;
    height: 40px;
    &:before {
      display: none;
    }
    &:hover {
      background: url(${chevron}) !important;
    }
    @media (max-width: 1390px) {
      top: -22.4%;
      right: 50%;
      transform: translate(720%, 0);
    }
    @media (max-width: 768px) {
      top: -22.4%;
      right: 59%;
      transform: translate(720%, 0);
    }
    @media (max-width: 425px) {
      right: 56%;
      width: 30px;
      height: 30px;
    }
    @media (max-width: 320px) {
      top: -19.4%;
      right: 68%;
    }
  }
  .slick-prev {
    top: 45%;
    /* left: 50%; */
    transform: rotate(180deg);
    background: url(${chevron});
    width: 40px;
    height: 40px;
    &:before {
      display: none;
    }
    &:hover {
      background: url(${chevron}) !important;
    }
    @media (max-width: 1390px) {
      top: -22.4%;
      left: 50%;
      transform: translate(-720%, 0) rotate(180deg);
    }
    @media (max-width: 768px) {
      top: -22.4%;
      left: 59%;
      transform: translate(-720%, 0) rotate(180deg);
    }
    @media (max-width: 425px) {
      left: 56%;
      width: 30px;
      height: 30px;
    }
    @media (max-width: 320px) {
      top: -19.4%;
      left: 68%;
    }
  }
`;

class HomeWinnerComponent extends React.Component {
    state = {
        winnerData: this.props.winnersData,
        carouselInited: false,
        year: null,
        activeSlide: null,
        winnerDataNew: null
    };


    // componentDidMount() {
    //   axios.get(`${process.env.REACT_APP_API_URL}/api/v1/winners-all`).then(response => {
    //     this.setState({
    //       winnerData: response.data,
    //       year: response.data[0].year,
    //       winnerDataNew: response.data[0].items
    //     });
    //   });
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('componentDidUpdate', this.props)
    // }

    render() {
        let afterChangeHandler = currentSlide => {
            // this.props.updateInitialSlide(currentSlide);
            this.setState({
                activeSlide: this.props.data[currentSlide]
            });
        };

        const settings = {
            speed: 500,
            // slidesToShow: 1,
            // slidesToScroll: 1,
            nextArrow: <Chevron/>,
            prevArrow: <ReverseChevron/>,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1390,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 375,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        if (this.props.data && !this.props.data.length) return null;

        return (
          this.props.data && this.props.data.length &&
            <Jobs>
                <Background>
                    <WinnersLogo/>
                </Background>
                <ContainerSpaces>
                    <CustomPageContainer style={{position: "relative", zIndex: "10"}}>
                        <Row center="lg">
                            <Col lg={12} md={12} style={{}}>
                                <TitleRow center="lg" center="md" center="xs" style={{}}>
                                    <TitleCol lg={7} md={7}>
                                        <IconImage src={win_icon} alt=""/>
                                        <CustomH2 style={{color: "#fff"}}>
                                            <span>{this.props.competitions.info && this.props.competitions.info.name}</span>
                                        </CustomH2>
                                    </TitleCol>
                                </TitleRow>

                                <StyledSlider
                                    {...settings}
                                    // afterChange={afterChangeHandler}
                                >
                                    {this.props.data &&
                                    this.props.data.map(
                                        (item, id) => (
                                            <CustomRow
                                                around="lg"
                                                around="md"
                                                around="sm"
                                                around="xs"
                                                key={id}
                                            >

                                                <Circle
                                                    style={{
                                                        background: `url(${item.avatar && item.avatar.url})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: 'no-repeat'
                                                    }}
                                                >
                                                    {
                                                        item.nomination && item.nomination.name &&
                                                        <Nomination
                                                            name={item.nomination.name}
                                                        />
                                                    }
                                                    <HoverDiv
                                                        onClick={() =>
                                                            this.props.openModalHandler(item)
                                                        }
                                                    >
                                                        <HoverDivIcon src={read_icon} alt=""/>
                                                        <HoverDivText>
                                                            Читать
                                                            <br/> сочинение
                                                        </HoverDivText>
                                                    </HoverDiv>
                                                </Circle>
                                                <CustomText
                                                    style={{width: "185px", margin: "0 auto"}}
                                                >
                                                    {item.name} {item.surname}
                                                </CustomText>
                                                <CustomText
                                                    style={{width: "192px", margin: "0 auto"}}
                                                >
                                                    ({item.region && item.region.name})
                                                </CustomText>
                                            </CustomRow>
                                        )
                                    )}
                                </StyledSlider>
                            </Col>
                        </Row>
                    </CustomPageContainer>
                </ContainerSpaces>
                {this.props.modal && (
                    <HomeModalContainer
                        openModalHandler={this.props.openModalHandler}
                        close={this.props.close}
                    />
                )}
            </Jobs>
        );
    }
}

export default HomeWinnerComponent;
