import React, {Component} from 'react';
import Swiper from "react-id-swiper";
import styled from 'styled-components';
import {DownLoadLink} from "../DocumentsPage/DocumentsPageComponent";
import {DownIcon} from "../WinnersPage/WinnerCard";
import {Action} from "./MediaPageComponent";
import download from "../../assets/svg/down.svg";
import vk from "../../assets/img/vk.png";
import fb from "../../assets/img/fb.png";

const Container = styled.div`
  width: 100%;
`;

const SwiperWrapper = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 5%;
  padding: 0 97px;
  height: 550px;
  
  @media screen and (max-width: 1200px) {
    padding: 30px;
  }
  
    @media screen and (max-width: 860px) {
    padding: 15px;
  }
  
  @media screen and (max-width: 445px) {
    padding: 0;
  }
  
  & .swiper-button-next, .swiper-button-prev {
      background: transparent;
      box-sizing: border-box;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 3px solid #fff;
      
      @media screen and (max-width: 745px) {
        width: 40px;
        height: 40px;
      }
      @media screen and (max-width: 445px) {
        display: none;
      }
    }
    
    & .swiper-button-next:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      display: block;
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%) rotate(-45deg);
      
      @media screen and (max-width: 745px) {
        left: 13px;
      }
    }
    
    & .swiper-button-prev:after {
      content: '';
      position: absolute;
      right: 2px;
      top: 50%;
      display: block;
      border-left: 3px solid #fff;
      border-top: 3px solid #fff;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%) rotate(-45deg);
      
      @media screen and (max-width: 745px) {
        right: -6px;
      }
    }
    
    & .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
      background: rgb(133, 132, 184);
      border-color: rgb(133, 132, 184);
      opacity: .7;
     
    }
    
    & .swiper-button-next.swiper-button-disabled:before {
      border-right: 3px solid #3d4669;
      border-bottom: 3px solid #3d4669;
    }
    
    & .swiper-button-prev.swiper-button-disabled:after {
      border-left: 3px solid #3d4669;
      border-top: 3px solid #3d4669;
    }
    
    @media (orientation: landscape) and (max-width: 780px) {
  margin-top: 0;
  height: 100%;
  padding: 0 35px 0;
  }
  @media (orientation: landscape) and (max-width: 445px) {
  margin-top: 0;
  height: 100%;
  padding: 0 35px 0;
  }
`;

const ImageWrapper = styled.div`
  width: 80%;
  max-width: 1053px;
  height: auto;
  background: #fff;
  margin: 0 auto;
  display: flex;
  
  @media screen and (max-width: 445px) {
    margin-top: 10px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  max-width: 850px;
  flex-grow: 1;
  height: auto;
  margin: 0 auto;
  padding: 30px 30px 0;
  
   @media screen and (max-width: 445px) {
    padding: 50px 0 0;
  }
`;

const Image = styled.div`
  width: 100%;
  
  & > img {
    width: 100%;
  }
`;

export const Socials = styled.div`
  display: inline-block;
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;
  justify-content: space-between;
  @media screen and (max-width: 580px) {
    padding: 0 15px;
  }
   @media (orientation: landscape) and (max-width: 780px) {
  margin-top: 10px;
  }
  @media (orientation: landscape) and (max-width: 445px) {
  margin-top: 10px;
  }
 
`;

class ImageSlider extends Component {
    // state = {
    //     activeSlideKey: 'img_0'
    // };
    //
    // componentDidMount() {
    //     this.setState({activeSlideKey: `img_${this.props.currentImgKey}`})
    // }

    render() {
        const { images, currentImgKey } = this.props;

        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            loop: true
            // activeSlideKey: this.state.activeSlideKey
        };

        const before = images.slice(0, currentImgKey);
        const after = images.slice(currentImgKey);
        const renderImages = [...after, ...before];
        // const renderImages = images;

        return (
            <SwiperWrapper>
                <Swiper {...params}>
                    {renderImages.map((img, i) => (
                        <Container key={i}>
                        {/*<Container key={`img_${i}`}>*/}
                            <ImageWrapper>
                                <ImageContainer>
                                    <Image>
                                        <img src={img.url}/>
                                    </Image>
                                    <ActionWrapper>
                                        <Action>
                                            <DownLoadLink href={img.url} download>
                                                <DownIcon src={download}/>
                                                Скачать
                                            </DownLoadLink>
                                        </Action>
                                        <Socials style={{cursor: 'not-allowed', marginTop: '-7px'}}>
                                            <img
                                                src={vk}
                                                alt=""
                                                style={{ marginRight: "20px"}}
                                            />
                                        </Socials>
                                    </ActionWrapper>
                                </ImageContainer>
                            </ImageWrapper>
                        </Container>
                    ))}
                </Swiper>
            </SwiperWrapper>
        );
    }
}

export default ImageSlider;