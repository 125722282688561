export const GET_COMPETITION_OPTIONS_REQUEST = "GET_COMPETITION_OPTIONS_OPTIONS";
export const GET_COMPETITION_OPTIONS_SUCCESS = "GET_COMPETITION_OPTIONS_SUCCESS";
export const GET_COMPETITION_OPTIONS_FAILURE = "GET_COMPETITION_OPTIONS_FAILURE";

export const GET_COMPETITION_INFO_REQUEST = "GET_COMPETITION_INFO_REQUEST";
export const GET_COMPETITION_INFO_SUCCESS = "GET_COMPETITION_INFO_SUCCESS";
export const GET_COMPETITION_INFO_FAILURE = "GET_COMPETITION_INFO_FAILURE";

export const GET_MAIN_COMPETITION_INFO_REQUEST = "GET_MAIN_COMPETITION_INFO_REQUEST";
export const GET_MAIN_COMPETITION_INFO_SUCCESS = "GET_MAIN_COMPETITION_INFO_SUCCESS";
export const GET_MAIN_COMPETITION_INFO_FAILURE = "GET_MAIN_COMPETITION_INFO_FAILURE";

export const GET_ALL_COMPETITIONS_ID_REQUEST = "GET_ALL_COMPETITIONS_ID_REQUEST";
export const GET_ALL_COMPETITIONS_ID_SUCCESS = "GET_ALL_COMPETITIONS_ID_SUCCESS";
export const GET_ALL_COMPETITIONS_ID_FAILURE = "GET_ALL_COMPETITIONS_ID_FAILURE";


