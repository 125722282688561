import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import letter from "../../assets/img/pdf/documents/ПисьмоВКС.pdf";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: auto;
  z-index: 999;
  animation: fadein 0.433s;
`;

const CustomIframe = styled.iframe`
  width: 80%;
  height: 80%;
  margin: 0 auto;
`;

const CloseBtn = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  right: 5%;
  top: 3%;
  cursor: pointer;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 32px;
    height: 3px;
    background: #fff;
    border-radius: 10px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

class Iframemodal extends React.Component {
  componentWillReceiveProps(props) {
    if (props.modal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }

  render() {
    return (
      <>
        {this.props.modal && (
          <ModalContainer onClick={this.props.closeModalHandler}>
            <CloseBtn onClick={this.props.closeModalHandler}/>
            <CustomIframe src={this.props.url} title="PortletIFrameWidget" />
          </ModalContainer>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalHandler: () => dispatch({ type: "DOCS_CLOSE_MODAL" })
  };
};

const mapStateToProps = state => ({
  modal: state.documents.modal,
  url: state.documents.url
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Iframemodal);
