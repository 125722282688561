import React from 'react';
import {Link} from 'react-router-dom';
import "./LogosLinks.scss";
import {items} from '../../helpers/logosLinks';
import icon_reg from "../../assets/svg/k4.svg"; //ico-reg.svg
import icon_contest_in_faces from "../../assets/svg/k5.svg";
import ReactHtmlParser from 'react-html-parser';
import {getCompetitionId} from '../../helpers/getCompetitionId';

const LogosLinks = (props) => {

    const createPath = () => {
        return props.competitions && props.competitions.options && props.competitions.info && props.competitions.info.type &&
            getCompetitionId(props.competitions.info.type.id, props.competitions.options)
    }

    return (
        <div className="LogosLinks">
            <div className="LinksWrapper">
                {
                    props.links && items.filter(({id}) => props.links.includes(id)).map((item, i) => (
                        <Link to={`${item.link}/${item.id}/${createPath()}`} key={i}>
                            <div>
                                {/* <AbsoluteDiv /> */}
                                <img src={item.img} alt=""/>
                            </div>
                            <div style={{marginTop: "-5px"}}>
                                <span>{ReactHtmlParser(item.title)}</span>
                            </div>
                        </Link>
                    ))
                }
                <Link to={`/operator`}>
                    <div>
                        {/* <AbsoluteDiv /> */}
                        <img src={icon_reg} alt=""/>
                    </div>
                    <div style={{marginTop: "-5px"}}>
                        <span>
                              Ответственные операторы <br/>
                              регионального этапа Конкурса
                        </span>
                    </div>
                </Link>
                <Link to={`/contest_in_faces`}>
                    <div>
                        <img src={icon_contest_in_faces} alt=""/>
                    </div>
                    <div style={{marginTop: "-5px"}}>
                        <span>
                              Конкурс в лицах
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default LogosLinks;
