import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import moment from "moment";
import "moment/locale/ru";
import createMarkup from "../helpers/dangerouslySetHTML";
import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../styles/containers";
import { getMonth } from "../helpers/getMonth";
import { H2, H3, Text } from "../../styles/fonts";
import image from "../../assets/img/full_image.png";
import birds from "../../assets/gif/birds-static.gif";

const FullNewsContainer = styled(PageContainer)`
  margin-top: 30px;
  padding-left: 95px;
  font-size: 16px;
  line-height: 24px;
  color: #3d4669;
  font-family: "Roboto";
  font-weight: 400;
  /* margin-bottom: 56px; */
`;

const TitleContainer = styled(PageContainer)`
  margin-top: 30px;
`;

const UpperNewsContainer = styled(PageContainer)`
  margin-bottom: 82px;
`;

const NewsTaskContainer = styled(PageContainer)``;

const CustomContainer = styled(PageContainer)`
  margin-top: 91px;
  margin-bottom: 91px;
`;

const BreadcrumbsItemLink = styled(BreadcrumbsItem)`
  color: #39404c;
  text-decoration: none;
`;

// const NewsTitle = styled.h1`
//   margin-top: 43px;
//   font-size: 36px;
//   line-height: 40px;
//   color: #10215e;
//   font-family: "Roboto Condensed";
//   font-weight: 400;
// `;

// const NewsDate = styled.span`
//   margin-left: ${props => (props.marginLeft ? `6px` : ``)};
//   font-size: ${props => (props.smallerTitle ? `15px` : `24px`)};
//   letter-spacing: 1px;
//   line-height: 21px;
//   color: #6873b5;
//   font-family: "Roboto";
//   font-weight: 700;
//   text-transform: uppercase;
// `;

// const NewsImage = styled.div`
//   width: 100%;
//   height: 490px;
//   background-image: url("https://picsum.photos/904/490");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   border: 1px solid red;
// `;

const ImageDivContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 110px;
  margin-right: 100px;
  padding-right: 16%;
  height: 155px;
  background-color: #4c579a;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 400;
  text-transform: uppercase;
`;

const NewsImage = styled.img`
  position: absolute;
  /* margin-top: -50px; */
  top: -60px;
  left: 65px;
`;

const NewsText = styled.div`
  margin-bottom: 80px;
  margin-right: 100px;
  font-size: 16px;
  line-height: 20px;
  color: #4e515d;
  font-family: "Roboto";
  font-weight: 400;
  text-align: left;
  & > div > p {
    margin-bottom: 40px;
    font-size: 30px;
    letter-spacing: 1px;
    color: #232c5f;
    font-family: "Roboto";
    font-weight: 700;
    text-transform: uppercase;
  }
  & > div > ul {
    padding-left: 90px;
  }
  & > div > ul > li {
    list-style-type: none;
    position: relative;
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 24px;
    color: #3d4669;
    font-family: "Roboto";
    font-weight: 400;
    &:before {
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      left: -25px;
      top: 5px;
      background-color: #00bbd5;
    }
  }
`;

const UpperContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 470px;
  height: 255px;
`;

const CustomText = styled(Text)`
  margin-left: 30px;
`;

const FullWidthContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #ddf3fe;
  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 20vw solid transparent;
    border-right: 79vw solid transparent;
    border-bottom: 59px solid #ddf3fe;
    top: -58px;
    /* left: 0; */
  }
`;

// const Backbtn = styled(Link)`
//   color: #7d86bf;
//   font-size: 16px;
//   letter-spacing: 1px;
//   line-height: 20px;
//   font-family: "Roboto Condensed";
//   font-weight: 400;
//   text-transform: uppercase;
//   text-decoration: none;
// `;

const AboutPageComponent = props => {
  return (
    <>
        <>
          <TitleContainer>
            <Grid fluid>
              <Row>
                <Col lg={12}>
                  <Breadcrumbs
                    separator={<span style={{ color: "#39404c" }}> / </span>}
                    finalItem={"b"}
                    finalProps={{
                      style: { color: "#6873b5", fontWeight: "normal" }
                    }}
                  />
                  <BreadcrumbsItemLink to="/">
                    Главная страница
                  </BreadcrumbsItemLink>
                  <BreadcrumbsItemLink to={`/about/`}>
                    О конкурсе
                  </BreadcrumbsItemLink>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <H2>О конкурсе</H2>
                </Col>
              </Row>
            </Grid>
          </TitleContainer>
          <UpperNewsContainer>
            <Grid fluid>
              <Row>
                <Col lg={12}>
                  <UpperContainer>
                    <Image src={birds} alt="" />
                    <CustomText>
                      <p>
                        Всероссийский конкурс сочинений направлен на возрождение
                        традиций написания сочинения как самостоятельной
                        творческой работы, в которой отражаются личностные,
                        предметные и метапредметные результаты на разных этапах
                        обучения и воспитания личности.
                      </p>
                      <p>
                        Конкурс проводится с 2015 года. Его участниками являются
                        обучающиеся государственных, муниципальных и
                        негосударственных общеобразовательных организаций,
                        организаций среднего профессионального образования,
                        реализующих программы общего образования Российской
                        Федерации.
                      </p>
                      <p>
                        Тематические направления конкурса сочинений меняются
                        ежегодно, отражая важные события культуры, науки,
                        общественной жизни в нашей стране и в мире.
                      </p>
                    </CustomText>
                  </UpperContainer>
                </Col>
              </Row>
            </Grid>
          </UpperNewsContainer>
          <FullWidthContainer>
            <NewsTaskContainer>
              <Grid fluid>
                <Row>
                  <Col lg={12}>
                    <NewsText>
                      <div>
                        <p>Задачи конкурса:</p>
                        <ul>
                          <li>
                            создать условия для самореализации обучающихся,
                            повышения их социальной и творческой активности;
                          </li>
                          <li>
                            выявить литературно одаренных обучающихся,
                            стимулировать их к текстотворчеству с целью
                            получения нового личностного опыта;
                          </li>
                          <li>
                            способствовать формированию положительного отношения
                            подрастающего поколения к русскому языку и
                            литературе как важнейшим духовным ценностям,
                            повышению в глазах молодежи престижа грамотного
                            владения русским языком и знания художественной
                            литературы;
                          </li>
                          <li>
                            привлечь внимание общественности к социально
                            значимым проектам в области образования, к пониманию
                            значимости функционально грамотного и творческого
                            владения русским языком;
                          </li>
                          <li>
                            продемонстрировать заинтересованной общественности
                            направления работы, ресурсы и достижения системы
                            образования;
                          </li>
                          <li>
                            получить внешнюю оценку образовательного результата,
                            закрепить в общественном сознании мысль о том, что
                            система образования интегрирована в процесс решения
                            общегосударственных гуманитарных проблем;
                          </li>
                          <li>
                            способствовать решению педагогических задач развития
                            связной письменной речи обучающихся, распространению
                            эффективных педагогических методик и практик в
                            области развития письменной речи обучающихся, в том
                            числе обучения написанию сочинений.
                          </li>
                        </ul>
                      </div>
                    </NewsText>
                  </Col>
                </Row>
              </Grid>
            </NewsTaskContainer>
          </FullWidthContainer>
          <FullNewsContainer>
            <p>
              Проведение конкурса регламентируется Положением о Всероссийском
              конкурсе сочинений. Участие в конкурсе является добровольным.
            </p>
            <p>
              Всероссийский конкурс сочинений проводится в два этапа:
              региональный и федеральный. По решению органов исполнительной
              власти субъектов Российской Федерации, осуществляющих
              государственное управление в сфере образования, региональный этап
              может включать школьный и муниципальный этапы.
            </p>
            <p>
              На федеральный этап принимается по одному сочинению от каждой
              возрастной группы обучающихся – победителей регионального этапа.
              Победителей награждают на торжественной церемонии, которая
              традиционно проходит в Москве в первых числах ноября.
            </p>
          </FullNewsContainer>
          <CustomContainer>
            <Grid fluid>
              <Row>
                <Col lg={12}>
                  <ImageDivContainer>
                    <ImageDiv>
                      <div style={{ width: "53%" }}>
                        Творческие работы всех победителей федерального этапа
                        публикуются в официальном сборнике.
                      </div>
                    </ImageDiv>
                    <NewsImage src={image} alt="" />
                  </ImageDivContainer>
                </Col>
              </Row>
            </Grid>
          </CustomContainer>
          <FullNewsContainer style={{ marginBottom: "56px" }}>
            <p>
              В 2017 году в рамках Всероссийского конкурса сочинений по
              инициативе Президента Российской Федерации проводился конкурс
              «Россия, устремленная в будущее», с победителями которого
              встречался лично В.В. Путин.
            </p>
            <p>
              На федеральном этапе официальным оператором конкурса является
              ФГАОУ ВО «Государственный университет просвещения».
            </p>
          </FullNewsContainer>
        </>
    </>
  );
};
export default AboutPageComponent;
