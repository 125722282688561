import photo1 from '../../assets/img/media2019/1new.jpg';
import photo2 from '../../assets/img/media2019/2new.jpg';
import photo3 from '../../assets/img/media2019/3new.jpg';
import photo4 from '../../assets/img/media2019/4new.jpg';
import photo5 from '../../assets/img/media2019/5new.jpg';
import photo6 from '../../assets/img/media2019/6new.jpg';
import photo7 from '../../assets/img/media2019/7new.jpg';
import photo8 from '../../assets/img/media2019/8new.jpg';
import photo9 from '../../assets/img/media2019/9new.jpg';
import photo10 from '../../assets/img/media2019/10new.jpg';
import photo11 from '../../assets/img/media2019/11new.jpg';
import photo12 from '../../assets/img/media2019/12new.jpg';
import photo13 from '../../assets/img/media2019/13new.jpg';
import photo14 from '../../assets/img/media2019/14new.jpg';
import photo15 from '../../assets/img/media2019/15new.jpg';
import photo16 from '../../assets/img/media2019/16new.jpg';
import photo17 from '../../assets/img/media2019/17new.jpg';
import photo18 from '../../assets/img/media2019/18new.jpg';
import photo19 from '../../assets/img/media2019/19new.jpg';
import photo20 from '../../assets/img/media2019/20new.jpg';
import photo21 from '../../assets/img/media2019/21new.jpg';
import photo22 from '../../assets/img/media2019/22new.jpg';
import photo23 from '../../assets/img/media2019/23new.jpg';
import photo24 from '../../assets/img/media2019/24new.jpg';
import photo25 from '../../assets/img/media2019/25new.jpg';
import photo26 from '../../assets/img/media2019/26new.jpg';
import photo27 from '../../assets/img/media2019/27new.jpg';
import photo28 from '../../assets/img/media2019/28new.jpg';
import photo29 from '../../assets/img/media2019/29new.jpg';
import photo30 from '../../assets/img/media2019/30new.jpg';
import photo31 from '../../assets/img/media2019/31new.jpg';
import photo32 from '../../assets/img/media2019/32new.jpg';

import photo33 from '../../assets/img/media2019/33new.jpg';
import photo34 from '../../assets/img/media2019/34new.jpg';
import photo35 from '../../assets/img/media2019/35new.jpg';
import photo36 from '../../assets/img/media2019/36new.jpg';
import photo37 from '../../assets/img/media2019/37new.jpg';
import photo38 from '../../assets/img/media2019/38new.jpg';
import photo39 from '../../assets/img/media2019/39new.jpg';
import photo40 from '../../assets/img/media2019/40new.jpg';
import photo41 from '../../assets/img/media2019/41new.jpg';
import photo42 from '../../assets/img/media2019/42new.jpg';
import photo43 from '../../assets/img/media2019/43new.jpg';
import photo44 from '../../assets/img/media2019/44new.jpg';
import photo45 from '../../assets/img/media2019/45new.jpg';
import photo46 from '../../assets/img/media2019/46new.jpg';
import photo47 from '../../assets/img/media2019/47new.jpg';
import photo48 from '../../assets/img/media2019/48new.jpg';
import photo49 from '../../assets/img/media2019/49new.jpg';
import photo50 from '../../assets/img/media2019/50new.jpg';
import photo51 from '../../assets/img/media2019/51new.jpg';
import photo52 from '../../assets/img/media2019/52new.jpg';
import photo53 from '../../assets/img/media2019/53new.jpg';
import photo54 from '../../assets/img/media2019/54new.jpg';
import photo55 from '../../assets/img/media2019/55new.jpg';
import photo56 from '../../assets/img/media2019/56new.jpg';
import photo57 from '../../assets/img/media2019/57new.jpg';
import photo58 from '../../assets/img/media2019/58new.jpg';

const photos2019 = [
    {
        id: 1,
        url: photo1
    },
    {
        id: 2,
        url: photo2
    },
    {
        id: 3,
        url: photo3
    },
    {
        id: 4,
        url: photo4
    },
    {
        id: 5,
        url: photo5
    },
    {
        id: 6,
        url: photo6
    },
    {
        id: 7,
        url: photo7
    },
    {
        id: 8,
        url: photo8
    },
    {
        id: 9,
        url: photo9
    },
    {
        id: 10,
        url: photo10
    },
    {
        id: 11,
        url: photo11
    },
    {
        id: 12,
        url: photo12
    },
    {
        id: 13,
        url: photo13
    },
    {
        id: 14,
        url: photo14
    },
    {
        id: 15,
        url: photo15
    },
    {
        id: 16,
        url: photo16
    },
    {
        id: 17,
        url: photo17
    },
    {
        id: 18,
        url: photo18
    },
    {
        id: 19,
        url: photo19
    },
    {
        id: 20,
        url: photo20
    },
    {
        id: 21,
        url: photo21
    },
    {
        id: 22,
        url: photo22
    },
    {
        id: 23,
        url: photo23
    },
    {
        id: 24,
        url: photo24
    },
    {
        id: 25,
        url: photo25
    },
    {
        id: 26,
        url: photo26
    },
    {
        id: 27,
        url: photo27
    },
    {
        id: 28,
        url: photo28
    },
    {
        id: 29,
        url: photo29
    },
    {
        id: 30,
        url: photo30
    },
    {
        id: 31,
        url: photo31
    },
    {
        id: 32,
        url: photo32
    },
    {
        id: 33,
        url: photo33
    },
    {
        id: 34,
        url: photo34
    },
    {
        id: 35,
        url: photo35
    },
    {
        id: 36,
        url: photo36
    },
    {
        id: 37,
        url: photo37
    },
    {
        id: 38,
        url: photo38
    },
    {
        id: 39,
        url: photo39
    },
    {
        id: 40,
        url: photo40
    },
    {
        id: 41,
        url: photo41
    },
    {
        id: 42,
        url: photo42
    },
    {
        id: 43,
        url: photo43
    },
    {
        id: 44,
        url: photo44
    },
    {
        id: 45,
        url: photo45
    },
    {
        id: 46,
        url: photo46
    },
    {
        id: 47,
        url: photo47
    },
    {
        id: 48,
        url: photo48
    },
    {
        id: 49,
        url: photo49
    },
    {
        id: 50,
        url: photo50
    },
    {
        id: 51,
        url: photo51
    },
    {
        id: 52,
        url: photo52
    },
    {
        id: 53,
        url: photo53
    },
    {
        id: 54,
        url: photo54
    },
    {
        id: 55,
        url: photo55
    },
    {
        id: 56,
        url: photo56
    },
    {
        id: 57,
        url: photo57
    },
    {
        id: 58,
        url: photo58
    }
];

export default photos2019;