import React, {Component} from "react";
import Select from "react-select";
import {PageContainer} from "../../styles/containers";
import styled from "styled-components";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumbs, BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {BreadcrumbsItemLink} from "../NewsPage/NewsPageComponent";
import {H2} from "../../styles/fonts";
import WinnerCard from "./WinnerCard";
import Input from "../UI/Input";
import Iframemodal from "../UI/Iframemodal";
import PaginationAdvanced from '../UI/PaginationAdvanced';
import queryString from 'query-string';


const WinnersPageWrapper = styled(PageContainer)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const InputWrapper = styled.div`
  // width: 80%;
  // max-width: 600px;
  margin-bottom: 30px;
  flex-grow: 1;

  & > input {
    padding-left: 10px;

    &::placeholder {
      color: #818bc6;
    }

    &:focus {
      // border-color: rgba(104, 115, 200, 0.8);
      box-shadow: 0 1px 1px rgba(104, 115, 200, 0.075) inset,
        0 0 8px rgba(104, 115, 200, 0.6);
      outline: 0 none;
    }
  }
`;

export const SelectWrapper = styled.div`
  margin-bottom: 30px;
  color: #39404c;
  // font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;

  & div.css-vj8t7z {
    width: 100%;
    height: 44px;
    background: #fff;
    border: none;
    outline: none;
    border: 1px solid #b7c5d8;
    border-radius: 0;

    & > option {
      background: #fff;
    }
  }

  & div.css-2o5izw {
    // border-color: rgba(104, 115, 200, 0.8);
    box-shadow: 0 1px 1px rgba(104, 115, 200, 0.075) inset,
      0 0 8px rgba(104, 115, 200, 0.6);
    outline: 0 none;
    height: 44px;
    border-radius: 0;

    &:hover,
    &:focus {
      // border-color: rgba(104, 115, 200, 0.8);
    }
  }

  & div.css-1492t68 {
    color: #818bc6;
  }

  & div.css-xp4uvy {
    color: #818BC6;
    font-size: 17px;
  }
  
  & div.css-z5z6cw {
    background:  #4c579a;
  }
  
  & div.css-wqgs6e {
    background:  rgba(104, 115, 200, 0.3);
  }
  
  & div.css-15k3avv {
    z-index: 100;
  }
  
  & div.css-11unzgr {
    padding-top: 0;
    
    & > div {
      border-bottom: 1px solid rgba(104, 115, 200, 0.3);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  
  & div.css-15k3avv {
    margin-top: 0;
  }
`;

export const SearchBtn = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: #d9ddf2;
  margin-left: 10px;
  margin-top: -2px;
  cursor: pointer;
  text-align: center;
  :hover {
    background: #c0c9f5;
    }
  & > svg {
    margin-top: 12px;
    width: 20px;
    height: 20px;
  }
`;

const GridTemplate = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    @media all and (max-width: 786px) {
        grid-template-columns: 1fr;
    }
`;

const Flex = styled.div`
    display: flex;
`
const StyledNothingFound = styled.div`
    font-family: "Roboto Condensed";
    display: flex;
    margin: 100px 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #394c94;
    font-size: 19px;
    width: 100%;
`

const NothingFound = () => (
    <StyledNothingFound>
        По выбранным условиям поиска победителей не найдено
    </StyledNothingFound>
)

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? `0 1px 1px rgba(104, 115, 200, 0.075) inset, 0 0 8px rgba(104, 115, 200, 0.6)` : '',
        border: '1px solid #b7c5d8',
        borderRadius: '0',
        height: '44px',
        backgroundColor: state.isSelected ? '#4c579a' : null
    })
};

class WinnersPageContainer extends Component {
    state = {
        currentWinners: this.props.filteredWinners || [],
        selectedTab: {
            value: 0,
            label: 'Все группы'
        },
        search: "",
        currentPage: 1,
        cardsOnPage: 6,
        currentCards: null,
        winners: [],
        selectedOption: this.props.options[0],
        selectedCompetition: null
    };

    componentDidUpdate(prevProps, prevState) {

        const params = queryString.parse(this.props.location.search)

        if ((this.props.competitions.info && this.state.selectedCompetition &&
            this.state.selectedCompetition.value &&
            this.state.selectedCompetition.value !== this.props.competitions.info.id)
            || (this.props.competitions.info && !this.state.selectedCompetition)) {
            this.setState({
                selectedCompetition: {
                    value: this.props.competitions.info.id,
                    label: this.props.competitions.info.name,
                }
            })
        }

        if ((this.props.filteredWinners && !prevProps.filteredWinners) ||
            (this.props.filteredWinners && prevProps.filteredWinners && this.props.filteredWinners.length
                && this.props.filteredWinners.length !== prevProps.filteredWinners.length)) {
            this.setState({currentWinners: this.props.filteredWinners}, () => {
                if (prevProps.filteredWinners && this.props.filteredWinners.length
                    && this.props.filteredWinners.length !== prevProps.filteredWinners.length) {
                    this.setState({
                        // selectedOption: this.props.options[0],
                        search: "",
                        currentPage: 1
                    })
                }
                    return this.setCurrentCards(1)
                }
            );
        }
        if (this.props.competitions.info && prevProps.competitions.info &&
            this.props.competitions.info.id && this.props.competitions.info.id !== prevProps.competitions.info.id) {
            this.setState({
                selectedOption: this.props.options[0],
                search: "",
                currentPage: 1,
                currentWinners: this.props.filteredWinners
            }, () => this.setCurrentCards(1))
        }

        if (this.props.tabs && params.chosenGrade && +params.chosenGrade !== 0) {
            if (!this.state.selectedTab.value) {
                const selectedTab = this.props.tabs.filter(tab => tab.value === +params.chosenGrade)[0];
                this.setState({
                    selectedTab
                })
            }
        }

        if (!params.chosenGrade && this.state.selectedTab && this.state.selectedTab.value !== 0) {
            this.setState({
                selectedTab: this.props.tabs[0]
            })
        }
    }

    handleSelectGradeChange = selectedTab => {
        this.setState({ selectedTab }, () => this.setCurrentCards(1))
        this.props.history.push
        (`/winners?competitionId=${this.props.competitions.info.id}&chosenGrade=${selectedTab.value}`)
        this.props.changeGrade(selectedTab)
    };

    handleSearchChange = e => {
        const value = e.target.value;
        this.setState({ [e.target.name]: value }, () => {
            let currentWinners = this.props.filteredWinners.filter(this.filterByAllFields);
            this.setState({ currentPage: 1 });
            this.setState({ currentWinners }, () => this.setCurrentCards(1));
        });
    };

    handleSelectChange = selectedOption => {
        this.setState({ selectedOption }, () => {
            let currentWinners = this.props.filteredWinners.filter(this.filterByAllFields);
            this.setState({
                currentPage: 1,
                selectedOption
            });
            this.setState({ currentWinners }, () => this.setCurrentCards(1));
        });
    };

    handleSelectCompetitionChange = selectedCompetition => {
        if (selectedCompetition.value) {
            this.setState({ selectedCompetition });
            this.props.history.push(`/winners?competitionId=${selectedCompetition.value}`)
        }
    }

    onPageChanged = currentPage => {
        this.setCurrentCards(currentPage);
    };

    setCurrentCards = currentPage => {
        const currentWinners = this.props.filteredWinners.filter(this.filterByAllFields);
        const pageLimit = 6;
        const offset = (currentPage - 1) * pageLimit;
        const currentCards = currentWinners.slice(offset, offset + pageLimit);
        const totalPages = Math.ceil(currentWinners.length / pageLimit);
        this.setState({currentPage, currentCards, totalPages});
    };

    filterByAllFields = winner => {
        const isContainSearch = winner.surname.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
            (winner.region && winner.region.name
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1)
        return isContainSearch && this.state.selectedOption.value !== 0 ? winner.winnerStatus.id === this.state.selectedOption.value : isContainSearch
    };

    render() {
        const {
            currentWinners,
            search,
            selectedTab,
            currentCards,
            selectedOption,
            selectedCompetition
        } = this.state;

        const { competitions, isLoading } = this.props;

        return (
            <WinnersPageWrapper className="WinnerPageContainer">
                <Grid fluid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs
                                separator={<span style={{color: "#39404c"}}> / </span>}
                                finalItem={"b"}
                                finalProps={{
                                    style: {color: "#6873b5", fontWeight: "normal"}
                                }}
                            />
                            <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
                            <BreadcrumbsItem to={`/news/?page=1`}>
                                { competitions && competitions.info && competitions.info.name }
                            </BreadcrumbsItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={5}>
                            <H2>{competitions && competitions.info && competitions.info.name}</H2>
                        </Col>
                    </Row>
                    <GridTemplate>
                        <SelectWrapper>
                            <Select
                                options={this.props.competitionsInChosenGroup}
                                onChange={this.handleSelectCompetitionChange}
                                value={selectedCompetition}
                                placeholder="Выбрать конкурс"
                                styles={customStyles}
                            />
                        </SelectWrapper>
                        <SelectWrapper>
                            <Select
                                options={this.props.tabs || []}
                                onChange={this.handleSelectGradeChange.bind(this)}
                                value={selectedTab}
                                placeholder="Все группы"
                                styles={customStyles}
                            />
                        </SelectWrapper>
                    </GridTemplate>
                    <GridTemplate>
                        <div>
                            <SelectWrapper>
                                <Select
                                    options={this.props.options}
                                    onChange={this.handleSelectChange}
                                    value={selectedOption}
                                    placeholder="Выбрать статус"
                                    styles={customStyles}
                                />
                            </SelectWrapper>
                        </div>
                        <Flex>
                            <InputWrapper>
                                <Input
                                    type="search"
                                    name="search"
                                    value={search}
                                    height="44px"
                                    handleChange={this.handleSearchChange}
                                    handleBlur={() => {
                                    }}
                                    border="1px solid #b7c5d8;"
                                    placeholder="ФИО победителя"
                                />
                            </InputWrapper>
                            {/*<SearchBtn>*/}
                            {/*    <SearchSvg color="#4e515d"/>*/}
                            {/*</SearchBtn>*/}
                        </Flex>

                    </GridTemplate>
                    <Row style={{marginBottom: "10px"}} between="lg">
                        {!isLoading && currentCards && currentCards.length ? currentCards.map((winner, i) => (
                            <WinnerCard
                                winner={winner}
                                key={i}
                                openModalHandler={this.props.openModalHandler}
                            />
                        )) : null}
                        {!isLoading && currentCards && currentCards.length === 0 ? <NothingFound/> : null}
                    </Row>
                    <PaginationAdvanced
                        totalRecords={currentWinners.filter(this.filterByAllFields).length}
                        pageLimit={6}
                        pageNeighbors={1}
                        onPageChanged={this.onPageChanged}/>
                </Grid>
                <Iframemodal
                    openModalHandler={this.props.openModalHandler}
                    close={this.props.close}
                />
            </WinnersPageWrapper>
        );
    }
}

export default WinnersPageContainer;

