import photo1 from '../../assets/img/media2023/1_new_2023.jpg';
import photo2 from '../../assets/img/media2023/2_new_2023.jpg';
import photo3 from '../../assets/img/media2023/3_new_2023.jpg';
import photo4 from '../../assets/img/media2023/4_new_2023.jpg';
import photo5 from '../../assets/img/media2023/5_new_2023.jpg';
import photo6 from '../../assets/img/media2023/6_new_2023.jpg';
import photo7 from '../../assets/img/media2023/7_new_2023.jpg';
import photo8 from '../../assets/img/media2023/8_new_2023.jpg';
import photo9 from '../../assets/img/media2023/9_new_2023.jpg';
import photo10 from '../../assets/img/media2023/10_new_2023.jpg';
import photo11 from '../../assets/img/media2023/11_new_2023.jpg';
import photo12 from '../../assets/img/media2023/12_new_2023.jpg';
import photo13 from '../../assets/img/media2023/13_new_2023.jpg';
import photo14 from '../../assets/img/media2023/14_new_2023.jpg';
import photo15 from '../../assets/img/media2023/15_new_2023.jpg';
import photo16 from '../../assets/img/media2023/16_new_2023.jpg';
import photo17 from '../../assets/img/media2023/17_new_2023.jpg';
import photo18 from '../../assets/img/media2023/18_new_2023.jpg';
import photo19 from '../../assets/img/media2023/19_new_2023.jpg';
import photo20 from '../../assets/img/media2023/20_new_2023.jpg';
import photo21 from '../../assets/img/media2023/21_new_2023.jpg';
import photo22 from '../../assets/img/media2023/22_new_2023.jpg';
import photo23 from '../../assets/img/media2023/23_new_2023.jpg';
import photo24 from '../../assets/img/media2023/24_new_2023.jpg';
import photo25 from '../../assets/img/media2023/25_new_2023.jpg';
import photo26 from '../../assets/img/media2023/26_new_2023.jpg';
import photo27 from '../../assets/img/media2023/27_new_2023.jpg';
import photo28 from '../../assets/img/media2023/28_new_2023.jpg';
import photo29 from '../../assets/img/media2023/29_new_2023.jpg';
import photo30 from '../../assets/img/media2023/30_new_2023.jpg';
import photo31 from '../../assets/img/media2023/31_new_2023.jpg';
import photo32 from '../../assets/img/media2023/32_new_2023.jpg';
import photo33 from '../../assets/img/media2023/33_new_2023.jpg';
import photo34 from '../../assets/img/media2023/34_new_2023.jpg';
import photo35 from '../../assets/img/media2023/35_new_2023.jpg';
import photo36 from '../../assets/img/media2023/36_new_2023.jpg';
import photo37 from '../../assets/img/media2023/37_new_2023.jpg';
import photo38 from '../../assets/img/media2023/38_new_2023.jpg';
import photo39 from '../../assets/img/media2023/39_new_2023.jpg';
import photo40 from '../../assets/img/media2023/40_new_2023.jpg';
import photo41 from '../../assets/img/media2023/41_new_2023.jpg';

const photos2023 = [
    {
        id: 1,
        url: photo1
    },
    {
        id: 2,
        url: photo2
    },
    {
        id: 3,
        url: photo3
    },
    {
        id: 4,
        url: photo4
    },
    {
        id: 5,
        url: photo5
    },
    {
        id: 6,
        url: photo6
    },
    {
        id: 7,
        url: photo7
    },
    {
        id: 8,
        url: photo8
    },
    {
        id: 9,
        url: photo9
    },
    {
        id: 10,
        url: photo10
    },
    {
        id: 11,
        url: photo11
    },
    {
        id: 12,
        url: photo12
    },
    {
        id: 13,
        url: photo13
    },
    {
        id: 14,
        url: photo14
    },
    {
        id: 15,
        url: photo15
    },
    {
        id: 16,
        url: photo16
    },
    {
        id: 17,
        url: photo17
    },
    {
        id: 18,
        url: photo18
    },
    {
        id: 19,
        url: photo19
    },
    {
        id: 20,
        url: photo20
    },
    {
        id: 21,
        url: photo21
    },
    {
        id: 22,
        url: photo22
    },
    {
        id: 23,
        url: photo23
    },
    {
        id: 24,
        url: photo24
    },
    {
        id: 25,
        url: photo25
    },
    {
        id: 26,
        url: photo26
    },
    {
        id: 27,
        url: photo27
    },
    {
        id: 28,
        url: photo28
    },
    {
        id: 29,
        url: photo29
    },
    {
        id: 30,
        url: photo30
    },
    {
        id: 31,
        url: photo31
    },
    {
        id: 32,
        url: photo32
    },
    {
        id: 33,
        url: photo33
    },
    {
        id: 34,
        url: photo34
    },
    {
        id: 35,
        url: photo35
    },
    {
        id: 36,
        url: photo36
    },
    {
        id: 37,
        url: photo37
    },
    {
        id: 38,
        url: photo38
    },
    {
        id: 39,
        url: photo39
    },
    {
        id: 40,
        url: photo40
    },
    {
        id: 41,
        url: photo41
    }

];

export default photos2023;