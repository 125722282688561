import React, { Component } from 'react';
import axios from "axios";
import FullNewsComponent from "./FullNewsComponent";

class FullNewsContainer extends Component {
    state = {
        selectedNews: null,
        loading: true,
        competitionId: null
      };

      componentDidMount() {
        const { id, competitionId } = this.props.match.params;
        this.setState({
            competitionId
        })

        axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/news/${id}`)
          .then(response => {
            console.log(response)
            this.setState({
              selectedNews: response.data,
              loading: false
            });
          })
          .catch(error => {
            console.log(error);
            // window.location.replace("/404");
          });
      }

    render() {
        return (
            <FullNewsComponent {...this.state} competitionId={this.state.competitionId} />
        )
    }
}

export default FullNewsContainer;
