import React, { Component } from "react";
import { connect } from "react-redux";
import HomeJobsComponent from "./HomeJobsComponent";

class HomeJobsContainer extends Component {

  render() {
    return (
      <HomeJobsComponent {...this.props} />
    )
  }
}

const mapStateToProps = ({competitions}) => ({
  competitions
});

export default connect(mapStateToProps)(HomeJobsContainer);
