import React, {Component} from 'react';
import HomeTitleComponent from './HomeTitleComponent';
import queryString from 'query-string';
import bg1 from '../../../assets/img/header_bg/bg_1.jpg';
import bg2 from '../../../assets/img/header_bg/bg_2.jpg';
import bg3 from '../../../assets/img/header_bg/bg3_1920_new_test.jpeg';

import mbg1 from '../../../assets/img/header_bg/bg_768_1.jpg'
import mbg2 from '../../../assets/img/header_bg/bg_768_2.jpg'
import mbg3 from '../../../assets/img/header_bg/bg_768_3.jpg'

class HomeTitleContainer extends Component {
    state = {
        resBg: null,
        name: '',
        isLoading: true
    };

    randomImage = () => {
        let arrImage;
        if (window.innerWidth < 320) {
            arrImage = [mbg1, mbg2, mbg3];
        } else if (window.innerWidth < 769) {
            arrImage = [mbg1, mbg2, mbg3];
        } else if (window.innerWidth < 1025) {
            arrImage = [bg1, bg2, bg3];
        } else if (window.innerWidth < 1200) {
            // arrImage = [bg_1200, bg1_1200, bg2_1200];
            arrImage = [bg1, bg2, bg3];
        } else if (window.innerWidth <= 1920) {
            // arrImage = [bg_1920_new, bg1_1920_new, bg2_1920_new];
            arrImage = [bg1, bg2, bg3];
        } else if (window.innerWidth > 1920) {
            // arrImage = [bg_1920_new_test, bg1_1920_new_test, bg2_1920_new_test];
            arrImage = [bg1, bg2, bg3];
        }
        var n = Math.floor(Math.random() * arrImage.length);
        this.setState({
            resBg: arrImage[n]
        });
    };

    componentWillMount() {
        this.randomImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match && this.props.competitions.info) {
            if ((+this.props.competitions.info.id === +this.props.match.params.competitionId && this.state.isLoading)
            || (!this.props.match.params.competitionId && this.state.isLoading)
            ) {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    render() {
        return <HomeTitleComponent resBg={this.state.resBg} {...this.props}
                                   isLoading={this.state.isLoading}
                                   items={this.props.competitionInfo && this.props.competitionInfo.section ?
                                       this.props.competitionInfo.section.map(section => section.id) : []}
        />;
    }
}

export default HomeTitleContainer;
