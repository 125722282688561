import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { FETCH_MAIN_CALENDAR_EVENT_REQUEST, FETCH_ALL_CALENDAR_EVENTS_REQUEST} from '../constants/calendarTypes';
import {
    fetchMainCalendarEventSuccess,
    fetchMainCalendarEventFailure,
    fetchAllCalendarEventsSuccess,
    fetchAllCalendarEventsFailure
} from '../actions/calendarActions';

export function* fetchMainCalendarEvent({payload}) {
    try {
        const response = yield axios.get(`${process.env.REACT_APP_API_URL}/api/v2/actual-contest-calendar-event/${payload}`).then().catch(e => console.error(e))
        // const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/actual-contest-calendar-event`);
        const data = response.data;
        yield put(fetchMainCalendarEventSuccess(data));
    }
    catch (error) {
        const message = "error, please try again";
        yield put(fetchMainCalendarEventFailure(message));
    }
}


export function* fetchAllCalendarEvents({payload}) {
    try {
        const response = yield axios.get(`${process.env.REACT_APP_API_URL}/api/v2/contest-calendar/${payload}`).then().catch(e => console.error(e))
        // const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v2/actual-contest-calendar-event`);
        const data = response.data;
        yield put(fetchAllCalendarEventsSuccess(data));
    }
    catch (error) {
        const message = "error, please try again";
        yield put(fetchAllCalendarEventsFailure(message));
    }
}

export function* calendarActionWatcher() {
    yield takeLatest(FETCH_MAIN_CALENDAR_EVENT_REQUEST, fetchMainCalendarEvent);
    yield takeLatest(FETCH_ALL_CALENDAR_EVENTS_REQUEST, fetchAllCalendarEvents);
}
