import {
    FETCH_MAIN_CALENDAR_EVENT_REQUEST,
    FETCH_MAIN_CALENDAR_EVENT_FAILURE,
    FETCH_MAIN_CALENDAR_EVENT_SUCCESS,
    FETCH_ALL_CALENDAR_EVENTS_REQUEST,
    FETCH_ALL_CALENDAR_EVENTS_FAILURE,
    FETCH_ALL_CALENDAR_EVENTS_SUCCESS
} from '../constants/calendarTypes';

export const fetchMainCalendarEventRequest = (id = null) => ({
    type: FETCH_MAIN_CALENDAR_EVENT_REQUEST,
    payload: id
})

export const fetchMainCalendarEventFailure = (message) => ({
    type: FETCH_MAIN_CALENDAR_EVENT_FAILURE,
    payload: message
})

export const fetchMainCalendarEventSuccess = (data) => ({
    type: FETCH_MAIN_CALENDAR_EVENT_SUCCESS,
    payload: data
})

export const fetchAllCalendarEventsRequest = (id = null) => ({
    type: FETCH_ALL_CALENDAR_EVENTS_REQUEST,
    payload: id
})

export const fetchAllCalendarEventsFailure = (message) => ({
    type: FETCH_ALL_CALENDAR_EVENTS_FAILURE,
    payload: message
})

export const fetchAllCalendarEventsSuccess = (data) => ({
    type: FETCH_ALL_CALENDAR_EVENTS_SUCCESS,
    payload: data
})
