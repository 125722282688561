import React, {Component} from "react";
import styled from "styled-components";
import "./HomeJobsComponent.scss";
import {H2} from "../../../styles/fonts";
import img_gif_static from "../../../assets/gif/birds-static.gif";
import img_gif_fly from "../../../assets/gif/birds-fly-cut.gif";
import {MenuLink} from "../../Header/HeaderComponent";
import WorksSlider from '../../UI/WorksSlider';


const ImgGif = styled.img`
  position: absolute;
  top: -32px;
  /* right: 37px; */
  right: 0;
  left: 50%;
    transform: translateX(-50%);
  z-index: 10;
  &:hover {
    opacity: 0;
  }
  @media (max-width: 767px) {
    position: relative;
    top: auto;
    right: auto;
    left: 0;
    transform: none;
  }
`;
const ImgGifActive = styled.img`
  position: absolute;
  top: -32px;
  /* right: 37px; */
  right: 0;
  /* opacity: 0; */
  left: 50%;
    transform: translateX(-50%);
  &:hover {
    opacity: 1;
  }
  @media (max-width: 767px) {
    top: 0;
    /* left: 50%;
    transform: translateX(-50%); */
  }
`;

const CustomH2 = styled(H2)`
  text-align: center;
  margin-top: -20px;
  margin-left: -25px;
  cursor: pointer; 
  @media (max-width: 767px) {
    position: relative;
    /* font-size: 20px; */
    /* line-height: 24px; */
    bottom: 0;
    color: #4e515d;
    font-family: "Roboto Condensed";
    font-weight: 700;
    text-align: center;
    margin-left: unset;
  }
`;


class HomeJobsComponent extends Component {
    state = {
        lastYear: null,
        name: ''
    }

    render() {
        return (
            <div className="HomeJobs">
                <div className="HomeJobs__Paddings">
                    <div className="WorksBlock">
                        <div className="Title">
                            Работы<br/>
                            победителей
                        </div>
                        <div className="Works">
                            <WorksSlider works={this.props.competitions.info ? this.props.competitions.info.grades : []}
                                         competitionTypeId={this.props.competitions}
                                         options={this.props.competitions.options}
                            />
                        </div>
                    </div>
                    <div className="ThemesBlock">
                        <MenuLink to={`/topics/${this.props.competitions.info && this.props.competitions.info.id}`}>
                            <div className="Img" style={{position: 'relative'}}>
                                <ImgGif src={img_gif_static} alt="" className="StaticImg"/>
                                <ImgGifActive src={img_gif_fly} alt=""/>
                            </div>
                            <CustomH2 className="CustomH2">
                                Тематические <br/>
                                направления
                            </CustomH2>
                        </MenuLink>
                    </div>
                </div>

            </div>
        )
    };
}

export default HomeJobsComponent;
