import React, {Component} from "react";
import {connect} from "react-redux";
import DocumentsPageComponent from "./DocumentsPageComponent";
import {getSectionInfoByIdRequest} from '../../actions/documentsActions';

class DocumentsPageContainer extends Component {

    componentDidMount() {
        this.props.getSectionInfoByIdRequest(this.props.match.params.competitionId, 'dokumenty')
        // this.props.fetchDocsRequest(code);
        // this.setState({docs: this.props.docs});
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log(999, this.props)
    // }
    //
    // openModalHandler(i) {
    //     console.log('i', i)
    // }

    render() {
        return <DocumentsPageComponent
            openModalHandler={this.props.openModalHandler}
            docs={this.props.documents}
            isDocsLoading={this.props.isDocsLoading}
        />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openModalHandler: (url) => dispatch({type: "DOCS_OPEN_MODAL", payload: url}),
        getSectionInfoByIdRequest: (id, sectionBlock) =>
            dispatch(getSectionInfoByIdRequest(id, sectionBlock))
    };
};

const mapStateToProps = state => ({
    documents: state.documents.dokumenty,
    isDocsLoading: state.documents.isLoading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsPageContainer);
