import React from "react";
import HeaderContainer from "../Header/HeaderContainer";
import HomeTitleContainer from "./HomeTitle/HomeTitleContainer";
import HomeInformationContainer from "./HomeInformation/HomeInformationContainer";
import HomeWinnersContainer from "./HomeWinners/HomeWinnersContainer";
import HomeJobsContainer from "./HomeJobs/HomeJobsContainer";
import HomeMediaContainer from "./HomeMedia/HomeMediaContainer";
import HomePartnersContainer from "./HomePartners/HomePartnersContainer";
import FooterContainer from "../Footer/FooterContainer";

const HomeComponent = (props) => {
    return (
        <div className="App">
            <HeaderContainer isHome={true} {...props} />
            <HomeTitleContainer {...props}/>
            <HomeInformationContainer/>
            <HomeWinnersContainer {...props}/>
            <HomeJobsContainer competitionId={props.competitionId}/>
            {/*<HomeMediaContainer/>*/}
            {/* <HomePartnersContainer/> */}
            <FooterContainer/>
        </div>
    );
};

export default HomeComponent;
