import React, { Component } from "react";
import SchedulePageComponent from "./SchedulePageComponent";

class SchedulePageContainer extends Component {
  render() {
    return (
      <SchedulePageComponent scheduleData={scheduleData} regions={regions} />
    );
  }
}

export default SchedulePageContainer;

const scheduleData = [
  {
    id: 15,
    subject: "Алтайский край",
    stages: [
        {
            stage: "Школьный этап",
            start_date: "01 сентября",
            finish_date: "25 сентября"
          },
          {
            stage: "Муниципальный этап",
            start_date: "26 сентября",
            finish_date: "01 октября"
          },
          {
            stage: "Региональный этап",
            start_date: "02 октября",
            finish_date: "13 октября"
          }
    ]
  },
  {
    id: 21,
    subject: "Амурская область",
    stages: [
        // {
        //     stage: "Школьный этап",
        //     start_date: "01 сентября",
        //     finish_date: "25 сентября"
        //   },
        //   {
        //     stage: "Муниципальный этап",
        //     start_date: "26 сентября",
        //     finish_date: "01 октября"
        //   },
          {
            stage: "Региональный этап",
            start_date: "01 апреля",
            finish_date: "13 октября"
          }
    ]
  }
];

const regions = [
  {
    federal_district: {
      name: "Сибирский федеральный округ",
      short_name: "СибФО"
    },
    label: "Алтайский край",
    value: 15
  },
  {
    federal_district: {
      name: "Дальневосточный федеральный округ",
      short_name: "ДФО"
    },
    label: "Амурская область",
    value: 21
  }
];
