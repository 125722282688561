import React from "react";
import styled from "styled-components";

const OperatorCardContainer = styled.div`
  margin-bottom: 45px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  color: #3d4669;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 14px;
`;

const OperatorLocation = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 30px;
  color: #4c579a;
  
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
`;

const RagionalSiteAddress = styled.a`
  margin-left: 15px;
  color: #00bbd5;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.3;
`;

const RegionalCardInfo = styled.div`
  margin-top: 17px;
  padding: 20px 15px;
  border: 1px solid #dfdddd;
`;

const NameCard = styled.span`
  font-size: 24px;
  line-height: 18px;
`;

const WorkPlaceCard = styled.p`
  line-height: 24px;
  color: #6873b5;
`;

const PersonalSite = styled.span`
  line-height: 18px;
  font-weight: 600;
`;

const OperatorCard = props => {
    return (
        <OperatorCardContainer>
            <OperatorLocation>{props.region}</OperatorLocation>
            <div>
                Региональный сайт Конкурса :
                <RagionalSiteAddress href={props.regional_site} target="_blank">{props.regional_site}</RagionalSiteAddress>
            </div>
            <RegionalCardInfo>
                <NameCard>
                    {`${props.surname} ${props.name} ${props.patronymic}`}{" "}
                </NameCard>
                <WorkPlaceCard>{props.work}</WorkPlaceCard>
                <PersonalSite>{props.email}</PersonalSite>
            </RegionalCardInfo>
        </OperatorCardContainer>
    );
};

export default OperatorCard;