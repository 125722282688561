import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

import { FETCH_OPERATORS_REQUEST } from "../constants";
import {
    fetchOperatorsSuccess,
    fetchOperatorsError,
} from "../actions/operatorsActions";

export function* fetchOperators() {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/api/v1/users/regional`);
    const data = response.data;
    try {
        yield put(fetchOperatorsSuccess(data));
    } catch (error) {
        const message = "error, please try again";
        yield put(fetchOperatorsError(message));
    }
}

export function* operatorsActionWatcher() {
    yield takeLatest(FETCH_OPERATORS_REQUEST, fetchOperators);
}
