import React from "react";
import {Link} from "react-router-dom";
import "moment/locale/ru";
import {PageContainer} from "../../../styles/containers";
import "./HomeInformationComponent.scss";
import {getMonth} from '../../../helpers/getMonth';
import HomeInformationCalendar from './HomeInformationCalendar';

const HomeInformationComponent = props => {

    // console.log('props', props.news.mainNews && new Date(props.news.mainNews.published_at))
    // const { main_news, news, mainisLoading, loading } = props;

    const formatDate = (string) => {
        const date = new Date(string.replace(' ', 'T'))
        return (
            <>
                <span className="day">
                    {date.getUTCDate()}
                </span>
                <span className="month">
                    {getMonth(date.getUTCMonth())}
                </span>
                <span className="year">
                    {date.getUTCFullYear()}
                </span>
                <span className="time">
                    г. {date.getHours()} : {date.getMinutes() < 10 ? date.getMinutes() === 0 ? '00' : '0' : '' + date.getMinutes()}
                </span>
            </>
        )
    }

    return (
        <PageContainer>
            <div
                className={`HomeInformationComponent ${props.calendar.mainEvent && props.calendar.mainEvent.stage ? '' : 'HomeInformationComponent--row'}`}>
                <div className="HomeInformationComponent__block">

                    <div className="title_h2">
                        Новости конкурса
                    </div>
                    <div className={`news-block ${props.calendar.mainEvent && props.calendar.mainEvent.stage ? 'news-block--two-columns' : 'news-block--three-columns'}`}>
                        <div className="main-news">
                            {
                                props.news.mainNews && props.news.mainNews.thumb_urls && props.news.mainNews.thumb_urls.previewPicture &&
                                <div className="image" style={{
                                    background: `left / contain no-repeat url(${props.news.mainNews &&
                                    props.news.mainNews.thumb_urls &&
                                    props.news.mainNews.thumb_urls.previewPicture &&
                                    props.news.mainNews.thumb_urls.previewPicture.c_news275x190
                                    })`
                                }}> </div>
                            }
                            <div className="date">
                                {props.news.mainNews && formatDate(props.news.mainNews.published_at)}
                            </div>
                            <Link to={`/news/detail/${props.news.mainNews && props.news.mainNews.id}/${props.competitionId}`}
                                  className="title">
                                {props.news.mainNews && props.news.mainNews.title}
                            </Link>
                        </div>
                        <div
                            className={`news-content ${props.calendar.mainEvent && props.calendar.mainEvent.stage ? 'grid-one-row' : 'grid-two-rows'}`}>
                            {
                                props.news.allNews && props.news.allNews.items &&
                                props.news.allNews.items.slice(0, props.calendar.mainEvent && props.calendar.mainEvent.stage ? 2 : 4).map(
                                    news => (
                                        <div className="news" key={news.id}>
                                            <div className="date">
                                                {formatDate(news.published_at)}
                                            </div>
                                            <div className="title">
                                                <Link to={`/news/detail/${news.id}/${props.competitionId}`} className="title">
                                                    {news.title}
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="button-block">
                        <div className={`grid-${props.calendar.mainEvent && props.calendar.mainEvent.stage ? 'two' : 'three'}-columns`}>
                            <div></div>
                            <div>
                                <Link to={`/news/?competitionId=${props.competitionId}&page=1`} className="button button--blue">
                                    Все новости
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    props.calendar.mainEvent && props.calendar.mainEvent.stage ?
                        <div className="HomeInformationComponent__block">
                            <HomeInformationCalendar {...props} />
                        </div> : null
                }
            </div>

        </PageContainer>
    );
};

export default HomeInformationComponent;
